<template>
  <div>
    <div
      id="dom-to-img-wrapper"
      class="top-section1"
      style="width: 100%; height: 125vh"
    >
      <div class="top-section1-text">{{ true_name }}</div>
      <div class="top-section2-text">
        <template>
          {{ IdNumber }}
        </template>
      </div>
      <div class="top-section3-text">
        <template>
          {{ CertificateNumber }}
        </template>
      </div>
      <img
        class="identification-photo"
        :src="certphoto"
        crossOrigin="anonymous"
      />
      <div class="top-section4-text">{{ true_name }}</div>
    </div>
    <el-button @click="createImg">下载证书</el-button>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import "../assets/fonts/font.css";
export default {
  data() {
    return {
      certphoto: "",
      true_name: "",
      IdNumber: null,
      CertificateNumber: null,
      //isEditingIdNumber: false, // 新增的变量
      //isEditingCertificateNumber: false,
    };
  },

  methods: {
    createImg() {
      let domName = "dom-to-img-wrapper";

      let imageDom = document.getElementById(domName);
      html2canvas(imageDom, {
        useCORS: true,
        width: document.getElementById(domName).offsetWidth, // canvas画板的宽度 一般都是要保存的那个dom的宽度
        height: document.getElementById(domName).offsetHeight, // canvas画板的高度  同上
        scale: 1,
      }).then((canvas) => {
        let base64Url = canvas.toDataURL("image/png", 1);
        this.fileDownload(base64Url);
      });
    },
    fileDownload(downloadUrl) {
      let aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = downloadUrl;
      aLink.download = "图片名称.png";
      // 触发点击-然后移除
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
    },
    // 照片点击
    figure_click(figure_name) {
      this.$router.push("/directors?=" + figure_name);
      this.$store.commit("set_current_figure", figure_name);
    },
  },
  created() {
    this.axios
      .post("https://api.movie.yingshinet.com/showcertificate", {
        name: this.$store.state.user_name,
      })
      .then((response) => {
        if (response.data.msg == "success") {
          this.certphoto = response.data.path;
          this.true_name = response.data.true_name;
          this.IdNumber = response.data.id_card;
          this.CertificateNumber = response.data.id_certificates;
        } else {
          this.$message({
            message: "证书照片好像出了点问题",
            type: "warning",
          });
        }
      });
  },
};
</script>

<style scoped>
.top-section1 {
  flex: 2;
  background: url("/static/certificate.jpg") center/cover no-repeat;
  background-size: cover; /* 添加此行 */
  position: relative;
  color: white;
}

.top-section1-text {
  position: absolute;
  top: 25%;
  left: 34%;
  letter-spacing: 1vw;
  font-size: 7vw; /* 不知道几号字号，具体数值可以根据实际情况调整 */
  font-family: FZDB; /* 使用字体 */
  padding: 5px 10px;
  color: black;
}
.top-section2-text {
  position: absolute;
  font-family: FZDB;
  letter-spacing: 0.2vw;
  top: 39.2%;
  left: 35.5%;
  font-size: 2vw;
  padding: 5px 10px;
  color: black;
}
.top-section3-text {
  position: absolute;
  font-family: FZDB;
  letter-spacing: 0.2vw;
  top: 45%;
  left: 35.5%;
  font-size: 2vw;
  padding: 5px 10px;
  color: black;
}
.top-section4-text {
  position: absolute;
  letter-spacing: 0.18vw;
  top: 61.8%;
  left: 25%;
  font-size: 1.7vw;
  font-family: FZDB; /* 使用字体 */
  padding: 5px 10px;
  color: DimGray;
}
.identification-photo {
  position: absolute;
  top: 30vh;
  left: 61.4vw;
  width: 12vw;
  height: 16vw;
}
</style>
