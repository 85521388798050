import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    login_state: window.sessionStorage.getItem("login_state") == "1" ? 1 : 0,
    user_name: window.sessionStorage.getItem("user_name"),
    current_figure:
      window.sessionStorage.getItem("current_figure") == null
        ? "费穆"
        : window.sessionStorage.getItem("current_figure"),
    current_figure_pic:
      window.sessionStorage.getItem("current_figure_pic") == null
        ? "https://assets.yingshinet.com/chronicles/pictures/FEIMU.jpg"
        : window.sessionStorage.getItem("current_figure_pic"),
    currentCreateMan:
      window.sessionStorage.getItem("currentCreateMan") ==
      window.sessionStorage.getItem("currentCreateMan"),
    is_administrator:
      window.sessionStorage.getItem("is_administrator") == "1" ? 1 : 0,
    is_summerschoolstudent:
      window.sessionStorage.getItem("is_summerschoolstudent") == "8" ? 1 : 0,
    auth: window.sessionStorage.getItem("auth"),
    summerschool: window.sessionStorage.getItem("summerschool"),
    current_term: "",
    keywordSearch: "",
    MonthSearch: "",
    YearSearch: "",
    min_width: 786,
    aside_show: true,
    isCollapse: true,
    isvis:
      window.sessionStorage.getItem("isvis") == null
        ? "home"
        : window.sessionStorage.getItem("isvis"),
  },
  mutations: {
    //移动端
    toggleCollapse(state) {
      console.log("isCollapse: " + state.isCollapse);
      if (window.screen.width < state.min_width) {
        state.isCollapse = true;
        state.aside_show = !state.aside_show;
        console.log("切换到了手机端");
      } else {
        state.aside_show = true;
        state.isCollapse = false;
      }
    },
    //登录状态
    change_state_in(state) {
      state.login_state = 1;
      window.sessionStorage.setItem("login_state", 1);
    },
    change_state_out(state) {
      state.login_state = 0;
      window.sessionStorage.setItem("login_state", 0);
    },
    //当前用户姓名
    set_username(state, username) {
      state.user_name = username;
      window.sessionStorage.setItem("user_name", username);
    },
    //当前标签
    set_current_term(state, term) {
      state.current_term = term;
      window.sessionStorage.setItem("current_term", term);
    },
    //当前影人
    set_current_figure(state, figure_name) {
      state.current_figure = figure_name;
      window.sessionStorage.setItem("current_figure", figure_name);
    },
    //当前影人头像图片
    set_current_figure_pic(state, currentFigureImage) {
      state.current_figure_pic = currentFigureImage;
      window.sessionStorage.setItem("current_figure_pic", currentFigureImage);
    },
    //当前年谱上传用户
    set_current_create_man(state, currentCreateMan) {
      state.currentCreateMan = currentCreateMan;
      window.sessionStorage.setItem("currentCreateMan", currentCreateMan);
    },
    //是否管理员
    set_is_administrator(state) {
      state.is_administrator = 1;
      window.sessionStorage.setItem("is_administrator", 1);
    },
    set_not_administrator(state) {
      state.is_administrator = 0;
      window.sessionStorage.setItem("is_administrator", 0);
    },
    //是否是学生
    set_is_summerschoolstudent(state) {
      state.is_summerschoolstudent = 1;
      window.sessionStorage.setItem("is_summerschoolstudent", 8);
    },
    set_not_summerschoolstudent(state) {
      state.is_summerschoolstudent = 0;
      window.sessionStorage.setItem("is_summerschoolstudent", 0);
    },
    //年谱
    setkeywordSearch(state, keywordSearch) {
      state.keywordSearch = keywordSearch;
      // console.log("vuex", keywordSearch);
    },
    setMonthSearch(state, MonthSearch) {
      state.MonthSearch = MonthSearch;
      // console.log("vuex", MonthSearch);
    },
    setYearSearch(state, YearSearch) {
      state.YearSearch = YearSearch;
      // console.log("vuex", MonthSearch);
    },
    //修改词云图图片墙状态
    handlevis(state, vis) {
      state.isvis = vis;
      window.sessionStorage.setItem("isvis", vis);
    },
  },
});

export default store;
