
<template>
  <div v-if="clickedLiteratureurl" style="margin-left:8%;margin-right:8%">
      <div>
        <el-row :gutter="7">
          <el-card style="height:32vh;background-color: #b8870b10" >
            <el-col :span="4">
              <div v-if="this.clickedLiteratureurl.file_dir">
                <!-- 文献图片显示 -->
                <el-image
                  style="width: 160px; height: 190px; margin-top: 6px"
                  :src="clickedLiteratureurl.file_dir[nowpage - 1]"
                  :preview-src-list="clickedLiteratureurl.file_dir"
                >
                </el-image>
              </div>
            </el-col>

            <el-col :span="15">
              <span style="height: auto; font-size: 16px; color:gray;text-align: left;line-height:30px;font-weight:bold;" v-html="replaceArr.title"></span><br/>
              <span class="content_key">年份：</span>
              <span class="content_value">{{ replaceArr.year }}</span><br/>
              <span class="content_key">来源：</span>
              <span class="content_value">{{ replaceArr.source }}</span><br/>
              <span class="content_key">研究人物：</span>
              <span class="content_value" v-html="replaceArr.label"></span><br/>
              <span class="content_key">页数：</span>
              <span class="content_value" v-if="replaceArr.file_dir">{{ replaceArr.file_dir.length }}页</span><br/>
              <!-- 登陆后显示功能按钮 -->
              <span v-if="$store.state.login_state" style="margin-left:10px">
                <el-button size="mini" type="warning" @click="jumptonewtab()">下载图片</el-button>

                <el-button size="mini" type="warning" plain @click="addFormVisible = true">增加</el-button>
                <!-- 增加文献的对话框 -->
                <el-dialog :visible.sync="addFormVisible" width="95%" height="95%" fullscreen>
                  <el-form :model="addform" :rules="addformrules" ref="addform">
                    <el-col>
                      <el-row>
                        <el-col :span="11" style="margin-right:4%">
                          <!-- 表单 -->
                          <el-row :span="2">
                            <el-form-item label="文献名" prop="title">
                              <el-input v-model="addform.title" autocomplete="off"></el-input>
                            </el-form-item>
                          </el-row>
                          <el-row :span="2">
                            <el-col :span="7" style="margin-right:4%">
                              <el-form-item label="研究人物">
                                <el-input v-model="addform.label" autocomplete="off"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="7" style="margin-right:4%">
                              <el-form-item label="文献年份">
                                <el-form-item prop="year">
                                  <el-date-picker
                                    type="year"
                                    placeholder="选择年份"
                                    v-model="addform.year"
                                    style="width: 100%;"
                                  ></el-date-picker>
                                </el-form-item>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="文献来源">
                                <el-input v-model="addform.source" autocomplete="off"></el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-col>

                        <el-col :span="11">
                          <!-- 上传图片 -->
                          <el-upload
                            class="avatar-uploader"
                            action
                            :auto-upload="false"
                            :on-preview="handlePicPreview"
                            :on-remove="handleRemove"
                            :on-change="handleChange"
                            :file-list="fileList"
                            list-type="picture-card"
                            multiple
                            accept=".jpg, .jpeg,.png"
                          >
                            <i class="el-icon-plus avatar-uploader-icon"></i>
                            <div class="el-upload__tip" slot="tip">只能上传jpg/jpeg/png文件,上传图片大小不能超过3MB!</div>
                          </el-upload>
                          <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                            <img width="100%" :src="dialogImageUrl" />
                          </el-dialog>
                        </el-col>
                      </el-row>

                      <el-row :span="20">
                        <el-form-item label="文献内容">
                          <el-input v-model="addform.content" autocomplete="off" type="textarea" :rows="16"></el-input>
                        </el-form-item>
                      </el-row>
                    </el-col>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="addFormVisible = false">取 消</el-button>
                    <el-button type="warning" @click="submitaddForm('addform')">提 交</el-button>
                  </div>
                </el-dialog>

                <el-button size="mini" type="warning" plain @click="deleteVisible = true">删 除</el-button>
                <!-- 删除提示 -->
                <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
                  <span>该条记录确定删除吗?</span>
                  <span slot="footer">
                    <el-button @click="deleteVisible = false">取 消</el-button>
                    <el-button type="warning" @click="submitdelete()">确 定</el-button>
                  </span>
                </el-dialog>

                <el-button size="mini" type="warning" plain @click="showUpdateForm()">修改</el-button>
                <!-- 修改文献对话框 -->
                <el-dialog :visible.sync="updateFormVisible" width="95%" height="95%" fullscreen>
                  <el-form :model="updateform" :rules="updateformrules" ref="updateform">
                    <el-row>
                      <el-col :span="13">
                        <span>页数：{{ nowpage }}/{{ this.clickedLiteratureurl.file_dir.length }}</span>
                        <el-button size="mini" circle icon="el-icon-arrow-left" @click="pagedown()"></el-button>
                        <el-button size="mini" circle icon="el-icon-arrow-right" @click="pageup()"></el-button>
                        <el-image
                          style="width: 90%; height: 100vh; margin-top: 10px ;margin-left: 10px"
                          :src="clickedLiteratureurl.file_dir[nowpage - 1]"
                          :preview-src-list="clickedLiteratureurl.file_dir"
                        >
                          <div slot="placeholder" class="image-slot" v-loading="imageloading">
                            <br />
                            <br />
                            <br />
                            <br />
                          </div>
                        </el-image>
                      </el-col>

                      <el-col :span="11">
                        <!-- 修改文献表单 -->
                        <el-form-item label="文献名" prop="title">
                          <el-input type="textarea" :rows="3" v-model="updateform.title" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-row>
                          <el-col :span="8" style="margin-right:10%">
                            <el-form-item label="研究人物" prop="label">
                              <el-input v-model="updateform.label" autocomplete="off"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8" style="margin-right:16%">
                            <el-form-item label="文献年份">
                              <el-form-item prop="year">
                                <el-date-picker
                                  type="year"
                                  placeholder="选择年份"
                                  v-model="updateform.year"
                                  style="width: 100%;"
                                ></el-date-picker>
                              </el-form-item>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-form-item label="文献内容">
                          <el-input
                            type="textarea"
                            :rows="16"
                            v-model="updateform.content"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="updateFormVisible = false">取 消</el-button>
                    <el-button type="warning" @click="submitupdateForm('updateform')">修 改</el-button>
                  </div>
                </el-dialog>
              </span>
            </el-col>

            <el-col :span="4" :offset="1">
              <el-row :gutter="2">
                <img style="height:80px; width:60px;border-radius:60%;" :src="directorurl.f_pic"/>
              </el-row>
              <el-row :gutter="5">
                <span style="height: auto; font-size: 8px; color:gray;text-align: left;line-height:10px;">{{directorurl.f_name}}-{{directorurl.f_brief}}</span>
              </el-row>
              <el-row :gutter="2">
              <span style="height: auto; font-size: 6px; color:gray;text-align: left;line-height:10px;font-weight:bold;">创建人：</span>
              <span style="height: auto; font-size: 6px; color:gray;text-align: left;line-height:10px;">{{ replaceArr.create_man }}</span><br/>
              </el-row> 
            </el-col>
            </el-card>
          </el-row>
          <el-row :gutter="6">
            <el-card style="height:68vh ;overflow-y:scroll;background-color: #b8870b10;margin-top:15px">
              <span class="content_key">内容：</span>
              <span class="content_value" v-html="replaceArr.content"></span><br/>
          </el-card>

        </el-row>
      </div>
    <br />
    <br />
  </div>
</template>

<script>
import service from "../../utils/request";
export default {
  name: 'LiteratureContent',
  data() {
    return {
      //关键词高亮所需数据
      colors: ["#b8860b", "#778899"],
      // 40位影人名单
      directors: [],
      // 电影名单
      movie: [],

      // 图片显示数据
      nowpage: 1,
      imageloading: true,
      // 增加表单数据
      addFormVisible: false,
      addform: {
        title: '',
        year: '',
        content: '',
        source: '',
        label: ''
      },
      addformrules: {
        title: [{ required: true, message: '请输入文献名', trigger: 'blur' }],
        year: [
          { type: 'date', required: true, message: '请选择年份', trigger: 'change' }
        ]
      },
      // 修改表单数据
      updateFormVisible: false,
      updateform: {},
      updateformrules: {
        title: [{ required: true, message: '请输入文献名', trigger: 'blur' }],
        label: [{ required: true, message: '请输入文献标签', trigger: 'blur' }],
        year: [
          { type: 'date', required: true, message: '请选择年份', trigger: 'change' }
        ]
      },
      deleteVisible: false,
      // 图片上传数据
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,

    }
  },
  props: {
    clickedLiteratureurl: Object,
    directorurl: Object
  },
  computed: {
    // 替换内容中的关键词为高亮形式;影人\影片实体超链接计算
    replaceArr() {
      const arr = JSON.parse(JSON.stringify(this.clickedLiteratureurl));

      const colors = this.colors;
      const keywords = this.directors;
      const keywords2 = this.movie;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a style="text-decoration:none" href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        //替换
        if(arr.label){  
          arr.label = arr.label.replace(replaceReg, replaceString)
        }
         if(arr.title){  
          arr.title = arr.title.replace(replaceReg, replaceString)
        }
        if (arr.content) {
          arr.content = arr.content.replace(replaceReg, replaceString)
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp('“'+keywordItem+'”', "g");
        // 高亮替换v-html值
        const replaceString = `<a style="text-decoration:none" href="/#/video?id=${keywordIndex + 1
          }"><font color=${colors[0]}>“${keywordItem}”</font></a>`;
        // 替换
        if(arr.label){  
          arr.label = arr.label.replace(replaceReg, replaceString)
        }
         if(arr.title){  
          arr.title = arr.title.replace(replaceReg, replaceString)
        }
        if (arr.content) {
          arr.content = arr.content.replace(replaceReg, replaceString)
        }
      });
      return arr;
    },

  },
  methods:{
    // 图片相关方法
    // 翻页
    pageup() {
      if (this.nowpage < this.clickedLiteratureurl.file_dir.length) {
        this.nowpage++
      }
    },
    pagedown() {
      if (this.nowpage > 1) {
        this.nowpage--
      }
    },

    // 下载图片
    jumptonewtab() {
      window.location.href = this.clickedLiteratureurl.file_dir
    },

    // 上传图片相关方法
    handleChange(file) {
      this.fileList.push(file)
    },

    handlePicPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.fileList.map((item, index) => {
        if (item.uid == file.uid) {
          this.fileList.splice(index, 1)
        }
      })
    },


    // 添加、修改、删除功能
    // 点击确定增加按钮事件
    submitaddForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.fileList.length == 0) {
            this.$message.error('需要上传至少一张图片！')
          } else {
            this.addform.year = this.addform.year.getFullYear()
            let _this = this
            service({
              url: 'articles/insert', method: 'post', data: _this.addform,
            })
              .then((response) => {
                if (response.data.msg == 'insert success') {
                  var new_file_id = response.data.id
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  });
                  if (this.fileList.length) {
                    let pic_param = new FormData() //form对象
                    for (var i = 0; i < this.fileList.length; i++) {
                      pic_param.append('file' + i.toString(), this.fileList[i].raw)
                    }
                    let config = {
                      headers: { 'Content-Type': 'multipart/form-data' },
                    }
                    service({
                      url: 'articles/file/' + new_file_id, method: 'post', data: pic_param, config: config
                    })
                      .then((response) => {
                        if (response.status == '200') {
                          this.$message({
                            message: '添加图片成功！',
                            type: 'success',
                          });
                          this.$router.push({
                            path: '/literature',
                            query: {
                              id: new_file_id,
                            },
                          })
                          this.$emit('added', this.addform.title)
                        } else {
                          this.$message({
                            message: '添加图片失败',
                            type: 'warning'
                          });
                        }
                      })
                  }

                }
              }),
              this.addFormVisible = false
            this.$refs[formName].resetFields();
          }

        } else {
          this.$message({
            message: '添加失败',
            type: 'warning'
          });
        }
      });

    },

    // 将当前所选文献内容传递到修改表单中
    showUpdateForm() {
      this.updateform = {
        'content': this.clickedLiteratureurl.content,
        'source': this.clickedLiteratureurl.source,
        'title': this.clickedLiteratureurl.title,
        'year': new Date(this.clickedLiteratureurl.year, 1, 1),
        'label': this.clickedLiteratureurl.label,
        'file_dir': this.clickedLiteratureurl.file_dir,
      }
      this.updateFormVisible = true
    },
    // 提交修改表单按钮事件
    submitupdateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateform.year = this.updateform.year.getFullYear()
          var updatedic = {
            'id': this.clickedLiteratureurl.id,
            'now_content': this.updateform.content,
            'now_source': this.updateform.source,
            'now_title_name': this.updateform.title,
            'now_year': this.updateform.year,
            'now_label': this.updateform.label
          }

          service({
            url: 'articles/update', method: 'post', data: updatedic
          })
            .then((response) => {
              console.log(response.data.msg)
              if (response.data.msg == 'update success')
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
              if (response.data.msg == 'update error')
                this.$message.error('修改出错')
              // 传递给父组件Literature的数据
              var ud = { 'id': updatedic.id, 'year': updatedic.year, 'title': updatedic.now_title_name }
              this.$emit('updatedData', ud)
            })
          this.updateFormVisible = false
        } else {
          console.log('error update!!');
          return false;
        }
      });
    },
    // 删除按钮事件
    submitdelete() {
      this.deleteVisible = false
      let _this = this
      service({
        url: 'articles/delete', method: 'post', data: _this.clickedLiteratureurl
      })
        .then((response) => {
          console.log(response.data.msg)
          if (response.data.msg == 'delete success')
            this.$message({
              message: '已删除',
              type: 'success'
            });
          if (response.data.msg == 'delete error')
            this.$message.error('删除出错')

          this.$emit('deleted', '1')       // 传递给父组件Literature的数据
          this.$router.push({
            path: '/literature',
            query: {
              id: 1,
            },
          })

        })
    },



  },
  created() {
    // 获取影片名
    this.axios.get("https://api.movie.yingshinet.com/movies").then((response) => {
      for (var i = 0; i < response.data.m_data.length; i++) {
        this.movie.push(response.data.m_data[i].m_name);
      }
    });
    //获取人物名
    this.axios.get("https://api.movie.yingshinet.com/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i].f_name);
      }
    });
  },
}
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.content_key{
  height: auto;
  font-size: 15px; 
  color:gray;
  text-align: left;
  line-height:30px;
  font-weight:bold;
}
.content_value{
  height: auto; 
  font-size: 15px; 
  color:gray;
  text-align: left;
  line-height:30px
}
</style>