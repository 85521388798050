<template>
  <div
    :class="this.$store.state.isCollapse ? 'container_collapse' : 'container'"
  >
    <el-row class="top-section">
      <!-- 图片右上角的文字 -->
      <div class="top-section-text">
        &nbsp;&nbsp;由北京大学艺术学院与中国传媒大学文化大数据实验室合作开发的中国电影知识体系平台，基于批判性的数字人文视野，试图在平台革命的宏观趋势中深入考察从数据库到数字平台的电影研究路径，通过知识论、知识管理学与知识情境分析的研究框架，跟主体性的价值观、整体性的组织方式与具体化的实际应用结合在一起，以“影人年谱”“电影计量”“知识生成”“电影百科”“文献索引”等五个特色数据库为中心，搭建一个学术导向、优特数据、众包群智与开源共享的、具有思辨性和成长性的中国电影学术平台与中国电影数字基础设施，并力图与中国电影的史论研究、学术创新以及公益服务互动共生，为反思中国电影实践、推进中国电影学派并构建中国电影知识体系奠定必要的基础。
      </div>
    </el-row>
    <div class="bottom-section">
      <div class="left-section">
        <!-- 这里使用后端接口来获取图片链接 -->
        <a href="#/directors?=郑正秋" style="max-width: 30%" target="_blank">
          <img class="left-section-image" src="../../../public/static/recommendFigure/FIGURE_1.jpg" alt="图片1" />
        </a>
        <a href="#/directors?=史东山" style="max-width: 30%" target="_blank">
          <img class="left-section-image" src="../../../public/static/recommendFigure/FIGURE_2.jpg" alt="图片2" />
        </a>
        <a href="#/directors?=费穆" style="max-width: 30%" target="_blank">
          <img class="left-section-image" src="../../../public/static/recommendFigure/FIGURE_3.jpg" alt="图片3" />
        </a>
        <a href="#/directors?=蔡楚生" style="max-width: 30%" target="_blank">
          <img class="left-section-image" src="../../../public/static/recommendFigure/FIGURE_4.jpg" alt="图片4" />
        </a>
        <a href="#/directors?=卜万苍" style="max-width: 30%" target="_blank">
          <img class="left-section-image" src="../../../public/static/recommendFigure/FIGURE_5.jpg" alt="图片5" />
        </a>
        <a href="#/directors?=桑弧" style="max-width: 30%" target="_blank">
          <img class="left-section-image" src="../../../public/static/recommendFigure/FIGURE_6.jpg" alt="图片6" />
        </a>
        
      </div>
      <div class="right-section">
        <!-- 这里使用后端接口来获取视频链接 -->
        <video
          class="video-wrapper"
          controls
          poster="path/to/default/cover.jpg"
        >
          <source src="" type="video/mp4" />
          <!-- 提供备用的视频格式 -->
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
var xhr1 = new XMLHttpRequest();
xhr1.open("GET", "https://api.movie.yingshinet.com/homepage_video", false);
xhr1.setRequestHeader("Content-type", "application/json");
xhr1.onreadystatechange = function () {
  if (xhr1.readyState === 4 && xhr1.status === 200) {
  }
};
xhr1.send();
var json1 = xhr1.responseText;

var data1 = JSON.parse(json1);
//视频
var replacementVideoUrl = [];
data1.m_data.map((href) => {
  replacementVideoUrl.push(href.m_video);
});
//影人

//所有元素加载完替换
document.addEventListener("DOMContentLoaded", function () {
  //视频
  var rightSectionVideo = document.querySelector(".video-wrapper source");
  rightSectionVideo.setAttribute("src", replacementVideoUrl);
  var rightSectionVideo = document.querySelector(".video-wrapper");
  rightSectionVideo.setAttribute("crossOrigin", "anonymous");

  // 获取第10帧的时间点（假设每秒30帧，即第10帧为0.3秒）
  var targetTime = 0.7;

  // 设置video的currentTime到第10帧的时间点
  rightSectionVideo.currentTime = targetTime;

  // 视频加载完成后执行回调函数
  rightSectionVideo.addEventListener("loadeddata", function () {
    // 创建一个canvas元素来绘制视频帧
    var canvas = document.createElement("canvas");
    canvas.width = rightSectionVideo.videoWidth;
    canvas.height = rightSectionVideo.videoHeight;
    var ctx = canvas.getContext("2d");

    // 在canvas上绘制第10帧
    ctx.drawImage(rightSectionVideo, 0, 0, canvas.width, canvas.height);

    // 获取canvas上的图像数据（即第10帧的图像数据）
    var coverImage = canvas.toDataURL();

    // 设置封面图像
    rightSectionVideo.setAttribute("poster", coverImage);
  });

  rightSectionVideo.load(); //确保加载的视频是替换完成链接后的

});

export default {
  data() {
    return {
      work: [],
      tableData: [],
      tableData_length: "",
    };
  },

  methods: {

  },
  mounted() {
  },
};
</script>

<style>
.el-slider__bar {
  background-color: #e2a253 !important;
}

.el-slider__button {
  border: 2px solid #e2a253 !important;
}
</style>

<style scoped>
.body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 86vh;
  margin-top: 0;
}

.top-section {
  flex: 2;
  background: url("../../../public/static/home_back.jpg") center/cover no-repeat;
  background-size: cover;
  position: sticky;
  color: white;
}
.container_collapse {
  padding-left: 8%;
  padding-right: 8%;
}
.container_collapse > .top-section {
  height: 16vh;
  background: url("../../../public/static/home_back.jpg") top/contain no-repeat;
  background-size: contain;
}
.container_collapse > .top-section > .top-section-text {
  right: 20px;
  font-size: 4px;
  margin-left: 40vw;
  margin-top: 3vh;
  font-family: "汉仪中简宋", HanYiZhongJianSong, serif; /* 使用楷体字体，如果用户设备中没有楷体，会回退到系统中的默认衬线字体 */
  color: #e2a253;
}
.top-section-text {
  position: absolute;
  right: 20px;
  font-size: 14px;
  margin-left: 40vw;
  margin-top: 8vh;
  font-family: "汉仪中简宋", HanYiZhongJianSong, serif; /* 使用楷体字体，如果用户设备中没有楷体，会回退到系统中的默认衬线字体 */
  color: #e2a253;
}

.bottom-section {
  flex: 1;
  display: flex;
}
.container_collapse > .bottom-section {
  display: block;
}
.left-section {
  flex: 2;
  /* 设置左侧图片占据更大空间 */
  display: flex;
  flex-direction: row;
  /* 将图片横向排列 */
  justify-content: flex-start;
  /* 保持图片靠左对齐 */
  align-items: center;
  /* 垂直居中图片 */
}
.container_collapse > .bottom-section > .left-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* 横向分布 */
  align-items: center; /* 垂直居中 */
}

.container_collapse > .bottom-section > .left-section .left-section-image {
  width: 100%;
  height: 140px;
  margin-bottom: 2px; /* 图片之间的垂直间距 */
  object-fit: cover;
  filter: grayscale(80%);
}
.left-section-image {
  width: 100%;
  /* 调整图片大小 */
  height: 35vh;
  object-fit: cover;
  filter: grayscale(80%);
}

.right-section {
  flex: 1;
  /* 设置右侧视频占据较小空间 */
  height: 35vh;
  display: flex;
  justify-content: center;
  /* 将视频水平居中 */
  align-items: center;
  /* 将视频垂直居中 */
  background-color: silver;
}

.container_collapse > .bottom-section > .right-section {
  width: 100%;
  display: flex;
  justify-content: center;
  /* 将视频水平居中 */
  align-items: center;
  /* 将视频垂直居中 */
  background-color: white;
}

.video-wrapper {
  max-width: 100%;
  max-height: 100%;
}

/* ... 其他样式 ... */
</style>
