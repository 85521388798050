// fm注册
<template>
  <div>
    <div align="middle">
      <img
        src="/static/home_logo.svg"
        align="middle"
        class="home_image"
        @click="gohome()"
        style="margin-top: 15px; height:16vw"
      />
    </div>
    <el-form
      ref="registerForm"
      :model="form"
      :rules="rules"
      label-width="80px"
      class="register-box"
    >
      <h3 class="register-title">注册一个账号吧</h3>
      <el-form-item label="用户名" prop="username">
        <el-input placeholder="请输入您的用户名" v-model="form.username" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input placeholder="备注" v-model="form.remarks" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" placeholder="请设置密码" v-model="form.password" />
      </el-form-item>
      <el-form-item label="确认密码" prop="check_password">
        <el-input type="password" placeholder="请再次输入您的密码" v-model="form.check_password" />
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click="onSubmit('registerForm')">注册</el-button>
        <el-button @click="resetForm('registerForm')">重置</el-button>
      </el-form-item>
    </el-form>

    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
      <span>输入有误</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    var checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("用户名不能为空"));
      }
      callback();
    };
    var checkRemarks = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("尽量输入备注"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.check_password !== "") {
          this.$refs.registerForm.validateField("check_password");
        }
        callback();
      }
    };
    var validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        username: '',
        remarks: '',
        password: '',
        check_password: '',
      },
      rules: {
        username: [
          { required: true, validator: checkUsername, trigger: "blur" },
        ],
        remarks: [
          { required: false, validator: checkRemarks, trigger: "blur" }
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        check_password: [
          { required: true, validator: validatePassCheck, trigger: "blur" },
        ],
      },
      dialogVisible: false,
    };
  },
  methods: {
    gohome() {
      this.$router.push('/')
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.post("https://api.movie.yingshinet.com/register", { name: this.form.username, password: this.form.password, info: this.form.remarks })
            .then(data => {
              //注册成功
              if (data.data == "success") {
                this.$message({
                  message: '注册申请已提交，请等待审核',
                  type: 'success'
                });
                this.$router.push('/home')
                //注册失败
              } else {
                this.$message({
                  message: '注册失败！',
                  type: 'warning'
                });
              }
            }).catch(error => {
              if (error.response.data.msg == 'UserName has existed') {
                this.$message({
                  message: '注册失败，用户名已存在！',
                  type: 'warning'
                });
              }
            });
        } else {
          this.dialogVisible = true;
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
};
</script>

<style lang="css" scoped>
.register-box {
  border: 1px solid #b3b3b3;
  width: 500px;
  margin: 10px auto 20vh auto;
  padding: 20px 50px 0px 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 0 15px #b3b3b3;
}
.home_image:hover {
  cursor: pointer;
}
.register-title {
  text-align: center;
  margin: 0 auto 20px auto;
  color: #303133;
}
</style>
<style>
.el-form-item {
  margin-bottom: 22px;
}
</style>