<template>
    <div style="height: 95vh">
        <el-table :data="register_data" style="width: 100%; margin-bottom:20vh">
            <el-table-column label="注册时间" style="width: 30vw" align="center">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="用户名" style="width: 30vw" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <p>姓名: {{ scope.row.name }}</p>
                        <p>备注: {{ scope.row.info }}</p>
                        <div slot="reference" class="name-wrapper">
                            <el-tag type="warning">{{ scope.row.name }}</el-tag>
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="备注" style="width: 30vw" align="center">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.info }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="small" type="warning" @click="handleAccept(scope.row)">接受</el-button>
                    <el-button size="small" @click="handleReject(scope.row)">忽略</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import service from "../utils/request";
export default {
    data() {
        return {
            register_data: [],
        };
    },
    methods: {
        handleAccept(row) {
            console.log(row.uid);
            service({ url: '/verify', method: 'post', data: { id: row.uid } })
                .then(response => {
                    console.log(response.data)
                    this.$message({
                        message: '通过',
                        type: 'success',
                    });
                    this.getData()
                });
        },
        handleReject(row) {
            service({ url: '/verifyrefuse', method: 'post', data: { id: row.uid } })
                .then(response => {
                    console.log(response.data)
                    if (response.data.msg == 'delete success') {
                        this.$message({
                            message: '拒绝',
                            type: 'success',
                        });
                        this.getData()
                    }
                });
        },
        getData() {
            this.register_data = []
            this.axios.get('https://api.movie.yingshinet.com/showVerify')
                .then(response => {
                    for (var i = 0; i < response.data.count; i++) {
                        this.register_data.push({
                            "time": response.data.data[i].time,
                            "name": response.data.data[i].name,
                            "info": response.data.data[i].info,
                            "uid": response.data.data[i].uid
                        })
                    }
                })
        }
    },

    created() {
        this.getData()
    },
};
</script>

<style scoped>
</style>
