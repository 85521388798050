<template>
    <div>
        <!-- <input v-model="keyword" @input="searchEvent"  /> -->
        <el-row>
            <el-col :span="24">
                <!-- 搜索框 -->
                <el-input
                    v-model="search"
                    @input="searchEvent"
                    @focus="focus"
                    @blur="blur"
                    @keyup.enter.native="searchHandler"
                    placeholder="搜索作品"
                >
                <!-- 搜索框按钮 -->
                    <el-button
                        slot="append"
                        icon="el-icon-search"
                        id="search"
                        @click="searchHandler"
                    ></el-button>
                </el-input>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <!-- 搜索框下方卡片 -->
                <el-card
                    @mouseenter="enterSearchBoxHanlder"
                    v-if="isSearch"
                    class="box-card"
                    id="search-box"
                    style="position:absolute;z-index:15"
                >
                <!-- 这是啥 -->
                    <div v-if="auto_search_show">
                        <div
                            v-for="(data,i) in tableData"
                            :key="i"
                            @click="enteressay(data.title)"
                            style="font-size:15px;margin-bottom:15px;color:gray;"
                        >{{ data.title }}</div>
                    </div>
                    <!-- 历史搜索 -->
                    <dl v-if="isHistorySearch && !auto_search_show">
                        <dt class="search-title" style="margin-top: -20px;" v-show="history">历史搜索</dt>
                        <dt
                            class="remove-history"
                            style="margin-top: -20px;"
                            v-show="history"
                            @click="removeAllHistory"
                        >
                            <i class="el-icon-delete"></i>清空历史记录
                        </dt>
                        <el-tag
                            v-for="search in historySearchList"
                            :key="search.id"
                            closable2
                            :type="search.type"
                            @close="closeHandler(search)"
                            @click="enteressay(search.name)"
                            style="margin-right:5px; margin-bottom:10px;"
                        >{{ search.name }}</el-tag>
                        <dt class="search-title">热门搜索</dt>
                        <el-tag
                            type="danger"
                            v-for="search in hotSearchList"
                            :key="search.id"
                            style="margin-right:5px; margin-bottom:10px;"
                            @click="enteressay(search)"
                        >{{ search }}</el-tag>
                    </dl>
                    <!-- 搜索框有内容搜索结果 -->
                    <dl v-if="isSearchList && !auto_search_show">
                        <el-tag
                            v-for="search in searchList"
                            :key="search.id"
                            style="margin-right:5px; margin-bottom:40px;"
                        >{{ search }}</el-tag>
                    </dl>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import RandomUtil from "../../../utils/randomUtil";
import Store from "../../../utils/store";
export default {
    data() {
        return {
            keyword: '',
            timer: null,
            len: false,
            searchData: [],
            tableData: [],
            search: "", //当前输入框的值
            isFocus: false, //是否聚焦
            hotSearchList: [], //热门搜索数据
            historySearchList: [], //历史搜索数据
            history: false,
            types: ["", "success", "info", "warning", "danger"], //搜索历史tag式样
            auto_search_show: false,
            searchData_len: 0
        }
    },
    methods: {
        get_search_data(content) {
            const path = "https://api.movie.yingshinet.com/articles/select";
            this.axios
                .post(path, content)
                .then((res) => {
                    for (var i = 0; i < res.data.m_count; i++) {
                        this.searchData[i] = {};
                        this.searchData[i].id = res.data.m_data[i].id;
                        this.searchData[i].title = res.data.m_data[i].title;
                        this.searchData[i].thumbnail = res.data.m_data[i].thumbnail;
                        this.tableData.push(this.searchData[i])
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        searchEvent() {
            this.clearTimer()
            if (this.search && this.search.length > 0) {
                this.auto_search_show = true
                this.searchData = []
                this.tableData = []
                this.len = true
                this.timer = setTimeout(() => {
                    console.log(this.search)
                    const content = {
                        'label': this.$store.state.current_figure,
                        'title': this.search
                    }

                    this.get_search_data(content)
                }, 500)

            }
            else {
                if (this.len) {
                    this.searchData = []
                    this.tableData = []
                    console.log(this.search)
                    const content = {
                        'label': this.$store.state.current_figure,
                        'title': this.search
                    }

                    this.get_search_data(content)
                }
                if (this.search === '') {
                    this.auto_search_show = false
                    this.len = false
                    return
                }
            }
        },
        clearTimer() {
            if (this.timer) {
                clearTimeout(this.timer)
            }
        },
        focus() {
            this.isFocus = true;
            this.historySearchList =
                Store.loadHistory() == null ? [] : Store.loadHistory();
            this.history = this.historySearchList.length == 0 ? false : true;
        },
        blur() {
            var self = this;
            this.searchBoxTimeout = setTimeout(function () {
                self.isFocus = false;
            }, 300);
        },
        enterSearchBoxHanlder() {
            clearTimeout(this.searchBoxTimeout);
        },
        searchHandler() {
            //随机生成搜索历史tag式样
            let n = RandomUtil.getRandomNumber(0, 5);
            let exist =
                this.historySearchList.filter(value => {
                    return value.name == this.search;
                }).length == 0
                    ? false
                    : true;
            if (!exist) {
                this.historySearchList.push({ name: this.search});
                Store.saveHistory(this.historySearchList);
            }
            this.history = this.historySearchList.length == 0 ? false : true;
            const path = "https://api.movie.yingshinet.com/articles/select";
            const content = {
                'label': this.$store.state.current_figure,
                'title': this.search
            }
            this.axios
                .post(path, content)
                .then((res) => {
                    for (var i = 0; i < res.data.m_count; i++) {
                        this.searchData[i] = {};
                        this.searchData[i].id = res.data.m_data[i].id;
                        this.searchData[i].title = res.data.m_data[i].title;
                        this.searchData[i].year = res.data.m_data[i].year;
                        this.searchData[i].file_dir = res.data.m_data[i].file_dir;
                    }
                    // this.id = res.data.mid;
                    this.$emit('searchResults', this.searchData)
                    this.$emit('searchUrl', this.searchData)
                    console.log(this.searchData);
                    // location.reload()
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        closeHandler(search) {
            this.historySearchList.splice(this.historySearchList.indexOf(search), 1);
            Store.saveHistory(this.historySearchList);
            clearTimeout(this.searchBoxTimeout);
            if (this.historySearchList.length == 0) {
                this.history = false;
            }
        },
        removeAllHistory() {
            Store.removeAllHistory();
        },
        enteressay(name) {
            let n = RandomUtil.getRandomNumber(0, 5);
            this.historySearchList.push({ name: name});
            Store.saveHistory(this.historySearchList);
            const path = "https://api.movie.yingshinet.com/articles/select";
            const content = {
                title: name
            }
            this.axios
                .post(path, content)
                .then((res) => {
                    this.$router.push({
                        path: '/literature',
                        query: {
                            id: res.data.m_data[0].id,
                        },
                    })
                    location.reload()
                    // console.log(res.data.m_data[0].mid)
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        // getHotList() {
        //     const path = "https://api.movie.yingshinet.com/articles";
        //     this.axios
        //         .get(path)
        //         .then((res) => {
        //             // for (var i = 0; i < res.data.a_count; i++) {
        //             //     if (res.data.a_data[i].label == '推荐') {
        //             //         this.hotSearchList.push(res.data.e_data[i].title)
        //             //     }
        //             // }
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //         });
        // }
    },
    created() {
        // this.getHotList()
    },
    computed: {
        isHistorySearch() {
            return this.isFocus && !this.search;
        },
        isSearchList() {
            return this.isFocus && this.search;
        },
        isSearch() {
            return this.isFocus;
        }
    }
}
</script>
<style scoped>
.selectfont {
    color: gray;
    font-size: 15px;
    margin-top: 10px;
}
.mouse_hover:hover {
    cursor: pointer;
}
#search {
    background-color: #ffc78e;
    border-radius: 0%;
}
.search-title {
    color: #bdbaba;
    font-size: 13px;
    margin-bottom: 15px;
}
.remove-history {
    color: #bdbaba;
    font-size: 10px;
    float: right;
    /* margin-top: -22px; */
}
#search-box {
    width: 100%;
    /* height: 10%; */
    margin-top: 0px;
    padding-top: -100px;
    padding-bottom: 20px;
}
</style>