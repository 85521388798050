<template>
  <!-- 底部第一行容器 -->
  <div class="countrys-Map" style="height: 50vh; width: 90vw; margin: 0 auto">
    <div id="container" height="100%"></div>
  </div>
</template>

<script>
import axios from "axios";
import * as $ from "jQuery";
import { lazyBMapApiLoaderInstance } from "vue-bmap-gl";
export default {
  name: "DirectorMap",
  props: { cities: Array },
  data() {
    return {
      map: null,
      centerPoint: {
        lng: 116.404,
        lat: 39.915,
      },
      zoom: 0.1,
      mapType: "BMAP_EARTH_MAP",
      countrys: [],
      current_marker_color: "",
      label: null,
      all_cities: this.cities,
    };
  },
  watch: {
    cities: {
      handler(newValue, oldValue) {
        this.all_cities = newValue;
        this.init();
      },
      deep: true, // 默认值是 false，代表是否深度监听
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    NavigationControl3D() {
      // eslint-disable-next-line no-undef
      var navi3DCtrl = new BMapGL.NavigationControl3D(); // 添加3D控件
      this.map.addControl(navi3DCtrl);
    },
    init() {
      lazyBMapApiLoaderInstance.load().then(() => {
        //获取地图地名
        this.axios
          .post("https://api.movie.yingshinet.com" + "/chronicles/map", {
            figure: this.$store.state.current_figure,
          })
          .then((response) => {
            this.all_cities = [];
            for (let i = 0; i < response.data.all_cities.length; i++) {
              this.all_cities[i] = response.data.all_cities[i].all_cities;
            }
            const GL = BMapGL;
            this.map = new GL.Map("container", {
              minZoom: 1,
              maxZoom: 20,
            }); // 创建Map实例
            this.map.centerAndZoom(
              new GL.Point(this.centerPoint.lng, this.centerPoint.lat),
              this.zoom
            );
            // 绘制带高度的点
            var cityGeo = new BMapGL.Geocoder();
            for (let i = 0; i < this.all_cities.length; i++) {
              var that = this;
              cityGeo.getPoint(that.all_cities[i], function (point) {
                if(that.all_cities[i]=="香港特别行政区"){
                  point.lng = 114.18612410257
                  point.lat = 22.29358599328
                }
                else if(that.all_cities[i]=="澳门特别行政区"){
                  point.lng = 113.55751910182
                  point.lat = 22.204117988443
                }
                else if(that.all_cities[i]=="台湾市"){
                  point.lng = 121.565
                  point.lat = 25.0378
                }
                else{}
                if((that.all_cities[i]=="北京市" && point.lng == 116.4133836971231)||(that.all_cities[i]!="北京市" && point.lng != 116.4133836971231)){
                // 地址解析获取对应经纬度
                var pt = new BMapGL.Point(point.lng, point.lat);
                console.log(pt)
                var marker = new BMapGL.Marker3D(pt, 100*i, {
                  size: 30,
                  shape: "BMAP_SHAPE_CIRCLE",
                  fillColor: "#454399",
                  fillOpacity: 0.6,
                });
                // 添加鼠标事件
                marker.addEventListener("mouseover", function (e) {
                  this.current_marker_color = e.target.getFillColor();
                  //添加label，鼠标未点击时隐藏
                  var marker_position = e.target.getPosition();
                  var number = e.target.getHeight()/100;
                  this.label = new BMapGL.Label(that.all_cities[number], {
                    position: marker_position,
                  });
                  this.label.setStyle({
                    border: "none",
                    color: "black",
                    backgroundColor: "transparent",
                    fontSize: "20px",
                    opacity: "1",
                  });
                  e.target.setFillColor("red");
                  that.map.addOverlay(this.label);
                });
                marker.addEventListener("mouseout", function (e) {
                  e.target.setFillColor(this.current_marker_color);
                  //删除属性为Label的覆盖物
                  var allOverlay = that.map.getOverlays();
                  this.label.setStyle({
                    border: "none",
                    color: "black",
                    backgroundColor: "transparent",
                    fontSize: "20px",
                    opacity: "0",
                  });
                });
                that.map.addOverlay(marker);
                }
              });
            }
            this.map.enableScrollWheelZoom(true);
            this.map.setMapType(this.mapType);
            this.NavigationControl3D();
          });
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.el-carousel__indicators li {
  border: 0.5vw;
}
.el-card__body {
  padding: 0.5vw;
}
.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.countrys-word:hover {
  cursor: pointer;
  text-decoration: none;
}
.footerCard .el-card__body {
  padding: 0.5vw;
}
#container {
  width: 100%;
  height: 50vh;
}
</style>
</script>