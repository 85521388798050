<template>
  <div>
    <el-table
      :data="table_data"
      :key="sort_button"
      style="width: 90%; margin: 20px auto"
      :row-style="{ height: '80px' }"
    >
      <el-table-column align="center" min-width="10%">
        <template slot="header">
          <el-button @click="sort_rise" v-if="sort_button">正序</el-button>
          <el-button @click="sort_drop" v-else>倒序</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="编号" prop="random" min-width="15%"></el-table-column>
      <el-table-column align="center" label="著作" prop="name" min-width="20%"></el-table-column>
      <el-table-column align="center" label="作者" prop="writer" min-width="15%"></el-table-column>
      <el-table-column align="center" label="出版社" prop="publisher" min-width="15%"></el-table-column>
      <el-table-column align="center" label="发表时间" prop="year" min-width="12%"></el-table-column>
      <el-table-column align="center" label="封面" min-width="20%">
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic != null"
            style="height: 75px; border-radius: 8px; width: 80px"
            :src="scope.row.pic"
          ></el-image>
          <div v-if="scope.row.pic == null">暂无封面</div>
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="15%">
        <template slot="header" slot-scope="scope">
          <el-input v-model="book_search" size="mini" placeholder="搜索著作名" />
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-if="$store.state.login_state"
            type="warning"
            @click="handleEdit(scope.$index, scope.row)"
          >修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align:center; margin:5vh 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData_length"
    ></el-pagination>

    <!-- 添加按钮 -->
    <span class="elebutton">
      <el-button
        style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa"
        class="el-backtop"
        icon="el-icon-plus"
        @click="dialog = true"
        circle
        v-if="this.$store.state.login_state"
      ></el-button>
    </span>
    <!-- 新添加著作页面 -->
    <el-dialog
      class="el-dia"
      title="请添加著作信息："
      :visible.sync="dialog"
      width="100%"
      height="100%"
      fullscreen
    >
      <el-dialog title="提示" :visible.sync="make_sure_add" width="100%" append-to-body>
        <span>确定要添加信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload
        class="upload-demo"
        ref="upload"
        list-type="picture"
        action="alert"
        :limit="limit_num"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-change="handleChange"
        :auto-upload="false"
        style="margin: 15px 0"
      >
        <el-button slot="trigger" size="medium" type="warning">点击上传著作封面图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left: 3px">支持上传jpg/jpeg/png文件，且不超过500kb</div>
      </el-upload>
      <el-form>
        <el-form-item label="著作" width="100px" style="margin-top: 15px">
          <el-input v-model="book_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="作者" width="100px" style="margin-top: 15px">
          <el-input v-model="writer" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出版社" width="100px" style="margin-top: 15px">
          <el-input v-model="publisher" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发表时间" width="100px" style="margin-top: 15px">
          <el-input v-model="year" autocomplete="off"></el-input>
        </el-form-item>
                <el-form-item label="著作类型" width="100px" style="margin-top: 15px">
       <select v-model="book_type" autocomplete="off" name="">
          <option value="book">专著</option>
          <option value="journal">期刊</option></select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="warning" @click="add_director()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改著作页面 -->
    <el-dialog class="el-dia" title="著作编辑" :visible.sync="dialogEditVisible" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload
        class="upload-demo"
        ref="upload"
        list-type="picture"
        action
        :limit="limit_num"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-change="handleChange"
        :auto-upload="false"
        style="margin: 15px 0"
      >
        <el-button slot="trigger" size="medium" type="warning">点击上传著作封面图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left: 3px">支持上传jpg/jpeg/png文件，且不超过500kb</div>
      </el-upload>
      <el-form>
        <el-form-item label="著作" width="100px" style="margin-top: 15px">
          <el-input v-model="now_book_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="作者" width="100px" style="margin-top: 15px">
          <el-input v-model="now_writer" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出版社" width="100px" style="margin-top: 15px">
          <el-input v-model="now_publisher" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发表时间" width="100px" style="margin-top: 15px">
          <el-input v-model="now_pub_year" autocomplete="off"></el-input>
        </el-form-item>
          <el-form-item label="著作类型" width="100px" style="margin-top: 15px">
         <select v-model="now_book_type" autocomplete="off" name="">
          <option value="book">专著</option>
          <option value="journal">期刊</option></select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="warning" @click="edit_director()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "../utils/request";
export default {
  data() {
    return {
      path: "https://api.movie.yingshinet.com/books/pic/",
      work: [],
      tableData: [],
      //随机数的数组
      randomNum:[],
      scope: [],
      num: 0,
      book_search: "",
      dialog: false,
      dialogEditVisible: false,
      //新添加著作参数
      limit_num: 1,
      book_name: "",
      writer: "",
      publisher: "",
      year: "",
      book_type:"",
      image_file: [],
      new_pic_id: "",
      make_sure_add: false,
      //修改著作参数
      pic_id: "",
      id: "",
      now_book_name: "",
      now_book_type:"",
      now_writer: "",
      now_publisher: "",
      now_pub_year: "",
      make_sure: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length: "",
      //first search
      first_search: false,
      sort_button: false,
    };
  },
  computed: {
    table_data() {
      let book_search = this.book_search;
      if (book_search) {
        //搜索功能
        if (!this.first_search) {
          this.currentPage = 1;
        }
        this.first_search = true;
        // 执行搜索操作之前需要将currentPage进行初始化，否则可能出现查不到数据的情况
        //this.currentPage=1
        let list = this.tableData.filter(
          (data) =>
            !book_search ||
            data.name.toLowerCase().includes(book_search.toLowerCase())
        );
        let fenye = list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        this.tableData_length = list.length;
        //console.log("搜索中的currentpage："+this.currentPage)
        return list, fenye;
      }
      // 分页功能
      else {
        this.first_search = false;
        //所有数据的长度  赋值给分页组件中的total
        this.tableData_length = this.tableData.length;
        let fenye = this.tableData.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        //console.log("分页中的currentpage："+this.currentPage)
        return fenye;
      }

    },
  },
  methods: {
    sort_rise() {
      this.tableData.reverse();
      this.sort_button = false
    },
    sort_drop() {
      this.tableData.reverse();
      this.sort_button = true
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
      //console.log(this.pagesize)
    },
    handleCurrentChange(currentPage) {
      //console.log(this.currentPage)
      this.currentPage = currentPage;
    },
    // 显示确认添加弹窗
    add_director() {
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      //信息上传
      service({
        url: "/books/insert",
        method: "post",
        data: {
          book_name: this.book_name,
          writer: this.writer,
          pub_year: this.year,
          publisher: this.publisher,
          book_type:this.book_type,
        },
      }).then((response) => {
        if (response.data.msg == "insert success") {
          this.new_pic_id = response.data.id;
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          // 图片上传
          if (this.image_file.length == 1) {
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/books/pic/" + this.new_pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.data.msg == "success") {
                  this.$message({
                    message: "著作封面图片上传成功！",
                    type: "success",
                  });
                  location.reload();
                } else {
                  this.$message({
                    message: "著作封面图片好像出了点问题",
                    type: "warning",
                  });
                }
              });
          }
        } else {
          this.$message({
            message: "添加有点问题！",
            type: "warning",
          });
        }
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    getInfo() {
      const path = "https://api.movie.yingshinet.com/books_journal";
      this.axios
        .get(path)
        .then((res) => {
          this.work = res.data.data;
          this.num = res.data.count;
          //console.log(this.num)
          this.getData();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData() {
      //打乱顺序
      for(var i=0;i<this.num;i++){
        this.randomNum[i]=i+1
      }
      //console.log(this.randomNum)
      let n = this.num;
      while (n) {
        let j = Math.floor(Math.random() * n--);
        [this.randomNum[j], this.randomNum[n]] = [this.randomNum[n], this.randomNum[j]];
      }
      //console.log(this.randomNum)
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.work[i].id;
        this.tableData[i].name = this.work[i].book_name;
        this.tableData[i].writer = this.work[i].writer;
        this.tableData[i].publisher = this.work[i].publisher;
        this.tableData[i].year = this.work[i].pub_year;
        this.tableData[i].pic = this.work[i].book_pic;
        this.tableData_length = this.tableData.length;
        this.tableData[i].random = this.num-i;
        this.tableData[i].book_type=this.work[i].book_type;
        console.log(this);
        //console.log(this.tableData[i].random)
      }
      //把table按随机数的顺序排序
      this.tableData.sort(this.compare("random"))
      //console.log(this.tableData)
      this.$set(this.tableData);
    },
    compare(attr) {
      return function(a,b){
      var val1 = a[attr];
      var val2 = b[attr];
      return val1 - val2;
      }
   },
    handleEdit(index, row) {
      console.log(row);
      console.log(index);
      this.pic_id = row.no;
      this.id = row.no;
      this.now_book_name = row.name;
      this.now_writer = row.writer;
      this.now_pub_year = row.year;
      this.now_publisher = row.publisher;
      this.dialogEditVisible = true;
      this.now_book_type=row.book_type;
    },
    //著作编辑
    edit_director() {
      this.make_sure = true;
    },
    make_sure_click() {
      this.make_sure = false;
      this.dialogEditVisible = false;
      //信息上传
      service({
        url: "/books/update",
        method: "post",
        data: {
          id: this.id,
          now_book_name: this.now_book_name,
          now_writer: this.now_writer,
          now_pub_year: this.now_pub_year,
          now_publisher: this.now_publisher,
          now_book_type:this.now_book_type,
        },
      }).then((response) => {
        if (response.status == "200") {
          (this.book_name = this.now_book_name),
            (this.book_type = this.now_book_type),
            (this.writer = this.now_writer),
            (this.pub_year = this.now_pub_year),
            (this.publisher = this.now_publisher);
          this.$message({
            message: "基本信息修改成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: "基本信息修改好像出问题了！",
            type: "warning",
          });
        }
        // 图片上传
        if (this.image_file.length == 1) {
          let file = this.image_file[0].raw;
          let pic_param = new FormData(); //form对象
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          pic_param.append("file", file);
          this.axios
            .post(
              "https://api.movie.yingshinet.com/books/pic/" + this.pic_id,
              pic_param,
              config
            )
            .then((response) => {
              if (response.status == "200") {
                this.$message({
                  message: "著作封面图片修改成功！",
                  type: "success",
                });
                location.reload();
              } else {
                this.$message({
                  message: "著作封面图片好像出了点问题",
                  type: "warning",
                });
              }
            });
        }
      });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>
<style scoped>
::v-deep .el-button:focus, .el-button:hover {
    color: #e2a253 !important;
    border-color: #e2a253 !important;
    background-color: #fff6ec !important;
}
</style>