<template>
  <div style="margin-left:3%">
    <el-form ref="form" :model="form" label-width="100px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="电影名称*">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="导演*">
            <el-input v-model="form.director" @input="searchEvent"></el-input>
            <el-card @mouseenter="enterSearchBoxHanlder" v-if="auto_show && this.figureData.length !== 0"
              style="position:absolute;z-index:15;width:100%;padding-top:-20px;padding-bottom:-20px">
              <div v-for="(data, i) in figureData" :key="i" @click="addname(data)"
                style="font-size:13px;color:gray;margin-top:0px;margin-bottom:0px;">{{ data }}</div>
            </el-card>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="上映时间*">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.time" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" style="margin-top:-10px">
          <span style="font-size:10px;color:firebrick;margin-left: 100px;">编剧之间请以、隔开</span>
          <el-form-item label="编剧*">
            <el-input v-model="form.script"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="margin-top:-10px">
          <span style="font-size:10px;color:firebrick;margin-left: 100px;">主演之间请以、隔开</span>
          <el-form-item label="主演*">

            <el-input v-model="form.actor"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="margin-top:-10px">
          <span style="font-size:10px;color:firebrick;margin-left: 100px;">制片人之间请以、隔开</span>
          <el-form-item label="制片人/公司">
            <el-input v-model="form.producer"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="是否推荐">
            <el-switch v-model="form.label"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <span style="font-size:10px;color:firebrick;margin-left: 100px;">0-10分，无评分填6</span>
          <el-form-item label="豆瓣评分">
            <el-input v-model="form.douban_rank"></el-input>
          </el-form-item>

        </el-col>
        <el-col :span="6">
          <el-form-item label="摄影">
            <el-input v-model="form.camerist"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="影片类型">
        <el-checkbox-group v-model="form.type">
          <el-checkbox v-for="(data, i) in type" :key="i" :label="data" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-row>
        <el-col :span="18">
          <span style="font-size:10px;color:firebrick;margin-left: 100px;">空间有限，暂时不能上传影片，录入电影题录即可</span>
          <el-form-item label="剧情简介">
            <el-input type="textarea" :rows='8' v-model="form.intro"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="海报">
            <div style="display: inline-block; vertical-align: top">
              <el-upload action v-model="form.pic" :auto-upload="false" :class="{ disabled: uploadDisabled }"
                accept=".jpg, .png, .jpeg" list-type="picture-card" :file-list="imagelist" :on-preview="handlePreview"
                :on-remove="handleRemove" :on-change="handleChange">
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
                <i slot="default" class="el-icon-plus"></i>
                <div class="el-upload__tip" slot="tip">点击上传，只能上传一张海报；只能上传jpg/jpeg/png文件；</div>
              </el-upload>
            </div>
            <el-image v-if="form.filmImageFile" style="
            border-radius: 2%;
            display: inline-block;
            vertical-align: top;
            height: 150px;
          " :preview-src-list="[form.filmImageFile]" class="update_image" :alt="1" :src="form.filmImageFile">
            </el-image>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确认更新</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import service from '../../utils/request'
export default {
  data() {
    return {
      img_file: [],
      uploadDisabled: false,
      dialogImageUrl: '',
      dialogVisible: false,
      figureData: [],
      len: false,
      auto_show: false,
      new_movie_id: 0,
      img_src: '',
      type: ["剧情", "喜剧", "家庭", "爱情", "短片", "戏曲", "纪录片", "传记", "历史", "战争", '古装', '歌舞剧'],
      imagelist: [],
      form: {
        name: '',
        time: '',
        director: '',
        script: '',
        actor: '',
        producer: '',
        label: false,
        type: [],
        intro: '',
        desc: '',
        pic: '',
        filmImageFile: "",
        video: '',
        douban_rank: '',
        camerist: '',
      }
    }
  },
  methods: {
    onSubmit() {
      console.log(this.form.time);
      if (this.form.name && this.form.time && this.form.director) {
        const content = {
          mid: Number(this.$route.query.id),
          now_movie_name: this.form.name,
          now_director: this.form.director,
          now_open_year: this.form.time.length == 4 ? this.form.time : this.form.time.getFullYear(),
          now_producer: this.form.producer,
          now_script: this.form.script,
          now_actor: this.form.actor,
          now_label: this.label_Is(this.form.label),
          now_intro: this.form.intro,
          movie_type: this.type_Is(this.form.type),
          now_douban_rank: this.form.douban_rank,
        }
        console.log(content);
        this.upload_info(content)
      }
      else {
        this.$alert('电影名不能为空', '请检查您的输入', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `请重新填写`
            });
          }
        });
      }

    },
    upload_info(content) {
      service({
        url: 'movies/update', method: 'post', data: content
      })

        .then((response) => {
          if (response.data.msg == 'update success') {
            this.new_movie_id = this.$route.query.id
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            if (this.img_file.length > 0) {

              let file = this.img_file[0].raw
              let pic_param = new FormData() //form对象
              let config = {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
              pic_param.append('file', file)
              service({
                url: 'movies/pic/' + this.new_movie_id, method: 'post', data: pic_param, config: config
              })
                .then((response) => {
                  if (response.status == '200') {
                    this.$message({
                      message: '上传海报成功！',
                      type: 'success',
                    });
                    location.reload();
                    this.$router.push({
                      path: '/video',
                      query: {
                        id: this.$route.query.id,
                      },

                    })
                  } else {
                    this.$message({
                      message: '添加图片失败',
                      type: 'warning'
                    });
                  }
                })

            }
            // location.reload();
          }

        })
    },
    label_Is(label) {
      if (label) {
        return '推荐'
      }
      else {
        return ''
      }
    },
    type_Is(typearr) {
      var arr = ''
      for (var i = 0; i < typearr.length; i++) {
        if (i !== typearr.length - 1) {
          arr = arr + typearr[i] + '/'
        }
        else {
          arr = arr + typearr[i]
        }
      }
      return arr
    },
    handleRemove(file, fileList) {
      this.img_file.length = 0
      this.uploadDisabled = false;
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file, fileList) {
      this.uploadDisabled = true;
      this.img_file.push(file)
    },
    searchEvent() {
      this.clearTimer()
      if (this.form.director && this.form.director.length > 0) {
        this.figureData = []
        this.auto_show = true
        this.len = true
        this.timer = setTimeout(() => {
          const content = {
            f_name: this.form.director
          }

          this.get_search_data(content)
        }, 500)

      }
      else {
        if (this.len) {
          this.figureData = []
          const content = {
            f_name: this.form.director
          }

          this.get_search_data(content)
        }
        if (this.form.director === '') {
          this.auto_show = false
          this.len = false
          return
        }
      }
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    get_search_data(content) {
      const path = "https://api.movie.yingshinet.com/figures/select";
      this.axios
        .post(path, content)
        .then((res) => {
          for (var i = 0; i < res.data.f_count; i++) {
            this.figureData.push(res.data.f_data[i].f_name)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addname(name) {
      this.form.director = name
      this.auto_show = false
    },
    typename_to_typeid(typename) {
      var type_name = new Array()
      var type_id = new Array()
      type_name = type_name.concat(typename.replace(/\s*/g, "").split('/'))
      // for(var i=0;i<type_name.length;i++){
      //     type_id.push(this.type.indexOf(type_name[i]))

      // }
      return type_name
    }

  },
  created() {
    this.axios
      .post("https://api.movie.yingshinet.com/movies/select", {
        mid: this.$route.query.id,
      })
      .then((response) => {
        this.moviedata = response.data.m_data[0]
        this.form.name = response.data.m_data[0].m_name
        this.form.time = response.data.m_data[0].m_year
        this.form.director = response.data.m_data[0].m_director
        this.form.script = response.data.m_data[0].m_script
        this.form.actor = response.data.m_data[0].m_actor
        this.form.producer = response.data.m_data[0].m_producer
        this.form.label = (response.data.m_data[0].label == '推荐') ? true : false
        this.form.type = this.typename_to_typeid(response.data.m_data[0].movie_type)
        this.form.intro = response.data.m_data[0].m_intro
        this.form.douban_rank = response.data.m_data[0].douban_rank ? response.data.m_data[0].douban_rank : 0
        this.form.filmImageFile = response.data.m_data[0].m_pic ? response.data.m_data[0].m_pic : ""
        // console.log(this.form)
        // this.$set(this.moviedata);
        // this.getInfo(this.moviedata.m_director);
        // this.playerOptions.sources[0].src =
        //   this.moviedata.m_video ||
        //   "https://assets.yingshinet.com/chronicles/videos/TL_FM.mp4";
        // this.$nextTick(() => {
        //   this.scroll = new BScroll(this.$refs.wrapper, {});
        // });
      });
  },
}
</script>

<style>
.disabled .el-upload--picture-card {
  display: none;
}
</style>
