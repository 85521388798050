<template >
  <el-card class="force-directed" style="height: 92.5vh; width: 96vw; margin: 0 auto; margin-top: 3vh; position: relative "
    :body-style="{ padding: '10px' }">
    <form action="/network" v-show="!this.$store.state.isCollapse" style="font-size: 1.1vw; margin: 1vw 1vw;">
      Distance: <input style="position: relative; top: 0.25vw; width: 12vw" type="range" id="lengthscale" min="10" max="400" v-model="lengthdata">
      Charge: <input style="position: relative; top: 0.25vw; width: 12vw" type="range" id="chargescale" min="10" max="2000" v-model="chargedata">
    </form>
    <form action="/network" v-show="this.$store.state.isCollapse" style="font-size: 4vw;">
      Distance: <input style="position: relative; width: 25vw" type="range" id="lengthscale" min="10" max="400" v-model="lengthdata">
      Charge: <input style="position: relative; width: 25vw" type="range" id="chargescale" min="10" max="2000" v-model="chargedata">
    </form>
    <!-- 搜索框 -->
    <el-row class="select_block">
      <el-select class="select_box" v-model="select" filterable placeholder="影人|影片" @change="serach_item_change">
        <el-option label="影人" value="影人"></el-option>
        <el-option label="影片" value="影片"></el-option>
      </el-select>
      <el-select class="input_style" v-model="search_content" filterable :placeholder="`请输入内容`" 
        @change="handle_select" ref="elinput">
        <el-option v-for="(director, index) in search_option" :key="index" :label="director.m_name || director.f_name"
          :value="director.m_name || director.f_name">
        </el-option>
      </el-select>
      <el-button id="photo-download" plain round @click.native='downloadPhoto("svg")' style="z-index: 1">
        下载图片<i class="el-icon-download el-icon--right"></i>
      </el-button>
    </el-row>
    <!-- <el-autocomplete class="inline-input input_style" v-model="search_content" :fetch-suggestions="query_search"
      placeholder="请输入内容" @select="handle_select" ref="elinput">
      <el-select v-model="select" slot="prepend" placeholder="请选择" class="select_style" @change="serach_item_change">
        <el-option label="影人" value="影人"></el-option>
        <el-option label="影片" value="影片"></el-option>
      </el-select>
    </el-autocomplete> -->
    <!-- <el-dropdown id="photo-download"> -->
    <!-- <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native='downloadPhoto("svg")'>svg</el-dropdown-item>
        <el-dropdown-item @click.native='downloadPhoto("png")'>png</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->

    <div id="svgbox" style="width: 100%; height: 100%">
      <el-row>
        <el-col :span="this.$store.state.isCollapse ? 24 : 4">
          <div id="asidetip" style="height: 100%">
            <br />
          </div>
        </el-col>
        <el-col :span="this.$store.state.isCollapse ? 24 : 20">
          <svg id="forceDirected" style="width: 100%;"></svg>
        </el-col>
      </el-row>
    </div>

    <div id="svgdataurl"></div>
    <canvas style="display: none"></canvas>
    <span class="elebutton">
      <!-- 增加新事件 -->
      <el-button style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa" class="el-backtop" icon="el-icon-plus"
        @click="dialog = true" circle v-if="this.$store.state.login_state"></el-button>
      <el-dialog title="添加新的关系：" :visible.sync="dialog" style="font-size: 1.4vw; text-align: left" class="base_color">
        <div style="margin: 20px">
          <el-form>
            <el-row>
              <el-col :span="12">
                <el-select class="select_box" v-model="nodeclass1" filterable placeholder="影人|影片"
                  @change="addRelation1_select = ''">
                  <el-option v-for="(nodeclass, index) in nodesclass" :key="index" :label="nodeclass"
                    :value="nodeclass"></el-option>
                </el-select>

                <el-form-item class="select_box_input" style="margin-top: 10px" label="">
                  <el-select v-model="addRelation1_select" filterable :placeholder="`选择${nodeclass1}`">
                    <el-option v-for="(director, index) in (getOptions(nodeclass1))" :key="director.mid || director.fid"
                      :label="director.m_name || director.f_name" :value="director.mid || director.fid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="endForm-col">
                <el-select class="select_box" v-model="nodeclass2" filterable placeholder="影人|影片"
                  @change="addRelation2_select = ''">
                  <el-option v-for="(nodeclass, index) in nodesclass" :key="index" :label="nodeclass"
                    :value="nodeclass"></el-option>
                </el-select>

                <el-form-item class="select_box_input" style="margin-top: 10px" label="">
                  <el-select v-model="addRelation2_select" filterable :placeholder="`选择${nodeclass2}`">
                    <el-option v-for="(director, index) in (getOptions(nodeclass2))" :key="director.mid || director.fid"
                      :label="director.m_name || director.f_name" :value="director.mid || director.fid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item style="margin: 10px 0" label="关系 ">
              <el-input v-model="relation_content" type="input" :autosize="{ minRows: 6, maxRows: 16 }"></el-input>
            </el-form-item>
            <el-form-item style="margin: 10px 0 20px 0" label="备注">
              <el-input v-model="relation_memo" type="textarea" :autosize="{ minRows: 1, maxRows: 8 }"></el-input>
            </el-form-item>
          </el-form>
          <div style="width:100%">
            <div style="display: flex;justify-content: center;align-items: center;">
              <el-button style="
                  transition: 0.1s;
                  font-weight: 500;
                  padding: 12px 20px;
                  font-size: 14px;
                  border-radius: 4p;
                " @click="cancelForm">取 消</el-button>
              <el-button style="
                  transition: 0.1s;
                  font-weight: 500;
                  padding: 12px 20px;
                  font-size: 14px;
                  border-radius: 4p;
                " type="warning" @click="handleClose" :loading="loading">{{ loading ? "提交中 ..." : "确 定" }}</el-button>

            </div>

          </div>
        </div>
      </el-dialog>
    </span>
  </el-card>
</template>

<script>
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as $ from "jQuery";
import { loadHistory } from "../../../utils/store";

export default {
  components: {},
  name: "PicForce",
  props: {
  },
  data() {
    return {
      // 本地切换2/2
      path: "https://api.movie.yingshinet.com",
      nodes: {},
      links: {},
      dialog: false,
      loading: false,
      nodesclass: ["影人", "影片"],
      nodeclass1: "影人",
      nodeclass2: "影片",
      movies: [],
      edgelength: 200,
      edgewidth: 200,
      chargedata: 500,
      lengthdata: 80,
      directors: [],
      addRelation1_select: "",
      addRelation2_select: "",
      relation_content: "",
      relation_memo: "",
      // 搜索框
      search_content: "",
      search_option: [],
      select: "影人",
      keyJson: {
      },
    };
  },

  methods: {

    // 词云图起步获得初始数据
    getData() {
      // 初始为阮玲玉
      this.axios.post(this.path + "/figures/relation", this.keyJson).then((response) => {
        // console.log(response.data);
        this.nodes = response.data.nodes;
        this.links = response.data.links;
        this.initforcedirect();
      });
    },

    // 初始化力导向图的svg尺寸和绑定
    initforcedirect() {
      this.drawForceDirect();
      this.$nextTick(() => {
        document.querySelectorAll(".clickNodes").forEach((item) => {
          // console.log(item.classList[1].slice(2),"ss")  // 得到id
          item.addEventListener("dblclick", () => {
            // 词云图词汇内容
            const id = item.classList[1].slice(2);
            const name = item.classList[2].slice(4);
            if (id < 10000) {
              // 搜索
              const keyJson = {
                type: "figure"
              };
              keyJson.search = name,
              this.keyJson = keyJson,
              this.getData();

            } else {
              // this.$router.push('/video?id=' + (id - 10000))跳转
              // 搜索
              const keyJson2 = {
                type: "movie"
              };
              keyJson2.search = name,
              this.keyJson = keyJson2,
              this.getData();

            }
            // let keyword = item.textContent;
          });
        });
      });
    },

    drawForceDirect() {
      let marge = { top: 60, bottom: 20, left: 0, right: 60 };
      let svg = d3.select("#forceDirected");
      svg.selectAll("*").remove();
      let width = $(".force-directed").width() * (!this.$store.state.isCollapse? 1 : 1.6);
      let height = $(".force-directed").height() - 20;
      svg.attr("width", width)
         .attr("height", height);
      let g = svg.append('g')
                 .attr('transform', 'translate(' + marge.top + ',' + marge.left + ')')

      const img_h = !this.$store.state.isCollapse? 50 : 42;
      const img_w = !this.$store.state.isCollapse? 50 : 42;
      const radius= !this.$store.state.isCollapse? 23 : 18;
      let nodes = this.nodes;
      let edges = this.links;

      // 新建一个力导向图
      let forceSimulation = d3.forceSimulation()
        .force('link', d3.forceLink().id((d) => {
              // 根据指定的 links 以及默认参数创建一个弹簧力模型。如果没有指定 links 则默认为空数组。
              return d.id;
            })
            .distance((d) => { //连线数据 distance为连线的距离设置
              return !this.$store.state.isCollapse? 80 : 50;
            })
        )
        .force('charge', d3.forceManyBody().strength(-this.chargedata))  // 节点间的作用力
        .force('collide', d3.forceCollide().radius(() => 30))  // collide为节点指定一个radius区域来防止节点重叠
        .force('center', d3.forceCenter());

      // 生成节点数据
      forceSimulation.nodes(nodes)
                     .on('tick', ticked);
      // 生成边数据
      forceSimulation.force('link')
                     .links(edges);

      // 设置图形中心位置
      forceSimulation.force('center')
                     .x(width / 2 - 200)
                     .y(height / 2);

      d3.select("#chargescale").on("change", () => {
        console.log(this.value);
        forceSimulation.force("charge", d3.forceManyBody().strength(-this.value));
      })

      d3.select("#lengthscale").on("change", () => {
        console.log(this.value);
        forceSimulation.force("link").distance(this.value);
      })

      // 提示框部分
      let tooltip = d3.selectAll("body")
                      .append("div")
                      .attr("class", "tooltip")
                      .style("opacity", 0.0);
      // 箭头
      let marker = svg.append("marker")
                      .attr("id", "arrow")
                      .attr("markerUnits", "userSpaceOnUse")
                      .attr("viewBox", "0 -5 10 10")  // 坐标系的区域
                      .attr("refX", 28)  // 箭头坐标
                      .attr("refY", 0)
                      .attr("markerWidth", !this.$store.state.isCollapse? 12 :10)  // 标识的大小
                      .attr("markerHeight", !this.$store.state.isCollapse? 12 :10)
                      .attr("orient", "auto")  // 绘制方向，可设定为：auto（自动确认方向）和角度值
                      .attr("stroke-width", 2)  // 箭头宽度
                      .append("path")
                      .attr("d", "M0,-5L10,0L0,5")  // 箭头的路径
                      .attr('fill', '#e2a253');  // 箭头颜色

      // 非基于路径的力导向
      let links = g.append('g')
					         .selectAll('line')
					         .data(edges)
					         .enter()
					         .append('line')
					         .attr('stroke', (d, i) => {
					            // return colorScale(i)
					            return "#e2a253"
					         })
					         .attr('stroke-width', 1);

      // 绘制基于路径的边
      let paths = g.append('g')
                   .selectAll('path')
									 .data(edges)
									 .enter()
									 .append('path')
                   .attr("id", (d, i) => {
									    return "edgepath" + i;
									 })
                   .attr("class", "edges")
									 .attr('stroke', (d, i) => {
									    // return colorScale(i)
									    return "#e2a253"
									 })
                   .attr("marker-end", "url(#arrow)")
									 .attr('stroke-width', 1)
                   .attr("fill", "none");

      // 边上的文字
      let linksText = g.append('g')
                       .selectAll('text')
										   .data(edges)
										   .enter()
										   .append('text')
                       .append('textPath')  // 基于路径时用
										   .attr("text-anchor", "middle")
                       .attr("startOffset", "50%")
										   .attr('xlink:href', (d, i) => {
										      // console.log("s");
										      return "#edgepath" + i;
										   })  // 基于路径时用
										   .text((d) => {
										      return d.relation;
										   });

      // 创建分组
      let gs = g.selectAll('.circleText')
                .data(nodes)
								.enter()
								.append('g')
								.attr('transform', (d) => {
								  let cirX = d.x;
								  let cirY = d.y;
								  return 'translate(' + cirX + ',' + cirY + ')';
								})
                .attr("class", (d, i) => {
								  return "clickNodes" + " id" + d.id + " name" + d.name;
								})
								.call(d3.drag()
								  .on('start', started)
								  .on('drag', dragged)
								  .on('end', ended)
								);

      // 绘制节点
      let nodescircle = gs.append('circle')
                          .attr("class", "node")
													.attr('r', radius)
													.attr("fill", (d, i) => {
													  // 创建圆形图片
													  // console.log(svg);
													  var defs = svg.append("defs").attr("class", "imgdefs");
													  var catpattern = defs.append("pattern")
														    .attr("id", "catpattern" + i)
														    .attr("height", 1)
														    .attr("width", 1);
													  // console.log(catpattern);
													  catpattern.append("image")
														    .attr("x", - (img_w / 2 - radius + 5.8))
														    .attr("y", - (img_h / 2 - radius + 3.5))
														    .attr("width", img_w + 11)
														    .attr("height", img_h + 6)
														    // .attr("xlink:href",()=>{ console.log(d.image,"ss") ;return d.image})
														    .attr("xlink:href", d.image);
													  return "url(#catpattern" + i + ")";
													})

      // 绘制文字
      gs.append('text')
        // .attr('x', d => -d.name.length * 5)
        .attr("text-anchor", "middle")
        .attr("startOffset", "50%")
        .attr('y', -25)
        .attr('dy', 0)
        .text(function (d) {
          return d.name;
        });

      // ticked
      function ticked() {
        paths.attr("d", (d) => {  // 基于路径
          let dx = d.target.x - d.source.x;  // 增量
          let dy = d.target.y - d.source.y;
          return "M" + d.source.x + "," + d.source.y + "L" + d.target.x + "," + d.target.y;
        });
        gs.attr('transform', (d) => { 
          return 'translate(' + d.x + ',' + d.y + ')' 
        });
      }

      // 设置drag
      function started(e, d) {
        if (!e.active) {
          // 设置衰减系数，对节点位置移动过程的模拟，数值越高移动越快，数值范围[0, 1]
          forceSimulation.alphaTarget(0.8).restart();
        }
        d.fx = e.x;
        d.fy = e.y;
      }

      function dragged(e, d) {
        asidetip.style("opacity", 0)
                .html("<br/>");
        d.fx = e.x;
        d.fy = e.y;
      }

      function ended(e, d) {
        if (!e.active) {
          forceSimulation.alphaTarget(0);
        }
        d.fx = null;
        d.fy = null;
      }

      //asidetip
      let asidetip = d3.select("#asidetip")
                       .style("opacity", 0)
											 // .style("position", "absolute")
											 .attr("class", "asidetip")
											 .style("color", "black")
											 .style("border-radius", "5px");

      const mouseover = function (e, d) {
        // console.log(e.layerY);
        asidetip.html("" + (d.project || d.intro))
                .style("opacity", 0.8);
                // .style("z-index", 1)
								// if (e.layerY < 240) {
								//   asidetip
								//     .style("left", -10 + "px")
								//     .style("top", 410 + "px");
								// } else {
								//   asidetip
								//     .style("left", -10 + "px")
								//     .style("top", -10 + "px");
								// }
      }

      const mousemove = function (e, d) {
        asidetip.style("opacity", 0.8);
        // if (e.layerY < 240) {
        //   asidetip
        //     .style("left", -10 + "px")
        //     .style("top", 410 + "px");
        // } else {
        //   asidetip
        //     .style("left", -10 + "px")
        //     .style("top", -10 + "px");
        // }
      }

      const mouseout = function (e, d) {
        asidetip.style("opacity", 0)
                .html("<br/>");
        // console.log("mouseout");
      }

      nodescircle.on("mouseover", mouseover)
                 .on("mousemove", mousemove)
                 .on("mouseout", mouseout)

    },

    downloadPhoto(form = "svg") {
      // d3.select("#photo-download").on("click", function () {
      // 导出最基础的svg
      let html = d3.select("#forceDirected")
        .attr("version", 1.1)
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
        .node().parentNode.innerHTML;
      let imgsrc = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(html)));
      // 从 String 对象中创建一个 base-64 编码的 ASCII 字符串，其中字符串中的每个字符都被视为一个二进制数据字节。
      let image = new Image;
      image.src = imgsrc;
      if (form === "png") {
        let img = '<img src="' + imgsrc + '">';
        d3.select("#svgdataurl").html(img);
        let boxWidth = document.querySelector(".force-directed").offsetWidth
        let boxHeight = document.querySelector(".force-directed").offsetHeight
        let canvas = document.querySelector("canvas"),
          context = canvas.getContext("2d");
        canvas.height = boxHeight;//canvas高度变化时清空画布
        canvas.width = boxWidth;
        image.onload = function () {
          context.drawImage(image, 0, 0);
          let canvasdata = canvas.toDataURL("image/png");
          let pngimg = '<img src="' + canvasdata + '">';
          d3.select("#pngdataurl").html(pngimg);
          let photo = document.createElement("a");
          photo.download = "关系图.png";
          photo.href = canvasdata;
          document.body.appendChild(photo);
          photo.click();
        }
      } else {
        image.onload = function () {
          let photo = document.createElement("a");
          photo.download = "关系图.svg";
          photo.href = imgsrc;
          document.body.appendChild(photo);
          photo.click();
        }
      }
      // console.log(img); //此时是可以下载的svg了
      // console.log(imgsrc);//此时是可以打开svg了
      // 
      // console.log(d3.select("#svgdataurl").node().innerHTML);//此时是可以下载的svg了。
      // 上面和前面的实现是一样的

      // image.onload = function () {
      //   context.drawImage(image, 0, 0);
      //   let canvasdata = canvas.toDataURL("image/png");
      //   let pngimg = '<img src="' + canvasdata + '">';
      //   d3.select("#pngdataurl").html(pngimg);
      //   let photo = document.createElement("a");
      //   photo.download = "关系图.png";
      //   photo.href = canvasdata;
      //   document.body.appendChild(photo);
      //   photo.click();
      // }

      // })
    },

    getOptions(nodeclass) {
      return nodeclass == "影人" ? this.directors : this.movies;
    },

    cancelForm() {
      this.loading = false;
      this.dialog = false;
    },

    async handleClose(done) {
      // alert(this.addRelation1_select)
      // alert(this.addRelation2_select)
      let type_id = null
      if (this.nodeclass1 == "影人" && this.nodeclass2 == "影片") {
        type_id = 2;
      } else if (this.nodeclass1 == "影人" && this.nodeclass2 == "影人") {
        type_id = 1;
      } else if (this.nodeclass1 == "影片" && this.nodeclass2 == "影人") {
        type_id = 3;
      } else {
        type_id = 4;
      };
      if (
        this.addRelation1_select != '' &&
        this.addRelation2_select > 0 &&
        this.relation_content.trim() != ""
      ) {
        if (this.loading) {
          return;
        }
        this.$confirm("确定要添加关系吗？")
          .then((_) => {
            // console.log(_);
            this.loading = true;
            //逻辑
            console.log();
            service({
              url: "/figures/insert_relation",
              method: "post",
              data: {
                //传影人参数
                f_source: this.addRelation1_select,
                f_target: this.addRelation2_select,
                type_id: type_id,
                relationship: this.relation_content,
                relation_memo: this.relation_memo
              },
            }).then((response) => {
              if (response.data.msg == "Success") {
                this.$message({
                  message: "添加成功！",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "添加失败！",
                  type: "warning",
                });
              }
            }).then(() => {
              if (this.nodeclass1 == "影人") {
                const keyJson = {
                  type: "figure"
                };
                keyJson.search = this.directors.filter((i) => i.fid == this.addRelation1_select)[0].f_name;
                this.keyJson = keyJson;
              } else {
                const keyJson2 = {
                  type: "movie"
                };
                keyJson2.search = this.movies.filter((i) => i.mid == this.addRelation1_select)[0].m_name;
                this.keyJson = keyJson2;
              }
              this.addRelation1_select = '';
              this.addRelation2_select = '';
              this.relation_content = '';
              this.relation_memo = '';
              // 动画关闭需要一定的时间
              this.loading = false;
              this.dialog = false;
              this.getData();
            });
          })
          .catch((_) => {
            this.dialog = false;
          });
      } else {
        this.$message({
          message: "输入信息或网络错误，请检查！",
          type: "warning",
        });
      }
    },

    // 匹配搜索建议
    query_search(query_string, callback) {
      let results = []
      let restaurants = this.search_option.map(i => i.f_name || i.m_name);
      // 影人匹配
      for (var i = 0; i < restaurants.length; i++) {
        if (restaurants[i].includes(query_string.trim().toLowerCase())) {
          results.push({ value: restaurants[i] });
        }
      }
      callback(results);
    },

    // 匹配项点击
    handle_select(item) {
      switch (this.select) {
        case "影人":
          // 影人搜索
          const keyJson = {
            type: "figure"
          };
          keyJson.search = item,
            this.keyJson = keyJson,
            this.getData()
          break;
        case "影片":
          // 影片搜索
          const keyJson2 = {
            type: "movie"
          };
          keyJson2.search = item,
            this.keyJson = keyJson2,
            this.getData()
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });

      }
    },

    serach_item_change(value) {
      this.search_content = ""
      switch (value) {
        // 影人搜索
        case "影人":
          this.search_option = this.directors;
          this.$refs.elinput.focus()
          break;
        case "影片":
          // 影片搜索
          this.search_option = this.movies;
          this.$refs.elinput.focus()
          break;
        default:
      }
    },
  },

  created() {
    // 获取影片名
    this.axios.get(this.path + "/movies").then((response) => {
      for (var i = 0; i < response.data.m_data.length; i++) {
        this.movies.push(response.data.m_data[i]);
      }
      this.keyJson.type = "movie";
      this.keyJson.search = this.movies[Math.floor(Math.random() * i)].m_name;
      // console.log(this.keyJson);
      this.getData()
    });
    //获取人物名
    this.axios.get(this.path + "/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i]);
      }
      //初始化this.search_option为随机影人
      this.search_option = this.directors;
    });
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.select_block {
  margin: -3vw 10vw 0 0;
}
.select_box {
  display: inline-block;
  width: 8vw;
  left: 68%;
}
.input_style {
  width: 12vw;
  border-radius: 1vw;
  left: 68%;
}
#photo-download {
  position: relative;
  left: 70%;
}
#asidetip {
  line-height: 1.4vw;
}

.select_box_input {
  display: inline-block;
  /* width: 10vw; */
}

.endForm-col {
  text-align: end;
}

.serach_button_style {
  width: 5vw;
  color: #fff;
}

@media screen and (max-width: 768px) {
  * {
    font-size: 3vw;
  }
  .select_block {
    margin: 3vh 0 0 0;
  }
  .select_box {
    width: 22vw;
    left: 0%;
  }
  .input_style {
    width: 34vw;
    left: 0%;
  }
  #photo-download {
    left: 0%;
  }
  #asidetip {
    margin: 2vw 1vw 2vw 1vw;
    font-size: 3.5vw;
    line-height: 4.5vw;
    max-height: 24vw;
    overflow: hidden;  /* 隐藏超出容器尺寸的内容 */
    text-overflow: ellipsis;  /* 使用省略号...表示被截断的文本 */
    display: -webkit-box;
    -webkit-line-clamp: 3;  /* 指定最大显示行数为3行 */
    -webkit-box-orient: vertical;  /* 让文本在垂直方向上展示为多行 */
  }
}
</style>