<template>
    <div style="margin:15vh 20vw 20vh 20vw;">
       <el-card>
            <div slot="header" style="height:3.5vw">
                <h3>中国电影知识体系平台“影人年谱”编纂操作指南</h3>
            </div>        
            <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">1.中国电影知识体系平台（movie.yingshinet.com）由北京大学艺术学院与中国传媒大学文化大数据实验室合作开发，主要依托国家社科基金艺术学课题“影人年谱与中国电影史研究”以及国家社科基金艺术学重大课题“中国特色电影知识体系研究”，是在数字人文的基础上，在知识论与知识管理学的框架里搭建的一个众包群智、开源共享的，具有思辨性、成长性的中国电影数字基础设施，将以“影人年谱”“电影计量”“文献索引”与“知识生成”4个特色数据库为主并关联“相关平台”，力图与中国电影的史论研究、学术创新以及公益服务互动共生，为反思中国电影理论、推进中国电影学派并构建中国电影知识体系奠定必要的基础。</div>
            <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">2.“影人年谱”是“中国电影知识体系平台”最重要的特色数据库之一。“影人年谱”的编纂，不是简单的数据录入，而是深入的研究经历，以学术性、规范性和权威性为目标。亦即：编纂相应的“影人年谱”的过程，就是在做相关影人的学术研究，以及从相关影人年谱出发展开的“数字人文与电影研究”和“中国电影知识体系研究”。这是中国电影研究的新路径和新方法，也是中国电影学术的前沿地带。</div>
            <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">3.“影人年谱”涉及的每一条史料，都需在页下脚注当中注明出处，方便核查。</div>
            <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">4.通过数据库找寻谱主自撰文字时，还要以影人的笔名、原名等为关键词进行检索，确保史料之翔实；谱主发表文字的期刊，最好能通读一遍，可将与谱主文字形成“对话”的文章，收入年谱。</div>
            <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">5.需重视与谱主关系密切之人的日记、书信与其他文献，如蔡楚生日记和书信中，便有不少费穆等联华同人的史料。</div>
            <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">6.需重视“影人年谱”编纂的“电影”特性：尽量包括跟电影生产与消费相关的机械、机器、影院、场所等物质和技术层面信息，也包括组织、创作、发行、放映等相关层面内容。</div>
            <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">7.可以参照目前平台已有“费穆年谱”（部分年份）提供的模板。</div>
            <br/>
            <div style="font-size:1.4vw;line-height:2.5vw;float:right; margin-bottom:1.6vw">——李道新、谭文鑫</div>


              </el-card>
    </div>
</template>

<script>
export default {
}
</script>
<style>
</style>