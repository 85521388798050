<template>
  <!-- <el-col :span="12">
      <el-card style="width: 32vw; height: 28vh" class="articlecard">
        <div style="overflow-x: auto; white-space: nowrap">
          <div
            style="width: 13vw; display: inline-block; margin: 0 -5vw 0vw 0"
            v-for="(movie_pic, index) in movie_pics"
            :key="index"
          >
            <el-card :body-style="{ padding: '0px' }">
              <img
                class="mouse_over_work"
                :src="movie_pic.m_pic"
                style="width: 100%; height: 28vh; display: block"
                @click="movie_click(movie_pic.mid)"
              />
              <div style="padding: 10px">
                <span class="mouse_over" @click="movie_click(movie_pic.mid)">{{
                  movie_pic.m_name
                }}</span>
                <div></div>
              </div>
            </el-card>
            
          </div>
        </div>
      </el-card>
    </el-col> -->

  <!-- <el-col :span="12" align="center"> -->
  <el-card :class="!this.$store.state.isCollapse ? 'word-cloud':'word-cloud-mobile'">
    <svg id="wordcloud" width="100%" height="100%" ></svg>
  </el-card>
</template>

<script>
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as $ from "jQuery";
import store from "../../store/index";
import { async } from "q";

export default {
  components: {},
  name: "DirectorFooter",
  props: {
    movie_pics: Array,
    url: String,
    srcList: Array,
    dir_name: String,
  },
  data() {
    return {
      words: [],
      fontSizeMapper: (word) => Math.log2(word.value) * 5,
      path: "https://api.movie.yingshinet.com/chronicles",
      wordcloud: null,
      wordcount: 0,
      wordsdata: [],
      director: "",
    };
  },
  watch: {
    // words() {
    // this.initWordCloud();
    // console.log("words被调用", words);
    // },
    "$store.state.keywordSearch": {
      handler(val, oldVal) {
        this.searchEvents();
        // this.words = this.$store.state.keywordSearch;
      },
    },
    "$store.state.MonthSearch": {
      handler(val, oldVal) {
        this.searchEvents();
      },
    },
    "$store.state.YearSearch": {
      handler(val, oldVal) {
        this.YearSearch = val;
        this.searchEvents();
      },
    },
    "$store.state.current_figure": {
      handler(val, oldVal) {
        this.searchEvents();
      },
    },
  },
  methods: {
    // 给每个词加搜索功能
    initWordCloud() {
      // console.log("this.words", this.words);
      // 先根据this.words绘图
      this.drawWordCloud();
      // 绘图后根据添加的类名，给每个词添加搜索功能
      this.$nextTick(function () {
        document.querySelectorAll(".cloud-word").forEach((item) => {
          item.addEventListener("click", () => {
            // 词云图词汇内容
            let keyword = item.textContent;
            // 将点击的词作为搜索词上传vuex
            this.$store.commit("setkeywordSearch", keyword);
            // this.searchEvents();
            // vuex中keywordSearch变化，引动组件中监视函数然后执行searchEvents()
            // console.log(
            //   "词云图s",
            //   item.textContent,
            //   this.$store.state.keywordSearch
            // );
          });
        });
      });
    },
    //获得初始数据
    getEvents() {
      // console.log("searchItem");
      // const keyword = this.director_search.trim();
      const searchData = this.$store.state.keywordSearch.trim();
      const month = this.$store.state.MonthSearch.trim();
      const year = this.$store.state.YearSearch.trim();
      const keyJson = {
        // searchData: "山东",
        // year: 1,
        // figure: "费穆",
      };
      if (searchData) {
        keyJson.searchData = searchData;
      }
      if (month) {
        keyJson.month = month;
      }
      if (year) {
        keyJson.year = year;
      }
      keyJson.figure = this.director;
      // console.log("searchData", searchData);
      // console.log("keyJson", keyJson);
      this.axios.post(this.path + "/keyword", keyJson).then(async (response) => {
        // console.log("测试", response);
        // console.log("response.data", response.data);
        this.wordcloud = response.data.wordcut;
        this.wordcount = response.data.wordcount;
        // console.log(
        //   " this.wordcloud",
        //   this.wordcloud,
        //   "this.wordcount",
        //   this.wordcount
        // );
        this.words = [];
        for (let i = 0; i < this.wordcount; i++) {
          this.words[i] = {
            text: `${this.wordcloud[i][1]}`,
            size: `${this.wordcloud[i][0] * 1.2 + 5}`,
          };
        }
        //如果数据为空，就按相关数据显示
        if (!response.data.wordcount) {
          console.log("获取额外的词云图数据")
          const keyJson2 = { searchData: this.$store.state.current_figure };
          await this.axios.post(this.path + "/keyword", keyJson2).then((response) => {
            this.wordcloud = response.data.wordcut;
            this.wordcount = response.data.wordcount;
            this.words = [];
            for (let i = 0; i < this.wordcount; i++) {
              this.words[i] = {
                text: `${this.wordcloud[i][1]}`,
                size: `${this.wordcloud[i][0] * 1.2 + 5}`,
              };
            }
          });
        }
        this.initWordCloud();
      });
    },

    drawWordCloud() {
      // console.log("词云图开始", this.words);
      let w;
      let h;
      if(!store.state.isCollapse) {
        w = $(".word-cloud").width() * 0.95;
        h = $(".word-cloud").height() * 0.95;
      }
      else {
        w = $(".word-cloud-mobile").width() * 0.95;
        h = $(".word-cloud-mobile").height() * 0.95;
      }

      // console.log(w, h);
      let layout = cloud()
        .size([w, h])
        .words(this.words)
        .padding(5)
        .rotate(0)
        .font("Impact")
        .fontSize(function (d) {
          return d.size;
        })
        .on("end", draw);

      layout.start();

      function draw(words) {
        const color = d3.scaleOrdinal(d3.schemeCategory10);
        let svg = d3.select("#wordcloud");
        svg.selectAll("*").remove();
        svg.attr("width", layout.size()[0]).attr("height", layout.size()[1]);
        let g = svg
          .append("g")
          .attr(
            "transform",
            "translate(" +
            layout.size()[0] / 2 +
            "," +
            layout.size()[1] / 2 +
            ")"
          );
        let text = g
          .selectAll("text")
          .data(words)
          .enter()
          .append("a")
          .attr("class", "cloud-word")
          // .attr("xlint:href", function (d) {
          //   // return "/network?wanted={{jfile}}";
          //   return null;
          // })
          .append("text")
          .attr("fill", (_, i) => color(i))
          .attr("transform", function (d) {
            return (
              "translate(" +
              [Math.random() * w - w / 2, Math.random() * h - h / 2 - 20] +
              ")"
            );
          })
          .transition()

          .style("font-size", function (d) {
            return d.size + "px";
          })
          .style("font-family", "Impact")
          .attr("text-anchor", "middle")
          .text(function (d) {
            return d.text;
          })
          .attr("transform", function (d) {
            return (
              "translate(" +
              [d.x, d.y] +
              ")rotate(" +
              d.rotate +
              ")" +
              "scale(1.2)"
            );
          });
      }
    },
    movie_click(movie_id) {
      this.$router.push("/video?id=" + movie_id);
    },
    searchItem() {
      // console.log("searchItem");
      // const keyword = this.director_search.trim();
      const searchData = this.$store.state.keywordSearch.trim();
      const month = this.$store.state.MonthSearch.trim();
      const year = this.$store.state.YearSearch.trim();
      const keyJson = {
        // searchData: "山东",
        // month: 1,
      };
      if (searchData) {
        keyJson.searchData = searchData;
      }
      if (month) {
        keyJson.month = month;
      }
      if (year) {
        keyJson.year = year;
      }
      keyJson.figure = this.$store.state.current_figure;
      this.axios.post(this.path + "/keyword", keyJson).then(async(response) => {
        this.wordcloud = response.data.wordcut;
        this.wordcount = response.data.wordcount;
        this.words = [];
        for (let i = 0; i < this.wordcount; i++) {
          this.words[i] = {
            text: `${this.wordcloud[i][1]}`,
            size: `${this.wordcloud[i][0] * 1.5}`,
          };
        }
        //如果数据为空，就按相关数据显示
        if (!response.data.wordcount) {
          console.log("获取额外的词云图数据")
          const keyJson2 = { searchData: this.$store.state.current_figure };
          await this.axios.post(this.path + "/keyword", keyJson2).then((response) => {
            this.wordcloud = response.data.wordcut;
            this.wordcount = response.data.wordcount;
            this.words = [];
            for (let i = 0; i < this.wordcount; i++) {
              this.words[i] = {
                text: `${this.wordcloud[i][1]}`,
                size: `${this.wordcloud[i][0] * 1.2 + 5}`,
              };
            }
          });
        }
        this.initWordCloud();
      });
    },
    debounce(fn, wait = 1000) {
      let timer;
      // console.log("s");
      return function () {
        // console.log("a");
        let context = this;
        // let args = arguments;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          // fn.apply(context, args);
          fn.apply(context);
          // fn();
        }, wait);
      };
    },
    // 点击词云中词时更新词云
    searchEvents() {
      // this.debounce(this.searchItem, 1000);不完善的防抖
      //   let timeout;
      //   clearTimeout(timeout);
      //   timeout = setTimeout(() => {
      //     this.searchItem();
      //   }, 100);
      // },
      this.debounce(this.searchItem, 600)();
    },
  },
  mounted() {
    this.director = this.$store.state.current_figure;
    this.director_select = this.director;
    // 词云图起步获得初始数据
    this.getEvents();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.el-carousel__indicators li {
  border: 0.5vw;
}

.el-card__body {
  padding: 0.5vw;
}

.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.cloud-word:hover {
  cursor: pointer;
}

.footerCard .el-card__body {
  padding: 0.5vw;
}

.word-cloud {
  height: 30.3vh; 
  margin: 0.5vw; 
  width: 65vw;
}

.word-cloud-mobile {
  height: 30.3vh; 
  margin: 0.5vw; 
  width: 99vw;
}
</style>