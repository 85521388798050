<template>
 <el-card :body-style="{ padding: '10px' }">
   <div v-show="window_width<768" align='left' style="font-weight:bold;color:#e2a253;font-size:15px;margin:-1.5vw 0 2vw -0.5vw">影人年谱，上新了</div>
   <p v-show="window_width>768" style="font-size:15px">上新了</p>
    <div style="height:2px; background-color:gainsboro; margin-top:2px;">
    </div>
    <!-- 纵向年谱列表 -->
    <div class="chronicleList" >
      <!-- 年谱列表搜索框 -->
      <!-- 时间轴列表 -->
      <el-timeline class="timeline infinite-list">
        <!-- 时间列表的每一项 -->
        <el-timeline-item v-for="(eventItem, index) in replaceArr" :key="eventItem.cid">
          <el-card
          @click.native="chronicleTofigure(eventItem.c_figure)">
            <div>
              <!-- 内容 -->
              <span class="itemdata" v-html="methodGetByteLen(eventItem.content,70)"></span>
              <span class="itemfont">--创建人：{{ allData[index].c_create_man
              }}--创建日期：{{allData[index].c_create_time}}</span>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    </el-card>
</template>

<script>
export default {
  name: "chronicle_list",
  data () {
    return {
      // 编辑模版
      //遮罩层是否显示
      dialogEditVisible: false,
      make_sure: false,
      colors: ["#b8860b", "#778899"],
      directors: [
        //40位影人名单
      ],
      dirctors_pic:[
        //40位影人照片
      ],
      allData: [], //所有年谱数据，数组['','','',...]
      keywordSearch: "",
      MonthSearch: "",
      YearSearch: "",
      path: "https://api.movie.yingshinet.com/chronicles",
      currentFigureImage:"",
      dialog: false,
      loading: false,
      edit_event_item: "",
      eventItemContent: "",
      tmpEventItemContent: "",
      movie: [],
      othermovie: [],
      otheritemLength:0,
      itemLength:0,
      formLabelWidth: "50px",
      date_value: new Date(1937, 0, 1),
      date_value_two: "",
      event_content: "",
      event_source: "",
      chronicle_id: "",
      date_value_three: "",
      director_select: "",
      director: "",
      c_year: "",
      c_month: "",
      c_date: "",
      str: "",
      new_source: "",
      window_width:window.screen.width,
    };
  },
  computed: {
    replaceArr() {
      const arr = JSON.parse(JSON.stringify(this.allData));
      const colors = this.colors;
      const keywords = this.directors;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a  href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (
            arr[index].content &&
            arr[index].content.length
          ) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      return arr;
    },
  },
  methods: {
    //按年份和月份时间顺序排序
    sortYear(obj) {
      return obj.sort(function (a, b) {
        let x = a["c_create_time"]
        let y = b["c_create_time"]
        if(x<y) return 1
        else return -1
      })
    },
    getEvents () {
      const key = {
        'request_num':11
      }
      this.axios.post(this.path + "/new",key).then((response) => {
        this.movies = response.data.c_data;
        //年谱数据movies.c_content
        this.itemLength = this.movies.length;
        this.allData = [];
        let j=this.itemLength-1
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[j].c_content;
          this.allData[i].c_year = this.movies[j].c_year;
          this.allData[i].c_month = this.movies[j].c_month;
          this.allData[i].c_id = this.movies[j].cid;
          this.allData[i].c_date = this.movies[j].c_date;
          this.allData[i].c_create_man = this.movies[j].c_create_man;
          this.allData[i].c_source = this.movies[j].c_source;
          this.allData[i].c_create_time = this.movies[j].c_create_time;
          this.allData[i].c_figure=this.movies[j].c_figure;
          j--;
        }
        this.allData = this.sortYear(this.allData)
      });
      this.$set(this.allData);
    },
    //截取部分字符中文前十个
    methodGetByteLen(str,len) {
        if (this.str <= len) {
          return str
        }
        for (let i = Math.floor(len / 2); i < str.length; i++) {
          if (str.substr(0, i).replace(/[^\x00-\xff]/g, '01').length >= len) {
            return str.substr(0, Math.floor(i / 2) * 2) + '...'
          }
        }
      },
      chronicleTofigure(keyword)
      {
      this.$router.push("/directors?=" + keyword);
      this.$store.commit("set_current_figure", keyword);
      }
  },
  created () {
    this.getEvents();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.chronicle_e_input {
  border-radius: 0.3vw;
  border: 0.07vw solid #b8860b;
  color: #606266;
  height: 5vw;
  line-height: 5vw;
  padding-left: 0.3vw;
  outline: none;
}
.chronicleList .listSearch {
  position: static;
  width: 94.5%;
  margin: 1vw 0 0.8vw 0;
  text-align: center;
  line-height: 1vw;
}
.listSearch .el-card__body {
  padding: 0.2vw;
}
.el-timeline {
  width: 100%;
  padding-inline-start: 0vw;
}
.chronicleList .timeline {
  height: 44vh;
  overflow: scroll;
}
.el-timeline .el-timeline-item {
  padding-bottom: 0vw;
}
.infinite-list .yearformcard {
  height: 8vw;
  overflow: scroll;
}
.yearformcard .el-card__body {
  padding: 0.8vw;
}
.yearformcard p {
  margin: 0vw;
}
.mouse_over_head:hover {
  color: #b8860b;
  cursor: pointer;
}
.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}
/* 创建人颜色 */
.itemfont {
  color: #a1a3a8;
}
/* 搜索框 */
.keywordSearchInput {
  width: 60%;
  margin-left: 1vw;
}
.keywordSearchInput #search {
  padding: 12px 20px;
  background-color: #ffc78e;
  border-radius: 0%;
}
.keywordSearchInput #search-box {
  width: 13.9%;
  /* height: 10%; */
  margin-top: 0px;
  margin-left: 65.4%;
  padding-bottom: 20px;
}
.director_select {
  display: inline-block;
  width: 6vw;
  height:6vh;
  margin-left:2vw;
}
</style>
<style>
.chronicleList {
  position: relative;
  margin-left: 0vw;
}
.chronicleList .el-card__body {
  padding: 0.5vw;
}
.chronicleList .el-timeline-item__tail,
.el-timeline-item__node {
  margin-left: 0.5vw;
}
.chronicleList .el-timeline-item__wrapper {
  padding-left: 1.3vw;
}
.chronicleList a {
  text-decoration: none;
}
.director_img{
  width:3vw !important;
  height: 6vh !important;
  left:1vw!important;
  top:0.8vh;
  position:absolute;
  border-radius:50%;
}
</style>