<template>
  <div>
    <el-table
      :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%"
      v-if="!isvis"
    >
      <el-table-column
        label="用户名"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="数据贡献榜单"
        prop="create"
        align="center"
        sortable
      ></el-table-column>
      <el-table-column
        label="更新数据数"
        prop="update"
        align="center"
        sortable
      ></el-table-column>
    </el-table>

    <statistics-barchart v-if="isvis"></statistics-barchart>

    <span class="elebutton">
      <el-button
        style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa"
        class="el-backtop"
        icon="el-icon-refresh"
        @click="isvis= !isvis"
      ></el-button>
    </span>

  </div>


</template>

<script>
// 引入组件
import StatisticsBarchart from "../components/statistics/statistics_barchart.vue";
export default {
  components: {
    StatisticsBarchart,
  },
  data () {
    return {
      work: [],
      tableData: [
        {
          name: '',
          create: '',
          update: '',
        },
      ],
      num: 0,
      search: '',
      isvis: true
    }
  },
  methods: {
    getInfo () {
      const path = "https://api.movie.yingshinet.com/countactions";
      this.axios
        .get(path)
        .then(res => {
          this.work = res.data.data;
          this.num = res.data.count;
          this.getData();
        })
        .catch(error => {
          console.error(error);
        });
    },
    getData () {
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {}
        this.tableData[i].create = this.work[i].create_num;
        this.tableData[i].name = this.work[i].name;
        this.tableData[i].update = this.work[i].update_num;
        // console.log(this.tableData)
        this.$set(this.tableData)
      }
    },
    handleEdit (index, row) {
      // console.log(index, row);
    },
    deleteRow (index, row) {
      row.splice(index, 1)
    },
  },
  created () {
    // console.log("yes")
    this.getInfo();

  },
}
</script>
<style scoped>
.elebutton .el-backtop {
  right: 36px;
  top: 115px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}
</style>