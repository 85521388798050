<template>
  <div>
       <el-button
              style="margin-left: 10px;margin-top: 8px;width:80px"
              type="warning"
              plain
              @click="changeview()"
              icon="el-icon-refresh-left"
              round
              ></el-button>
    <Hist_year v-if="isshowhist==true" height="40%"></Hist_year>
    <cloud_writer v-if="isshowhist==false" height="40%"></cloud_writer>
    <el-table :data="this.isSearch ? searchData: tableData" :key="sort_button" style="width: 90%; margin: 20px auto"
      :row-style="{ height: '80px' }">
      <!-- <el-table-column align="center" min-width="10%">
        <template slot="header">
          <el-button @click="sort_rise" v-if="sort_button">正序</el-button>
          <el-button @click="sort_drop" v-else>倒序</el-button>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="编号" prop="random" min-width="15%"></el-table-column>
      <el-table-column align="center" label="著作" prop="book_name" min-width="20%"></el-table-column>
      <el-table-column align="center" label="作者" prop="writer" min-width="15%"></el-table-column>
      <el-table-column align="center" label="出版社" prop="publisher" min-width="15%"></el-table-column>
      <el-table-column align="center" label="发表时间" prop="year" min-width="12%"></el-table-column>
      <el-table-column align="center" label="封面" min-width="20%">
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <el-image class="image_style" v-if="scope.row.pic != null" :src="scope.row.pic"></el-image>
          <div v-if="scope.row.pic == null">暂无封面</div>
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="15%">
        <template slot="header" slot-scope="scope">
      <el-select
        v-model="select"
        slot="prepend"
        size="mini"
        placeholder="请选择"
        class="select_style"
        @change="search_item_change"
      >
        <el-option label="著作" value="1"></el-option>
        <el-option label="作者" value="2"></el-option>
        <el-option label="出版社" value="3"></el-option>
        <el-option label="发表时间" value="4"></el-option>
      </el-select>
          <el-input v-model="search_content" @keyup.enter.native="searchHandler" size="mini" class="input_style" placeholder="输入内容" />
        </template>
        <template slot-scope="scope">
          <el-button size="mini" v-if="$store.state.login_state" type="warning"
            @click="handleEdit(scope.$index, scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="text-align:center; margin:5vh 0" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pagesize"
      layout="total, prev, pager, next" :total="tableData_length"></el-pagination>

    <!-- 添加按钮 -->
    <span class="elebutton">
      <el-button style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa" class="el-backtop" icon="el-icon-plus"
        @click="dialog = true" circle v-if="this.$store.state.login_state"></el-button>
    </span>
    <!-- 新添加著作页面 -->
    <el-dialog class="el-dia" title="请添加著作信息：" :visible.sync="dialog" width="100%" height="100%" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure_add" width="100%" append-to-body>
        <span>确定要添加信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload class="upload-demo" ref="upload" list-type="picture" action="alert" :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
        <el-button slot="trigger" size="medium" type="warning">点击上传著作封面图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left: 3px">支持上传jpg/jpeg/png文件，且不超过500kb</div>
      </el-upload>
      <el-form>
        <el-form-item label="著作" width="100px" style="margin-top: 15px">
          <el-input v-model="book_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="作者" width="100px" style="margin-top: 15px">
          <el-input v-model="writer" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出版社" width="100px" style="margin-top: 15px">
          <el-input v-model="publisher" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发表时间" width="100px" style="margin-top: 15px">
          <el-input v-model="year" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="著作类型" width="100px" style="margin-top: 15px">
          <select v-model="book_type" autocomplete="off" name="">
            <option value="book">专著</option>
            <option value="journal">期刊</option>
          </select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="warning" @click="add_director()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改著作页面 -->
    <el-dialog class="el-dia" title="著作编辑" :visible.sync="dialogEditVisible" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload class="upload-demo" ref="upload" list-type="picture" action :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
        <el-button slot="trigger" size="medium" type="warning">点击上传著作封面图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left: 3px">支持上传jpg/jpeg/png文件，且不超过500kb</div>
      </el-upload>
      <el-form>
        <el-form-item label="著作" width="100px" style="margin-top: 15px">
          <el-input v-model="now_book_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="作者" width="100px" style="margin-top: 15px">
          <el-input v-model="now_writer" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出版社" width="100px" style="margin-top: 15px">
          <el-input v-model="now_publisher" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发表时间" width="100px" style="margin-top: 15px">
          <el-input v-model="now_pub_year" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="著作类型" width="100px" style="margin-top: 15px">
          <select v-model="now_book_type" autocomplete="off" name="">
            <option value="book">专著</option>
            <option value="journal">期刊</option>
          </select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="warning" @click="edit_director()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "../utils/request";
import Hist_year from '../components/books/hist_year.vue';
import cloud_writer from '../components/books/cloud_writer.vue';

export default {
  components:{
    Hist_year,
    cloud_writer,
  },
  data() {
    return {
      path: "https://api.movie.yingshinet.com/books/pic/",
      work: [],
      tableData: [],
      //随机数的数组
      randomNum: [],
      scope: [],
      num: 0,
      search_content: "",
      dialog: false,
      dialogEditVisible: false,
      //新添加著作参数
      limit_num: 1,
      book_name: "",
      writer: "",
      publisher: "",
      year: "",
      book_type: "",
      image_file: [],
      new_pic_id: "",
      make_sure_add: false,
      //修改著作参数
      pic_id: "",
      id: "",
      now_book_name: "",
      now_book_type: "",
      now_writer: "",
      now_publisher: "",
      now_pub_year: "",
      make_sure: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length:null,
      //first search
      searchData:[],
      first_search: false,
      sort_button: false,
      isSearch: false,
      all_count: 0,
      list:[],
      search_option:2,
      isshowhist:true,
      isshowcloud:true,
    };
  },
  watch: {
    search_content: {
      handler: function () {
        if (this.search_content == "") {
          this.isSearch = false
          this.tableData_length = this.all_count;
        }
      }
    }
  },
  methods: {
    // 搜索功能
    searchHandler() {
      this.isSearch = true
      const path = "https://api.movie.yingshinet.com/books/select";
      this.content = {
        'book_name':this.search_option==1?this.search_content:null,
        "writer":this.search_option==2?this.search_content:null,
        "publisher":this.search_option==3?this.search_content:null,
        "pub_year":this.search_option==4?this.search_content:null,
      }
      this.axios
        .post(path, this.content)
        .then((res) => {
          this.searchData = [];
          for (var i = 0; i < res.data.count; i++) {
            this.searchData[i] = {};
            this.searchData[i].no = res.data.data[i].id;
            this.searchData[i].book_name = res.data.data[i].book_name;
            this.searchData[i].writer = res.data.data[i].writer;
            this.searchData[i].book_type = res.data.data[i].book_type;
            this.searchData[i].pub_city = res.data.data[i].pub_city;
            this.searchData[i].year = res.data.data[i].pub_year;
            this.searchData[i].translator = res.data.data[i].translator;
            this.searchData[i].publisher = res.data.data[i].publisher;
            this.searchData[i].director = res.data.data[i].director;
            this.searchData[i].random = i+1+(this.currentPage - 1) * this.pagesize;
            this.searchData[i].publisher = res.data.data[i].publisher;
            if (res.data.data[i].book_pic != null) {
              //console.log(res.data.data[i].image)
              this.searchData[i].book_pic = res.data.data[i].book_pic
            }
            else {
              this.searchData[i].book_pic = null
            }
          }
          
          this.list = this.searchData
          this.searchData = this.list.slice(
            (this.currentPage - 1) * this.pagesize,
            this.currentPage * this.pagesize
          );
          // 获取查询的结果，把数组长度赋值给分页组件中的total
          this.tableData_length = this.list.length;
          this.$set(this.searchData);
       
    
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sort_rise() {
      this.tableData.reverse();
      this.sort_button = false
    },
    sort_drop() {
      this.tableData.reverse();
      this.sort_button = true
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    // handleCurrentChange(currentPage) {
    //   this.currentPage = currentPage;
    // },
      // select option回调方法
    search_item_change(value) {
      switch (value) {
        case "1":
          this.search_option = 1;
          break;
        case "2":
          this.search_option = 2;
          break;
        case "3":
          this.search_option = 3;
          break;
        case "4":
          this.search_option = 4;
          break;
        default:
      }
    },
     GetName () {
      var url = window.location.href; //获取url中"?"符后的字串
      var param = [];
      if (url.split("?")[1] != null) {
        param = url.split("?")[1].split("=");
      }
      return decodeURI(param[1]);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.isSearch) {
        // && this.tableData.length!=0
        //如果是搜索功能的话，是一次性返回全部数据，需要每次换页的时候分页显示
        this.searchData = this.list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        //this.tableData_length = list.length;
        this.$set(this.searchData);
      }
      else {
        const path = "https://api.movie.yingshinet.com/books";
        this.axios
          .post(path, { divide: 1, page: currentPage, count: 10 })
          .then((res) => {
            this.work = res.data.all_books;
            this.num = res.data.cur_subtitle_count;
            this.tableData_length = res.data.all_subtitle_count;
            this.all_count = res.data.all_subtitle_count;
            this.tableData.length = this.tableData_length;
            this.tableData = []
            this.getData();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    // 显示确认添加弹窗
    add_director() {
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      //信息上传
      service({
        url: "/books/insert",
        method: "post",
        data: {
          book_name: this.book_name,
          writer: this.writer,
          pub_year: this.year,
          publisher: this.publisher,
          book_type: this.book_type,
        },
      }).then((response) => {
        if (response.data.msg == "insert success") {
          this.new_pic_id = response.data.id;
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          // 图片上传
          if (this.image_file.length == 1) {
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/books/pic/" + this.new_pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.data.msg == "success") {
                  this.$message({
                    message: "著作封面图片上传成功！",
                    type: "success",
                  });
                  location.reload();
                } else {
                  this.$message({
                    message: "著作封面图片好像出了点问题",
                    type: "warning",
                  });
                }
              });
          }
        } else {
          this.$message({
            message: "添加有点问题！",
            type: "warning",
          });
        }
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    changeview()
    {
      this.isshowhist=!this.isshowhist;
    },
    // getInfo() {
    //   const path = "https://api.movie.yingshinet.com/books";
    //   this.axios
    //     .get(path)
    //     .then((res) => {
    //       this.work = res.data.data;
    //       this.num = res.data.count;
    //       //console.log(this.num)
    //       this.getData();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    getData() {
      //打乱顺序
      // for(var i=0;i<this.num;i++){
      //   this.randomNum[i]=i+1
      // }
      //console.log(this.randomNum)
      // let n = this.num;
      // while (n) {
      //   let j = Math.floor(Math.random() * n--);
      //   [this.randomNum[j], this.randomNum[n]] = [this.randomNum[n], this.randomNum[j]];
      // }
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.work[i].bid;
        this.tableData[i].book_name = this.work[i].book_name;
        this.tableData[i].writer = this.work[i].writer;
        this.tableData[i].publisher = this.work[i].publisher;
        this.tableData[i].year = this.work[i].pub_year;
        this.tableData[i].translator = this.work[i].translator;
        this.tableData[i].pic = this.work[i].book_pic;
        // this.tableData_length = this.tableData.length;
        this.tableData[i].random = i+1+(this.currentPage - 1) * this.pagesize;
        this.tableData[i].book_type = this.work[i].book_type;
      }
      //把table按随机数的顺序排序
      // this.tableData.sort(this.compare("random"))
      this.$set(this.tableData);
         console.log("hello");
      this.$nextTick(function () {
        document.querySelectorAll(".rect1").forEach((item) => {
          item.addEventListener("click", () => {
            console.log("item");
           console.log(item);
          });
        });
      });
    },
    compare(attr) {
      return function (a, b) {
        var val1 = a[attr];
        var val2 = b[attr];
        return val1 - val2;
      }
    },
    handleEdit(index, row) {
      console.log(row);
      console.log(index);
      this.pic_id = row.no;
      this.id = row.no;
      console.log(this.id);
      this.now_book_name = row.book_name;
      this.now_writer = row.writer;
      this.now_pub_year = row.year;
      this.now_publisher = row.publisher;
      this.dialogEditVisible = true;
      this.now_book_type = row.book_type;
    },
    //著作编辑
    edit_director() {
      this.make_sure = true;
    },
    make_sure_click() {
      this.make_sure = false;
      this.dialogEditVisible = false;
      //信息上传
      service({
        url: "/books/update",
        method: "post",
        data: {
          id: this.id,
          now_book_name: this.now_book_name,
          now_writer: this.now_writer,
          now_pub_year: this.now_pub_year,
          now_publisher: this.now_publisher,
          now_book_type: this.now_book_type,
        },
      }).then((response) => {
        if (response.status == "200") {
          (this.book_name = this.now_book_name),
            (this.book_type = this.now_book_type),
            (this.writer = this.now_writer),
            (this.pub_year = this.now_pub_year),
            (this.publisher = this.now_publisher);
            console.log("success");
          this.$message({
            message: "基本信息修改成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: "基本信息修改好像出问题了！",
            type: "warning",
          });
        }
        // 图片上传
        if (this.image_file.length == 1) {
          let file = this.image_file[0].raw;
          let pic_param = new FormData(); //form对象
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          pic_param.append("file", file);
          this.axios
            .post(
              "https://api.movie.yingshinet.com/books/pic/" + this.pic_id,
              pic_param,
              config
            )
            .then((response) => {
              if (response.status == "200") {
                this.$message({
                  message: "著作封面图片修改成功！",
                  type: "success",
                });
                location.reload();
              } else {
                this.$message({
                  message: "著作封面图片好像出了点问题",
                  type: "warning",
                });
              }
            });
        }
      });
    },
  },
watch: {
    $route(to, from) {
      // if (this.$route.query.id) {
        this.$router.go(0)
        console.info( "加载页面数据" );
      // }
    }
},
  created() {
      if(this.GetName()!="undefined")
   {
     if(this.GetName().slice(0,4)=="year")
    { this.search_option=4;
     this.search_content=this.GetName().substring(4);
     console.log(this.search_content);
     
    //  this.select="国别"
     this.searchHandler();
     console.log(this.search_content);
    }
     else if(this.GetName().slice(0,6)=="author")
     {
       this.search_content=this.GetName().substring(6);
       this.search_option="2";
       this.isshowhist=false;
    //    this.select="译名"
       this.searchHandler();      
     }
     else
     {
       console.log(error);
     }
   }
    // this.getInfo();
    this.handleCurrentChange(1);
    
  },
};
</script>
<style scoped>
::v-deep .el-button:focus,
.el-button:hover {
  color: #e2a253 !important;
  border-color: #e2a253 !important;
  background-color: #fff6ec !important;
}

.image_style {
  width: 40px;
  height: 55px;
  border-radius: 3px;
  object-fit: fill;
  vertical-align: middle;
}

.image_style:hover {
  z-index: 10;
  transform: scale(3, 3);
  transition: 0.5s transform;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}

.select_style {
  width: 3vw;
  border-radius: 1vw 0 0 0vw;
}

.input_style {
  width: 7vw;
  border-radius: 1vw 0 0 0vw;
}
</style>