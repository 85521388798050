import axios from 'axios'
import Cookies from 'js-cookie'


/****** 创建axios实例 ******/
// 本地切换1/2
const service = axios.create({
  //baseURL: 'http://127.0.0.1:5004',  
  baseURL: 'https://api.movie.yingshinet.com',  // api的base_url
  timeout: 5000  // 请求超时时间
})

service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = Cookies.get('Authorization')
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

export default service;