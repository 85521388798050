<template>
 <el-card :body-style="{ padding: '10px' }">
  <el-row :gutter="20">
              <el-col
                :xs="12"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8"
                v-for="(data, i) in keyTags"
                :key="i"
                class="mouse_hover"
              >
                <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }"
                @click.native="toTagContent(data)">
                    <div class="time">{{ data }}</div>
                </el-card>
              </el-col>
         
            </el-row>
    </el-card>
</template>

<script>
import service from "../../utils/request";
export default {
  name: "chronicle_list",
  data () {
    return {
      // 编辑模版
      //遮罩层是否显示
      path_currency: "https://api.movie.yingshinet.com",
      window_width:window.screen.width,
      keyTags:["国别电影史","电影杂志","香港电影导演","日本电影导演","电影图书","电影技术","电影工业","中国电影理论家","电影奖"],
    };
  },
  computed: {
  
  },
  methods: {
   
toTagContent(tagName)
{
  if(this.window_width<768){
    this.$router.push("/post?name=" + tagName);
        this.$message({
          showClose:true,
          message:'更多信息请见电脑端页面',
          type:'warning'
        });
  }
  this.$router.push("/post?name=" + tagName);
} 
  },
  created () {
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
.card{
    height:40px;
    margin-bottom: 16px;
    background-color: gainsboro;
    cursor:pointer;
}
.time{
    font-family:'Times New Roman', Times, serif;
    font-size:15px;
    margin-top: 10px!important;
    margin-left:3px;
}
</style>