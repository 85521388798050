import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui'
import '../node_modules/element-ui/lib/theme-chalk/index.css'
import VideoPlayer from '../node_modules/vue-video-player/dist/vue-video-player.js';
import '../node_modules/video.js/dist/video-js.css';
import store from './store'

// 引入百度地图（不用再到html中导入）
import VueBMap, {initBMapApiLoader} from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css'
Vue.use(VueBMap)
initBMapApiLoader({
  ak: 'D3yXDfIAn0WvyVUfEsdzGK1Ao1CgeCiG',
})

Vue.config.productionTip = false

Vue.prototype.axios = axios

Vue.use(ElementUI, axios)
Vue.use(VideoPlayer)
Vue.use(Vuex)

new Vue({
  store,
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
}).$mount('#app')