<template>
  <div style="padding: 5px">
    <div align="center" style="margin-top: 10px">
      <div style="width: fit-content">
        <span
          style="
            font-size: 60px;
            color: #6c6d70;
            font-family: STsong;
            font-weight: bold;
          "
          align="center"
          >{{ this.title_name }}</span
        >
        <div style="height: 6px; background-color: #e2a253"></div>
        <div
          style="height: 2px; background-color: #e2a253; margin-top: 2px"
        ></div>
        <div
          style="
            font-size: 22px;
            color: #6c6d70;
            font-family: STsong;
            font-weight: bold;
          "
        >
        </div>
      </div>
    </div>

    <el-row :gutter="8" style="margin-top: 20px">
      <el-col :span="18">
        <!-- 标签详情页子组件 -->
        <post-info
          v-bind:entity_direction="content"
          v-bind:current_id="current_id"
        ></post-info>
      </el-col>
      <!-- 右布局 -->
      <el-col :span="6">
        <div>
          <!-- 影人列表 -->
          <el-card
            style="
              box-shadow: 3px 4px 1px -2px rgb(0 0 0 /16%),
                0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
              height: 77vh;
              background-color: #b8870b10;
            "
          >
            <div class="el_card" slot="header" style="height: 60px">
              <el-input
                class="e-input"
                placeholder="搜索标签"
                v-model="post_search"
                style="width: 80%; margin: 10px 0px"
              ></el-input>
            </div>
            <div style="overflow-y: auto">
              <el-row>
                <el-col
                  :span="7"
                  v-for="(title, index) in search_data"
                  :key="index"
                  class="text_item"
                >
                  <span class="mouse_over" @click="titleClick(title)">{{
                    title
                  }}</span>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { findIndex } from "@better-scroll/shared-utils";
import PostInfo from "../components/director/post_info.vue";
import service from "../utils/request";
export default {
  components: {
    PostInfo,
  },
  data() {
    return {
      //这个标签下的全部posts
      term_posts: [],
      //第一个content
      content: "",
      director_sorted: [],
      //所有的titles
      all_posts_titles: [],
      //所有的contents
      all_posts_contents: [],
      //所有的ID
      all_IDs: [],
      //当前的ID
      current_id: "",
      director_sex: "",
      post_name: "",
      spouse: "",
      post_search: "",
      direction: "",
      movie_pics: [],
      allLabels: [],
      title_name:"",
      all_post_titles:[],
      dir_id: 0,
      srcList: [],
      path: "https://api.movie.yingshinet.com",
      dialog: false,
      make_sure_add: false,
      color: "#b8860b",
    };
  },
  computed: {
    // 搜索
    search_data: {
      get() {
        if (!this.post_search.trim()) {
          return this.allLabels;
        }
        return this.allLabels.filter(
          (data) =>
            data &&
            data.toLowerCase().includes(this.post_search.trim().toLowerCase())
        );
      },
    },
  },
  watch: {
    $route(to, from) {
      console.log("路由变化");
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.axios
          .post("https://api.movie.yingshinet.com/termsearch", {
            term_name: to.query.name,
          })
          .then((response) => {
          });
      }
    },
  },
  methods: {


    // title点击
    titleClick(title) {
      this.$router.push('/post?name=' + title)
    },

  },
  created() {
    this.axios
      .post("https://api.movie.yingshinet.com/posttitle", {
        title_name: this.$route.query.name,
      })
      .then((response) => {
        this.all_post_titles = response.data.searched_titles;
        this.content = this.all_post_titles[0].contents;
        this.title_name = this.all_post_titles[0].titles;
        for(let i=0;i<response.data.labels.length;i++)
        {
            this.allLabels[i]=response.data.labels[i]['labels'];
        } 
        var j = 0; 
        for (var i = 0; i < this.term_posts.length; i++) {
          // 判断：如果有重复的title，就取最长的content
          const existindex = this.all_posts_titles.indexOf(
            this.term_posts[i].titles
          );
          if (existindex != -1) {
            //存在重复的title，那么判断content
            const existlen = this.all_posts_contents[existindex].length;
            const newlen = this.term_posts[i].contents.length;
            if (existlen < newlen) {
              this.all_posts_contents[existindex] = this.term_posts[i].contents;
              this.all_IDs[existindex] = this.term_posts[i].ID;
            }
          } else {
            this.all_posts_titles[j] = this.term_posts[i].titles;
            this.all_posts_contents[j] = this.term_posts[i].contents;
            this.all_IDs[j] = this.term_posts[i].ID;
            j = j + 1;
          }
        }
        this.current_id = this.all_IDs[0];
      });
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>

<style>
.text_item {
  font-size: 15px;
  margin-bottom: 12px;
  margin-right: 12px;
}

.mouse_over:hover {
  color: #b8860b;
  cursor: pointer;
}

.el-card .el-card__header {
  padding: 8px 20px;
}

.e-input {
  text-align: left;
  border-radius: 5px;
  color: #606266;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  padding-left: 8px;
  outline: none;
}

.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}

.back_image {
  background-image: url("/static/back1.jpg");
  background-size: cover;
}
</style>
