
<template class="Chronicle" style="height:100%;">
  <!-- 影人年谱页面 -->
  <div style="min-height:100%;">
    <!-- el-col将页面分为24列 -->
    <div v-if="!this.$store.state.isCollapse">
      <el-col :span="16" >
        <chronicle-barchart></chronicle-barchart>
        <!-- 影人信息轮播图组件 -->
        <!-- <chronicle-event
          :srcList="srcList"
          :director="director"
        ></chronicle-event> -->
        <!-- 影人信息作品和词云图组件 -->
        <!-- 数据可视化demo -->

        <chronicle-footer :movie_pics="movie_pics"></chronicle-footer>

        <!-- 影人年谱列表组件 -->
      </el-col>

      <!-- 右布局 -->
      <el-col :span="8">
        <!-- 未来引入影人选择参数 -->
        <chronicle-list
          v-if="isRouterAlive"
          :director="director"
        ></chronicle-list>
      </el-col>
    </div>
    <div v-if="this.$store.state.isCollapse">
      <el-col >
        <chronicle-barchart></chronicle-barchart>
        <!-- 影人信息轮播图组件 -->
        <!-- <chronicle-event
          :srcList="srcList"
          :director="director"
        ></chronicle-event> -->
        <!-- 影人信息作品和词云图组件 -->
        <!-- 数据可视化demo -->

        <chronicle-footer :movie_pics="movie_pics"></chronicle-footer>

        <!-- 影人年谱列表组件 -->
        <!-- 未来引入影人选择参数 -->
        <chronicle-list
          v-if="isRouterAlive"
          :director="director"
        ></chronicle-list>
      </el-col>
    </div>
  </div>
</template>

<script>
// 引入组件
import ChronicleBarchart from "../components/chronicle/chronicle_barchart.vue";
// import ChronicleEvent from "../components/chronicle/chronicle_event.vue";
import ChronicleFooter from "../components/chronicle/chronicle_footer.vue";
import ChronicleList from "../components/chronicle/chronicle_list.vue";
export default {
  components: {
    ChronicleBarchart,
    // ChronicleEvent,
    ChronicleFooter,
    ChronicleList,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      // fm年谱api根路径
      path: "https://api.movie.yingshinet.com",
      // director_search: "",搜索功能demo
      //暂时使用的轮播图左侧图片
      srcList: [
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.kan0512.com%2Fmaterial%2Fnews%2Fimg%2F2018%2F10%2F7ba9c16a06327e3c0e419387346e92e5.jpg&refer=http%3A%2F%2Fimg.kan0512.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634994129&t=8d18e0c8f512f82432a01475965760da",
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.ddwenzhai.com%2Fddpic%2Fslzk%2Fslzk201843%2Fslzk20184331-4-l.jpg&refer=http%3A%2F%2Fwww.ddwenzhai.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1634994177&t=2c1b1032d9aa6c5e2220eec785b416e3",
      ],
      // 作品图片列表
      movie_pics: [],
      //默认选择的影人为费穆
      director: "费穆",
    };
  },
  computed: {},
  methods: {
    reload() {
      this.isRouterAlive = false;

      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    //点击所选影人，获取该影人的作品接口

    directorClick(director) {
      //逻辑操作
      this.movie_pics.length = 0;
      //获取所选影人的作品信息
      this.axios
        .post(this.path + "/movies/select", { director: director })
        .then((response) => {
          for (var i = 0; i < response.data.m_data.length; i++) {
            //获取作品的图片列表并推给本地movie_pics
            this.movie_pics.push(response.data.m_data[i]);
          }
        });
    },
  },
  mounted() {
    // 挂载后先调用一次加载作品
    this.directorClick(this.director);
  },
};
</script>

<style scoped>
/* ::-webkit-scrollbar {
  display: none;
} */
#app .el-col-16 {
  width: 66.6%;
}
</style>