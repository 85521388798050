<template>
  <div :class="this.$store.state.isCollapse ? 'container_collapse' : 'container'">
    <div class="top-section">
      <el-select class="select_box" v-model="select"  @change="serach_item_change"
        filterable placeholder="国别/地区|译名|导演|ASL|别名|年份|片名|国别/中">
        <el-option label="片名" value="片名"></el-option>
        <el-option label="译名" value="译名"></el-option>
        <el-option label="导演" value="导演"></el-option>
        <el-option label="ASL" value="ASL"></el-option>
        <el-option label="别名" value="别名"></el-option>
        <el-option label="年份" value="年份"></el-option>
        <el-option label="国别/地区" value="国别/地区"></el-option>
        <el-option label="国别" value="国别"></el-option>
      </el-select>
      <el-input class="select_box_input" v-model="search_content" 
        @keyup.enter.native="searchHandler" placeholder="请输入内容">
        <!-- 搜索框按钮 -->
        <el-button slot="append" icon="el-icon-search" id="search" @click="searchHandler">
        </el-button>
      </el-input>
      <div v-show="window_width > 768" style="margin-left: 55vw; margin-top: -2vw; margin-bottom: 2vw; font-size: 1.25vw;">
        <span class="top-section-text">数据来自：</span>
        <a style="text-decoration: none; color: inherit" target="_blank" href="https://cinemetrics.uchicago.edu/">Cinemetrics</a>
        <span> & </span>
        <a style="text-decoration: none; color: inherit" target="_blank" href="http://www.starword.com/">Starword</a>
      </div>
      <!-- <div v-show="window_width <= 768" style="margin-left: 20vw; font-size: 4vw;">
        <span class="top-section-text">数据来自：</span>
        <a style="text-decoration: none; color: inherit" target="_blank" href="https://cinemetrics.uchicago.edu/">Cinemetrics</a>
        <span> & </span>
        <a style="text-decoration: none; color: inherit" target="_blank" href="http://www.starword.com/">Starword</a>
      </div> -->
    </div>  
  
    <div class="table-wrapper">
      <el-table
        :data="this.isSearch ? searchData: tableData"
        :key="sort_button"
        style="width: 90vw; margin: 0px auto"
        :row-style="{ height: '80px' }"
        v-scroll-lock
        height="60vh"
      >
    
      <!-- <el-table-column align="center" >
        <template slot="header">
          <el-button @click="sort_rise" v-if="sort_button">正序</el-button>
          <el-button @click="sort_drop" v-else>倒序</el-button>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="编号" prop="no" fixed="left" type="index"></el-table-column>
      <el-table-column
        align="center"
        label="片名"
        prop="name"
        fixed="left"
      ></el-table-column>
      <el-table-column
        align="center"
        label="别名"
        prop="other_name"
        
      ></el-table-column>
      <el-table-column
        align="center"
        label="译名"
        prop="trans_name"
        
      ></el-table-column>
      <el-table-column
        align="center"
        label="年份"
        prop="year"
        
      ></el-table-column>
      <el-table-column
        align="center"
        label="导演"
        prop="director"
        
      ></el-table-column>
      <el-table-column align="center" label="平均镜头长度(ASL)" prop="asl" ></el-table-column>
      <el-table-column
        
        align="center"
        label="国别/地区"
        prop="countryCH"
      ></el-table-column>
      <el-table-column
        
        align="center"
        label="国别"
        prop="country"
      ></el-table-column>
      <el-table-column
        align="center"
        label="范围"
        prop="scale"
        
      ></el-table-column>
      <el-table-column
        align="center"
        label="摄影"
        prop="photographer"
        
      ></el-table-column>
      <el-table-column
        align="center"
        label="剪辑"
        prop="editor"
        
      ></el-table-column>
      <el-table-column 
        align="center"
        label="Studio"
        prop="studio"
      ></el-table-column>
      <el-table-column align="center" label="海报" >
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic != null"
            style="height: 75px; border-radius: 8px; width: 40px"
            :src="scope.row.pic"
          ></el-image>
          <div v-if="scope.row.pic == null">暂无海报</div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <!-- <template slot="header" slot-scope="scope">
          <el-input
            v-model="movie_search"
            size="mini"
            placeholder="搜索影片名"
          />
        </template> -->
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-if="$store.state.login_state"
            type="warning"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>

    <el-pagination
      style="text-align: center; margin: 5vh 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pagesize"
      layout="total, prev, pager, next"
      :total="tableData_length"
    ></el-pagination>

    <!-- 添加按钮 -->
    <span class="elebutton">
      <el-button
        style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa"
        class="el-backtop"
        icon="el-icon-plus"
        @click="dialog = true"
        circle
        v-if="this.$store.state.login_state"
      ></el-button>
    </span>
    <!-- 新添加影片页面 -->
    <el-dialog
      class="el-dia"
      title="请添加影片信息："
      :visible.sync="dialog"
      width="100%"
      height="100%"
      fullscreen
    >
      <el-dialog
        title="提示"
        :visible.sync="make_sure_add"
        width="100%"
        append-to-body
      >
        <span>确定要添加影片？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick"
            >确 定</el-button
          >
        </span>
      </el-dialog>
       <el-row>
        <el-col :span="8"  width="50px" style="margin-top: 30px">
          <el-upload
            class="upload-demo"
            ref="upload"
            list-type="picture"
            action
            :limit="limit_num"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :auto-upload="false"
            style="margin-left: 100px"
          >
          <el-button slot="trigger" size="medium" type="warning"
            >点击上传电影海报</el-button
          >
          <div slot="tip" class="el-upload__tip" >
            支持上传jpg/jpeg/png文件，且不超过500kb
          </div>
          </el-upload>
        </el-col>
        <el-col :span="8"  width="50px" style="margin-top: 30px">
          <div>
            <el-button type="warning" @click="add_film()"  style="margin-left: 100px">确 定</el-button>
            <el-button @click="dialog = false">取 消</el-button>
          </div>
        </el-col>
      </el-row>
      <el-form label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="片名" width="50px" style="margin-top: 30px">
              <el-input v-model="film_name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="别名" width="50px" style="margin-top: 30px">
              <el-input v-model="other_film" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="译名" width="100px" style="margin-top: 30px">
              <el-input v-model="trans_name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年份" width="50px" style="margin-top: 30px">
              <el-input v-model="film_year" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="导演" width="50px" style="margin-top: 30px">
              <el-input v-model="film_director" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ASL" width="100px" style="margin-top: 30px">
              <el-input v-model="film_asl" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="国别" width="50px" style="margin-top: 30px">
              <el-input v-model="film_country" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="国别/地区" width="50px" style="margin-top: 30px">
              <el-input v-model="film_countryCH" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="范围" width="50px" style="margin-top: 30px">
              <el-input v-model="film_scale" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="摄影" width="100px" style="margin-top: 30px">
              <el-input v-model="film_photographer" autocomplete="off"></el-input>
            </el-form-item>
          </el-col> 
          
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="剪辑" width="50px" style="margin-top: 30px">
              <el-input v-model="film_editor" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Studio" width="50px" style="margin-top: 30px">
              <el-input v-model="film_studio" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 修改著作页面 -->
    <el-dialog
      class="el-dia"
      title="影片编辑"
      :visible.sync="dialogEditVisible"
      fullscreen
    >
      <el-dialog
        title="提示"
        :visible.sync="make_sure"
        width="30%"
        append-to-body
      >
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
        <el-row>
        <el-col :span="8"  width="50px" style="margin-top: 30px">
          <el-upload
            class="upload-demo"
            ref="upload"
            list-type="picture"
            action
            :limit="limit_num"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :auto-upload="false"
            style="margin-left: 100px"
          >
          <el-button slot="trigger" size="medium" type="warning"
            >点击上传电影海报</el-button
          >
          <div slot="tip" class="el-upload__tip" >
            支持上传jpg/jpeg/png文件，且不超过500kb
          </div>
          </el-upload>
        </el-col>
        <el-col :span="8"  width="50px" style="margin-top: 30px">
          <div>
            <el-button type="warning" @click="edit_director()"  style="margin-left: 100px">确 定</el-button>
            <el-button @click="dialogEditVisible = false">取 消</el-button>
          </div>
        </el-col>
      </el-row>

      <el-form label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="片名" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_name" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="别名" width="50px" style="margin-top: 30px">
              <el-input v-model="now_other_name" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="译名" width="100px" style="margin-top: 30px">
              <el-input v-model="now_trans_name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年份" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_year" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="导演" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_director" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ASL" width="100px" style="margin-top: 30px">
              <el-input v-model="now_film_asl" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="国别" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_country" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="国别/地区" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_countryCH" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="范围" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_scale" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="摄影" width="100px" style="margin-top: 30px">
              <el-input v-model="now_film_photographer" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col> 
          
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="剪辑" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_editor" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Studio" width="50px" style="margin-top: 30px">
              <el-input v-model="now_film_studio" autocomplete="off" :readonly="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
     
    </el-dialog>
  </div>
</template>

<script>
import service from "../utils/request";
export default {
  data() {
    return {
      window_width: window.screen.width,
      work: [],
      tableData: [],
      scope: [],
      num: 0,
      book_search: "",
      dialog: false,
      dialogEditVisible: false,
      //新添加影片参数
      limit_num: 1,
      film_name: "",
      other_film: "",
      trans_name: "",
      film_year: "",
      film_director: "",
      film_country:"",
      film_countryCH:"",
      film_asl:"",
      film_scale:"",
      film_photographer:"",
      film_editor:"",
      film_studio:"",
      image_file: [],
      new_pic_id: "",
      make_sure_add: false,
      //修改影片参数
      pic_id: "",
      id: "",
      now_film_name: "",
      now_film_director: "", 
      now_other_name: "",
      now_film_year: "",
      now_trans_name: "",
      now_film_country:"",
      now_film_countryCH:"",
      now_film_asl:"",
      now_film_scale:"",
      now_film_photographer:"",
      now_film_editor:"",
      now_film_studio:"",
      make_sure: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length: null,
      searchItem:[],
      //first search
      first_search: false,
      sort_button: false,
      // 搜索框
      search_content: "",
      search_option: "",
      searchData:[],
      select: "国别/地区",
      content:{},
      isSearch : false,
      all_count:0,
      list:[]
    };
  },
  watch: {
    search_content: {
      handler: function(){
        if(this.search_content == ""){
        this.isSearch=false
        this.tableData_length = this.all_count;
      }
      
      }
    },
    
  },
  methods: {
    // 搜索功能
    searchHandler() {
      this.isSearch = true
      const path = "https://api.movie.yingshinet.com/select_cinemetrics";
      if(this.search_option=="title"){
        this.content = {
          'title':this.search_content
        }
      }
      else if(this.search_option=="director"){
        this.content = {
          'director':this.search_content
        }
      }
      else if(this.search_option=="year"){
        this.content = {
          'year':this.search_content
        }
      }
      else if(this.search_option=="country_chi"){
        this.content = {
          'country_chi':this.search_content
        }
      }
      else if(this.search_option=="country"){
        this.content = {
          'country':this.search_content
        }
      }
      else if(this.search_option=="translate_name"){
        this.content = {
          'translate_name':this.search_content
        }
      }
      else if(this.search_option=="asl"){
        this.content = {
          'asl':this.search_content
        }
      }
      else{
        this.content = {
          'alternative_title':this.search_content
        }
      }
      
      this.axios
          .post(path, this.content)
          .then((res) => {
            this.searchData= [];
              for (var i = 0; i < res.data.count; i++) {
                  this.searchData[i] = {};
                  this.searchData[i].no = res.data.selected_cinemetrics[i].id;
                  this.searchData[i].name = res.data.selected_cinemetrics[i].title;
                  this.searchData[i].other_name = res.data.selected_cinemetrics[i].alternative_title;
                  this.searchData[i].trans_name = res.data.selected_cinemetrics[i].translate_name;
                  this.searchData[i].scale = res.data.selected_cinemetrics[i].scope;
                  this.searchData[i].countryCH= res.data.selected_cinemetrics[i].country_chi;
                  this.searchData[i].country= res.data.selected_cinemetrics[i].country;
                  this.searchData[i].photographer= res.data.selected_cinemetrics[i].cinematographer;
                  this.searchData[i].director = res.data.selected_cinemetrics[i].director;
                  this.searchData[i].publisher = res.data.selected_cinemetrics[i].publisher;
                  this.searchData[i].year = res.data.selected_cinemetrics[i].year;
                  this.searchData[i].asl = res.data.selected_cinemetrics[i].asl;
                  if(res.data.selected_cinemetrics[i].image !=null){
                    //console.log(res.data.selected_cinemetrics[i].image)
                    this.searchData[i].pic = "https://assets.yingshinet.com/chronicles/cinemetrics/"+res.data.selected_cinemetrics[i].image;
                  }
                  else{
                    this.searchData[i].pic =null
                  }
                  this.searchData[i].editor = res.data.selected_cinemetrics[i].editor;
                  this.searchData[i].studio = res.data.selected_cinemetrics[i].studio;
              }
              this.list = this.searchData
              this.searchData = this.list.slice(
                (this.currentPage - 1) * this.pagesize,
                this.currentPage * this.pagesize
              );
              // 获取查询的结果，把数组长度赋值给分页组件中的total
              this.tableData_length = this.list.length;
              this.$set(this.searchData);
          })
          .catch((error) => {
              console.error(error);
          });
        },
    sort_rise() {
      this.tableData.reverse();
      this.sort_button = false;
    },
    sort_drop() {
      this.tableData.reverse();
      this.sort_button = true;
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
      //console.log(this.pagesize)
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if(this.isSearch){
        // && this.tableData.length!=0
        //如果是搜索功能的话，是一次性返回全部数据，需要每次换页的时候分页显示
        this.searchData = this.list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        //this.tableData_length = list.length;
        this.$set(this.searchData);
      }
      else{
        const path = "https://api.movie.yingshinet.com/all_cinemetrics";
      this.axios
        .post(path,{divide:1,page:currentPage,count:10})
        .then((res) => {
          //console.log(res)
          this.work = res.data.all_cinemetrics;
          this.num = res.data.cur_subtitle_count;
          this.tableData_length = res.data.all_subtitle_count;
          this.all_count = res.data.all_subtitle_count;
          this.tableData.length = this.tableData_length;
          this.tableData = []
          this.getData();
        })
        .catch((error) => {
          console.error(error);
        });
      }
    },
    // 显示确认添加弹窗
    add_film() {
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      //信息上传
      service({
        url: "https://api.movie.yingshinet.com/insert_cinemetrics",
        method: "post",
        data: {
          title: this.film_name,
          alternative_title: this.other_film,
          translate_name: this.trans_name,
          year: this.film_year,
          director: this.film_director,
          country: this.film_country,
          country_chi: this.film_countryCH,
          asl: this.film_asl,
          scope: this.film_scale,
          cinematographer: this.film_photographer,
          editor: this.film_editor,
          studio: this.film_studio,
        },
      }).then((response) => {
        if (response.status == "200") {
          console.log(response)
          this.new_pic_id = response.data.id;
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          // 图片上传
          if (this.image_file.length == 1) {
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/cinemetrics/pic/" + this.new_pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.status == "200") {
                  this.$message({
                    message: "影片海报图片上传成功！",
                    type: "success",
                  });
                  location.reload();
                } else {
                  this.$message({
                    message: "影片海报图片好像出了点问题",
                    type: "warning",
                  });
                }
              });
          }
        } else {
          console.log(response)
          this.$message({
            message: "添加有点问题！",
            type: "warning",
          });
        }
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    getData() {
      for (let i = 0 ; i < this.num ; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.work[i].id;
        this.tableData[i].name = this.work[i].title;
        this.tableData[i].other_name = this.work[i].alternative_title;
        this.tableData[i].trans_name = this.work[i].translate_name;
        this.tableData[i].scale = this.work[i].scope;
        this.tableData[i].countryCH= this.work[i].country_chi;
        this.tableData[i].country= this.work[i].country;
        this.tableData[i].photographer= this.work[i].cinematographer;
        this.tableData[i].director = this.work[i].director;
        this.tableData[i].publisher = this.work[i].publisher;
        this.tableData[i].year = this.work[i].year;
        this.tableData[i].asl = this.work[i].asl;
        this.tableData[i].pic = this.work[i].image;
        this.tableData[i].editor = this.work[i].editor;
        this.tableData[i].studio = this.work[i].studio;
      }
        this.$set(this.tableData);
    },
    handleEdit(index, row) {
      this.pic_id = row.no;
      this.id = row.no;
      this.now_film_name= row.name;
      this.now_film_director = row.director
      this.now_other_name = row.other_name
      this.now_film_year = row.year
      this.now_trans_name = row.trans_name
      this.now_film_country =row.country
      this.now_film_countryCH =row.countryCH
      this.now_film_asl =row.asl
      this.now_film_scale =row.scale
      this.now_film_photographer =row.photographer
      this.now_film_editor =row.editor
      this.now_film_studio =row.studio
      this.dialogEditVisible = true;
    },
    //影片编辑
    edit_director() {
      this.make_sure = true;
    },
    make_sure_click() {
      this.make_sure = false;
      this.dialogEditVisible = false;
      //信息上传
      service({
        url: "https://api.movie.yingshinet.com/update_cinemetrics",
        method: "post",
        data: {
          cinemetrics_id: this.id,
          translate_name: this.now_trans_name,
          country_chi: this.now_film_countryCH
        },
      }).then((response) => {
        if (response.status == "200") {
          (this.trans_name = this.now_trans_name),
            (this.countryCH = this.now_film_countryCH),
          this.$message({
            message: "基本信息修改成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: "基本信息修改好像出问题了！",
            type: "warning",
          });
        }
        // 图片上传
        if (this.image_file.length == 1) {
          //console.log("修改电影海报的pic_id: "+this.pic_id)
          let file = this.image_file[0].raw;
          let pic_param = new FormData(); //form对象
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          pic_param.append("file", file);
          this.axios
            .post(
              "https://api.movie.yingshinet.com/cinemetrics/pic/" + this.pic_id,
              pic_param,
              config
            )
            .then((response) => {
              if (response.status == "200") {
                this.$message({
                  message: "影片海报图片修改成功！",
                  type: "success",
                });
                location.reload();
              } else {
                this.$message({
                  message: "影片海报图片好像出了点问题",
                  type: "warning",
                });
              }
            });
        }
      });
    },
    serach_item_change (value) {
      this.search_content = ""
      this.search_option=""
      switch (value) {
        case "片名":
          this.search_option = "title";
          break;
        case "译名":
          this.search_option = "translate_name";
          break;
        case "导演":
          this.search_option = "director";
          break;
        case "ASL":
          this.search_option = "asl";
          break;
        case "别名":
          this.search_option = "alternative_title";
          break;
        case "年份":
          this.search_option = "year";
          break;
        case "国别/地区":
          this.search_option = "country_chi";
          break;
        case "国别":
          this.search_option = "country";
          break;
        default:
      }
    },

      GetName () {
      var url = window.location.href; //获取url中"?"符后的字串
      var param = [];
      if (url.split("?")[1] != null) {
        param = url.split("?")[1].split("=");
      }
      return decodeURI(param[1]);
    },
  },
  created() {
  if(this.GetName()!="undefined")
   {
     if(this.GetName().slice(0,7)=="country")
    { this.search_option="country";
     this.search_content=this.GetName().substring(7);
     this.select="国别"
     this.searchHandler();
     console.log(this.search_content);
    }
     else if(this.GetName().slice(0,4)=="name")
     {
       this.search_content=this.GetName().substring(4);
       this.search_option="translate_name";
       this.select="译名"
       this.searchHandler();      
     }
     else
     {
       console.log(error);
     }
   }
    this.handleCurrentChange(1);
  },
};

</script>
<style scoped>

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 86vh;
  margin-top: 0;
}
.container_collapse > .top-section {
  height: 10vh;
}
.container_collapse > .top-section > .select_box{
  margin-left: 3vw;
  width: 35vw;
}
.container_collapse > .top-section > .select_box_input{
  width: 50vw;
  margin-left: 5vw;
}
.container_collapse > .top-section-text{

  margin-left: 5vw;
}


.select_box {
  margin-top:20px;
  margin-left: 5vw;
  width: 9vw;
} 
.select_box_input{
  width: 20vw;
  margin-left: 1vw;
}
::v-deep .el-button:focus,
.el-button:hover {
  color: #e2a253 !important;
  border-color: #e2a253 !important;
  background-color: #fff6ec !important;
}
.table-wrapper {
  overflow-x: auto; /* 添加横向滚动 */
  max-width: 100vw; /* 最大宽度不超过屏幕宽度 */
}
</style>
