<template >
  <div>
    <div style="margin-top: 30px;"><span style="margin-left:100px; font-size: 16px;">数据来自:</span><a style="font-size:13px"
        target="_blank" href="https://cinemetrics.uchicago.edu/">Cinemetrics</a><span style="font-size:13px"> & </span><a
        style="font-size:13px" target="_blank" href="http://www.starword.com/">Starword</a>
    </div>
    <div class="hist-graph">
      <svg id="histGraph" height="100%" />
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { copyFileSync } from "fs";
import * as $ from "jQuery";

//import service from "../utils/request";
export default {
  data() {
    return {
      //path: "https://api.movie.yingshinet.com/books/pic/",
      work: [],
      tableData: [],
      //新添加影片参数
      tableData_length: "",
    };
  },


  methods: {

    getInfo() {
      const path = "https://api.movie.yingshinet.com/cinemetrics_bycountry";
      this.axios
        .post(path, { divide: 1, page: 1, count: 20 })
        .then((res) => {
          this.tableData_length = res.data.count;
          this.work = res.data.response_data;
          this.getData();
          this.initHist();
        })
        .catch((error) => {
          //console.error(error);
        });
    },

    getData() {
      for (var i = 0; i < this.tableData_length; i++) {
        this.tableData[i] = {};
        this.tableData[i].id = i;
        this.tableData[i].country_name = this.work[i][1];
        this.tableData[i].trans_name = this.work[i][0];
        this.tableData[i].asl = this.work[i][2];
        this.tableData[i].film_count = this.work[i][3];
        this.$set(this.tableData);
      }
    },

    initHist() {
      this.drawHist();
      this.$nextTick(function () {
        document.querySelectorAll(".rect1").forEach((item) => {
          item.addEventListener("click", () => {
            let keyword = item.id
            this.$router.push("/LengthTable?id=" + "country" + keyword);
          });
        });
      });
    },

    drawHist() {
      let bodywidth = document.body.clientWidth;
      let width = bodywidth;
      let leftw = bodywidth * 0.02;
      let rightw = bodywidth * 0.88;
      let bandwidth = (rightw - leftw) / (this.tableData_length);

      let height = $(".hist-graph").height();
      let svg = d3.select("#histGraph")
        .attr("width", width)
        .attr("height", height);
      // 每次调用前删除之前部分
      svg.selectAll("*").remove();
      let xScale = d3.scaleLinear().range([leftw, rightw]).domain([0, this.tableData_length])
      // x轴                                 
      let xAxis = svg.append('g')
        .attr('class', 'xAxis')
        .attr('transform', `translate(0, ${height - 100})`)
        .call(d3.axisBottom(xScale).ticks(0).tickFormat(x => `${x.toFixed(0)}`))

      let yScale = d3.scaleLinear()
        .domain([0, 33])
        .range([0, height * 0.75]);

      let yAxis = svg.append('g')
        .attr('class', 'yAxis')
        .attr('transform', `translate(0, ${height - 60})`)
      // .call(d3.axisBottom(yScale).ticks(20).tickFormat(x => `${x.toFixed(0)}`))
      const color = d3.scaleOrdinal(d3.schemeCategory10);



      let rect = svg.append('g')
        .attr("class", "rect")
        .selectAll(".rect")
        .data(this.tableData)
        .enter()
        .append("rect")
        .attr("class", "rect1")
        .attr("id", function (d, i) {
          return d.country_name;
        })
        .attr("x", function (d, i) {
          return xScale(i) + bandwidth / 20;

        })
        .attr("y", function (d, i) {
          return height - yScale(parseFloat(d.asl)) - 100;
        })
        .attr("width", bandwidth * 0.9)
        .attr("height", function (d, i) {
          return yScale(parseFloat(d.asl));
        })
        .attr("fill", function (d, i) { return color(i); })
        .on("mouseover", function (d, i) {
          d3.select(this)
            .attr("fill", "yellow");
        })
        .on("mouseout", function (d, i) {
          d3.select(this)
            .transition()
            .duration(0)
            .attr("fill", color(i.id));
        })
      let text = svg.append('g')
        .selectAll(".text")
        .data(this.tableData)
        .enter()
        .append("text")
        .attr("fill", function (d, i) {
          return color(i);
        })
        .attr("font-size", "14px")
        .attr("text-anchor", "middle")
        .attr("width", 10)
        .attr("x", function (d, i) {
          return xScale(i) + leftw - bandwidth / 2 + 30;
        })
        .attr("y", function (d) {
          return height - yScale(parseFloat(d.asl)) - 120;
        })
        .attr("dx", 10)
        .attr("dy", 10)
        .text(function (d, i) {
          return d.trans_name + ":" + parseFloat(d.asl).toFixed(2);
        })


    }
  },
  mounted() {
    this.getInfo()
  },
};
</script>

<style>
.el-slider__bar {
  background-color: #e2a253 !important;
}

.el-slider__button {
  border: 2px solid #e2a253 !important;
}
</style>

<style scoped>
.hist-graph {
  height: 100vh;
  width: 90vw;
  margin: 0 auto;
  margin-top: 0vh;
}
</style>
