<template>
  <div> 

    <!-- 登出弹窗 -->
    <el-dialog title="提示" :visible.sync="logout_visible" width="30%">
      <span>确定要登出吗...</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logout_visible = false">取 消</el-button>
        <el-button type="warning" @click="logout_click">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码弹窗 -->
    <el-dialog
      title="密码修改"
      :visible.sync="change_password_visible"
      width="40%"
    >
      <el-form
        ref="change_password_form"
        :model="change_passwrd"
        :rules="rules"
        label-width="6vw"
      >
        <el-form-item label="原始密码" prop="old_password">
          <el-input
            type="password"
            placeholder="请输入原密码"
            v-model="change_passwrd.old_password"
          />
        </el-form-item>
        <el-form-item label="新的密码" prop="new_password">
          <el-input
            type="password"
            placeholder="请输入新密码"
            v-model="change_passwrd.new_password"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="new_password_again">
          <el-input
            type="password"
            placeholder="请再次入新密码"
            v-model="change_passwrd.new_password_again"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="change_password_visible = false">取 消</el-button>
        <el-button type="warning" @click="onSubmit('change_password_form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 密码修改确认弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="change_password_makesure"
      width="30%"
    >
      <span>确定要修改吗...</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="change_password_makesure = false">取 消</el-button>
        <el-button type="warning" @click="change_password_click"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 修改个人信息弹窗 -->
    <el-dialog
      title="个人信息修改"
      :visible.sync="change_information_visible"
      width="40%"
    >
      <el-form
        ref="change_information_form"
        :model="change_infor"
        label-width="6vw"
      >
        <el-form-item label="用户名">{{
          this.$store.state.user_name
        }}</el-form-item>
        <el-form-item label="真实姓名" prop="true_name">
          <el-input
            placeholder="请输入真实姓名"
            v-model="change_infor.true_name"
          />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input placeholder="请输入邮箱地址" v-model="change_infor.email" />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="change_infor.sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
            <el-radio label="其他"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="电话号码" prop="phone">
          <el-input placeholder="请输入电话号码" v-model="change_infor.phone" />
        </el-form-item>
        <el-form-item label="身份证号" prop="idcard">
          <el-input
            placeholder="请输入身份证号"
            v-model="change_infor.idcard"
          />
        </el-form-item>
        <el-form-item label="证书号" prop="idcertificates">
          <el-input
            placeholder="请输入证书号"
            v-model="change_infor.idcertificates"
          />
        </el-form-item>
        <el-form-item
          v-if="this.$store.state.is_summerschoolstudent"
          label="证书照片"
          prop="photo"
        >
          <el-upload
            :limit="1"
            :auto-upload="false"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :file-list="fileList"
            action="#"
            class="avatar-uploader"
            list-type="picture-card"
            accept="image/jpg,image/jpeg,image/png"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="el-upload__tip">jpg、jpeg、png格式，大小2M以内</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="change_information_visible = false">取 消</el-button>
        <el-button
          type="warning"
          @click="onSubmitInfor('change_information_form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 个人信息修改确认弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="change_information_makesure"
      width="30%"
    >
      <span>确定要修改吗...</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="change_information_makesure = false"
          >取 消</el-button
        >
        <el-button type="warning" @click="change_information_click"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <pic-home v-if="this.$store.state.isvis == 'home'"></pic-home>
    <pic-homeold v-if="this.$store.state.isvis == 'homeold'"></pic-homeold>
    <!-- 照片墙 -->
    <pic-border
      v-if="this.$store.state.isvis == 'border'"
      :figures="figures"
      :figure_names="figure_names"
      :figures_serach="figures_serach"
      :rec_figure="rec_figure"
    ></pic-border>

    <!-- 人物词云图 -->
    <pic-cloud
      v-if="this.$store.state.isvis == 'cloud'"
      :figures="figures"
      :figure_names="figure_names"
      :figures_serach="figures_serach"
    ></pic-cloud>

    <pic-force v-if="this.$store.state.isvis == 'force'"></pic-force>
    <pic-line v-if="this.$store.state.isvis == 'line'"></pic-line>
    <pic-hist v-if="this.$store.state.isvis == 'hist'"></pic-hist>
    <pic-lollipop v-if="this.$store.state.isvis == 'lollipop'"></pic-lollipop>
    <VideoGraph v-if="this.$store.state.isvis == 'video'"></VideoGraph>
    <!-- 网站数据 -->

    <!-- 上布局 -->
    <!-- <div
      v-if="this.$store.state.isvis == 'homepage'"
      class="homepage"
      style="background-color: aliceblue"
    >
      <el-row style="margin-top: 20px">
        <el-col :span="16" style="display: inline-block">
          <force
            style="display: inline-block; height: 54vh; width: 99.3%"
          ></force>
        </el-col>
        <el-col :span="8" style="display: inline-block">
          <el-row
            ><chronicleRecent
              style="height: 32vh; display: inline-block; width: 100%"
            ></chronicleRecent
          ></el-row>
          <el-row
            ><tagshow
              style="
                height: calc(22vh - 6px);
                display: inline-block;
                width: 100%;
              "
            ></tagshow
          ></el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" style="display: inline-block">
          <cloud
            style="height: 31vh; display: inline-block; width: 99.3%"
          ></cloud>
        </el-col>
        <el-col :span="8" style="display: inline-block">
          <movieRecent
            style="height: 31vh; display: inline-block; width: 100%"
          ></movieRecent>
        </el-col>
      </el-row>
    </div> -->

    <div style="margin: 0vh 10vw 0 10vw; width: 80vw; position: relative">
      <div class="title" align="center">
        <h2></h2>
      </div>
      <el-divider></el-divider>
      <el-row>
        <el-col
          class="message_count"
          :span="4"
          style="align-content: center; width: 13% !important; margin-left: 3vw"
        >
          <div class="icon_style">
            <i class="el-icon-user"></i>
          </div>
          <span class="data_font_size">影人 {{ figure_count }} 位</span>
        </el-col>
        <el-col class="message_count" :span="4" style="width: 13% !important">
          <div class="icon_style">
            <i class="el-icon-pie-chart"></i>
          </div>
          <span class="data_font_size">年谱 {{ chronicle_count }} 条</span>
        </el-col>

        <el-col :span="4" class="message_count" style="width: 13% !important">
          <div class="icon_style">
            <i class="el-icon-film"></i>
          </div>
          <span class="data_font_size">影片 {{ movie_count }} 条</span>
        </el-col>

        <el-col :span="4" class="message_count" style="width: 13% !important">
          <div class="icon_style">
            <i class="el-icon-notebook-1"></i>
          </div>
          <span class="data_font_size">文章 {{ essay_count }} 条</span>
        </el-col>

        <el-col :span="4" class="message_count" style="width: 13% !important">
          <div class="icon_style">
            <i class="el-icon-notebook-2"></i>
          </div>
          <span class="data_font_size">著作 {{ article_count }} 条</span>
        </el-col>
        <el-col :span="4" class="message_count" style="width: 13% !important">
          <div class="icon_style">
            <i class="el-icon-chat-dot-square"></i>
          </div>
          <span class="data_font_size">百科 {{ encyclopedia_count }} 条</span>
        </el-col>
        <el-col :span="4" class="message_count" style="width: 13% !important">
          <div class="icon_style">
            <i class="el-icon-chat-dot-square"></i>
          </div>
          <span class="data_font_size">用户 {{ user_count }} 个</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import service from "../utils/request";
import PicHome from "../components/home/pic_home.vue";
import PicHomeold from "../components/home/pic_homeold.vue";
import PicBorder from "../components/home/pic_border.vue";
import PicCloud from "../components/home/pic_cloud.vue";
import PicForce from "../components/home/pic_force.vue";
import PicLine from "../components/home/pic_line.vue";
import PicHist from "../components/home/pic_hist.vue";
import PicLollipop from "../components/home/pic_lollipop.vue";
import VideoGraph from "./Video_index_graph.vue";

import cloud from "../components/homepage/cloud.vue";
import force from "../components/homepage/force.vue";
import movieRecent from "../components/homepage/movieRecent.vue";
import chronicleRecent from "../components/homepage/chronicleRecent.vue";
import tagshow from "../components/homepage/tagshow.vue";
import chronicle_list from "../components/chronicle/chronicle_list.vue";

export default {
  components: {
    PicHome,
    PicHomeold,
    PicBorder,
    PicCloud,
    PicForce,
    PicLine,
    VideoGraph,
    PicLollipop,
    PicHist,
    cloud,
    force,
    tagshow,
    chronicleRecent,
    movieRecent,
    chronicle_list,
  },
  data() {
    // 登出表单验证
    var validate_oldpass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入旧密码"));
      } else {
        callback();
      }
    };
    var validate_newpass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    };
    var validate_newpass_again = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.change_passwrd.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      menu_click: false,
      WorldASLdata: "世界电影ASL",
      filmanalysis: "电影拉片",
      company: "制片公司",
      essay_title: "文献索引",
      essay_peo: "影人文论",
      conments: "评论",
      introduction: "项目简介",
      knowledgeGene: "知识生成",
      editGuidance: "编纂指南",
      about: "加入我们",
      programDialog: "项目日志",
      force: "社交网络",
      ASLdata: "华语ASL",
      picFigure: "影人星丛",
      picFilm: "影人作品",
      figurearticles: "论  文",
      literature: "早期报刊",
      references: "相关成果",
      essay_website: "相关平台",
      essay_chi: "著  作",
      periodical: "期  刊",
      chronicle: "影人年谱",
      ChronicleAnalysis: "可视分析",
      encyclopedia: "电影百科",
      chronicleList: "年谱列表",
      registerAuthorize: "注册验证",
      people: "影人年谱",
      people_china: "中国影人",
      people_world: "世界影人",
      movie_classic: "经典电影",
      dialog_content: "项目日志",
      video_title: "电影计量",
      video_brief: "影片题录",
      video: "影像视频",
      filmanalysis: "电影拉片",
      company: "制片公司",
      essay: "影人文论",
      color_write: "#ffffff",
      conments: "评论",
      connectus: "数据",
      content: "研究专著",
      occupation: "职业导航",
      Montage: "标签词云",
      HKmovie: "香港电影",
      postSearch: "标签搜索",
      titleSearch: "标题搜索",
      Francedirector: "法国电影",
      AnnualEvent: "电影年表",
      // 路径
      path: "https://api.movie.yingshinet.com",
      // 数据与统计
      fig_response: "",
      figures: [],
      figure_names: [],
      figures_serach: [],
      figure_count: 0,
      chronicles: [],
      chronicle_count: 0,
      movies: [],
      movie_count: 0,
      essays: [],
      essay_count: 0,
      articles: [],
      literature_count: 0, //文献计数
      article_count: 0, //著作计数
      encyclopedia_count: 0,
      user_count: 0,
      // 搜索框
      select: "1",
      serach_option: [],
      search_content: "",
      // 登出与修改密码
      logout_visible: false,
      change_password_visible: false,
      change_password_makesure: false,
      change_passwrd: {
        old_password: "",
        new_password: "",
        new_password_again: "",
      },
      // 修改密码表单规则
      rules: {
        old_password: [
          { required: true, validator: validate_oldpass, trigger: "change" },
        ],
        new_password: [
          { required: true, validator: validate_newpass, trigger: "change" },
        ],
        new_password_again: [
          {
            required: true,
            validator: validate_newpass_again,
            trigger: "change",
          },
        ],
      },
      //修改个人信息
      change_information_visible: false,
      change_information_makesure: false,
      change_infor: {
        true_name: "",
        email: "",
        sex: "",
        phone: "",
        idcard: "",
        idcertificates: "",
      },
      user_id: "",
      viswhat: "",
      rec_figure: {},
      //上传照片
      url: "",
      onChangeImgUrl: "",
      image_file: [],
      fileList: [],
      isrightshape: false,
    };
  },
  //解决el-upload的picture-card样式问题
  watch: {
    onChangeImgUrl: {
      handler(newName) {
        var aa = document.querySelector(".el-upload--picture-card");
        if (newName) {
          aa.style.display = "none";
        } else {
          setTimeout(() => {
            aa.style.display = "inline-block";
          }, 1000);
        }
      },
    },
  },
  methods: {
    // 词云图切换
    handlecloud(isvis, name) {
      this.viswhat = name;
      this.$store.commit("handlevis", isvis);
      if (name == "首页") this.$router.push("/");
    },
    go_director() {
      this.$router.push("/directors?=" + this.rec_figure.f_name);
      this.$store.commit("set_current_figure", this.rec_figure.f_name);
    },
    // 照片点击
    figure_click(figure_name) {
      this.$router.push("/directors?=" + figure_name);
      this.$store.commit("set_current_figure", figure_name);
    },
    // select option回调方法
    serach_item_change(value) {
      this.search_content = "";
      switch (value) {
        // 影人搜索
        case "1":
          this.serach_option = this.figure_names;
          break;
        case "2":
          // 影片搜索
          this.serach_option = this.movies;
          break;
        case "3":
          // 文章搜索
          this.serach_option = this.essays;
          break;
        case "4":
          // 文章搜索
          this.serach_option = this.articles;
          break;
        default:
      }
    },
    // 搜索点击
    search_click() {
      switch (this.select) {
        case "1":
          // 影人搜索
          if (this.search_content.trim()) {
            this.figures_serach = [];
            for (var i = 0; i < this.figures.length; i++) {
              if (
                this.figures[i].f_name.includes(
                  this.search_content.trim().toLowerCase()
                )
              ) {
                this.figures_serach.push(this.figures[i]);
              }
            }
          } else {
            this.figures_serach = this.figures;
          }
          break;
        case "2":
          // 影片搜索
          break;
        case "3":
          // 文章搜索
          break;
        case "4":
          // 文献搜索
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });
      }
    },
    // 匹配搜索建议
    query_search(query_string, callback) {
      var restaurants = this.serach_option;
      var results = [];
      // 影人匹配
      for (var i = 0; i < restaurants.length; i++) {
        if (restaurants[i].value.includes(query_string.trim().toLowerCase())) {
          results.push(restaurants[i]);
        }
      }
      callback(results);
    },
    // 匹配项点击
    handle_select(item) {
      switch (this.select) {
        case "1":
          // 影人搜索
          this.$router.push("/directors?=" + item.value);
          this.$store.commit("set_current_figure", item.value);
          break;
        case "2":
          // 影片搜索
          this.$router.push("/video?id=" + item.id);
          this.$store.commit("set_current_figure", item.director);
          break;
        case "3":
          // 文章搜索
          this.$router.push("/essays?id=" + item.id);
          this.$store.commit("set_current_figure", item.writer);
          break;
        case "4":
          // 文献搜索
          this.$router.push("/literature?id=" + item.id);
          this.$store.commit("set_current_figure", item.label);
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });
      }
    },
    // 确定登出
    logout_click() {
      this.logout_visible = false;
      service({ url: "/logout", method: "get" })
        .then((response) => {
          this.$store.commit("change_state_out");
          this.$message({
            message: "登出成功！",
            type: "success",
          });
          this.$router.push("/");
          this.$store.commit("set_not_administrator");
          this.$store.commit("set_not_summerschoolstudent");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 提交修改密码表单
    onSubmit(change_password_form) {
      this.$refs[change_password_form].validate((valid) => {
        if (valid) {
          this.change_password_makesure = true;
        }
      });
    },
    // 修改密码
    change_password_click() {
      service({
        url: "/changepsw",
        method: "post",
        data: {
          old_password: this.change_passwrd.old_password,
          new_password: this.change_passwrd.new_password,
        },
      })
        .then((response) => {
          this.change_password_visible = false;
          this.change_password_makesure = false;
          if (response.data.msg == "change password success") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 提交修改个人信息表单
    onSubmitInfor(change_information_form) {
      this.$refs[change_information_form].validate((valid) => {
        if (valid) {
          this.change_information_makesure = true;
        }
      });
    },
    // 修改个人信息
    change_information_click() {
      service({
        url: "/changeinfo",
        method: "post",
        data: {
          id: this.user_id,
          true_name: this.change_infor.true_name,
          email: this.change_infor.email,
          sex: this.change_infor.sex,
          phone: this.change_infor.phone,
          photo: this.change_infor.photo,
          idcard: this.change_infor.idcard,
          idcertificates: this.change_infor.idcertificates,
          isrightshape: this.isrightshape,
        },
      })
        .then((response) => {
          this.change_information_visible = false;
          this.change_information_makesure = false;
          if (response.data.msg == "change error") {
            this.$message({
              message: "修改失败",
              type: "warning",
            });
          } else if (
            response.data.msg == "format error" &&
            this.$store.state.is_summerschoolstudent
          ) {
            this.$message({
              message: "照片格式错误",
              type: "warning",
            });
          } else if (
            response.data.msg == "format error" &&
            !this.$store.state.is_summerschoolstudent
          ) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else if (response.data.msg == "Success") {
            if (this.image_file.length == 1) {
              let file = this.image_file[0].raw;
              let pic_param = new FormData(); //form对象
              let usernanme = this.$store.state.user_name;
              let config = {
                headers: { "Content-Type": "multipart/form-data" },
              };
              pic_param.append("file", file, usernanme + ".jpg");
              this.axios
                .post(
                  "https://api.movie.yingshinet.com/changecertificate",
                  pic_param,
                  config
                )
                .then((response) => {
                  if (response.data.msg == "success") {
                    this.$message({
                      message: "证书照片上传成功！",
                      type: "success",
                    });
                    location.reload();
                  } else {
                    this.$message({
                      message: "证书照片好像出了点问题",
                      type: "warning",
                    });
                  }
                });
            }
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleChange(file, fileList) {
      this.onChangeImgUrl = file.url; //解决el-upload样式问题
      console.log(file);
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过 2M!");
        return false;
      } else if (!isJPG) {
        this.$message.warning("上传图片格式只能是jpg或png");
        return false;
      } else {
        this.imgchecked(file).then((data) => {
          //data返回值即为判断结果。
          if (data && isJPG && isLt2M) {
            this.isrightshape = true;
          } else {
            this.isrightshape = false;
            return false;
          }
        });
      }
      this.image_file.push(file);
    },
    handleRemove(file, fileList) {
      this.onChangeImgUrl = "";
    },
    // 限制图片大小和尺寸
    imgchecked(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file.raw);
        reader.onload = () => {
          let img = new Image();
          img.src = reader.result;
          img.onload = () => {
            if (
              Math.floor((img.width / img.height) * 100) / 100 > 0.75 ||
              Math.floor((img.width / img.height) * 100) / 100 < 0.65
            ) {
              this.$message.warning(
                `需上传图片宽高比例为3：4,当前文件${img.width}×${img.height}`
              );
              resolve(false);
            } else {
              resolve(true);
            }
          };
        };
      });
    },
  },
  created() {
    this.$store.commit("toggleCollapse");
    //获得推荐人物
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    //获取各类数据个数
    this.axios.get(this.path + "/statistics").then((response) => {
      this.chronicle_count = response.data.statistics[0].chronicle_count;
      this.article_count = response.data.statistics[0].article_count;
      this.essay_count = response.data.statistics[0].essay_count;
      this.figure_count = response.data.statistics[0].figure_count;
      this.movie_count = response.data.statistics[0].movie_count;
      this.encyclopedia_count = response.data.statistics[0].wp_count;
      this.user_count = response.data.statistics[0].user_count;
    });
    this.axios
      .post("https://api.movie.yingshinet.com/figures/select", {
        birth_time: month + "月" + day + "日",
      })
      .then((response) => {
        if (response.data.f_count != 0) {
          this.rec_figure = response.data.f_data[0];
        } else {
          this.axios
            .post("https://api.movie.yingshinet.com/figures/select", {
              birth_time: month + "月",
            })
            .then((res) => {
              this.rec_figure = res.data.f_data[0];
            });
        }
      });
    // 影人数据
    this.axios.get(this.path + "/figures").then((response) => {
      const fig_count = response.data.f_count;
      for (var i = 0; i < fig_count; i++) {
        this.figures.push(response.data.f_data[i]);
        this.figure_names.push({ value: response.data.f_data[i].f_name });
      }
      this.figures_serach = this.figures;
      this.fig_response = response.data;
      // this.figure_count = response.data.f_count;
      this.serach_option = this.figure_names;
    });
    // 年谱数据
    // this.axios.get(this.path + "/chronicles").then((response) => {
    //   this.chronicle_count = response.data.c_count;
    // });
    // 影片数据
    this.axios.get(this.path + "/movies").then((response) => {
      this.movie_count = response.data.m_count;
      for (var i = 0; i < this.movie_count; i++) {
        // value必须，不然搜索框不显示
        this.movies.push({
          value: response.data.m_data[i].m_name,
          id: response.data.m_data[i].mid,
          director: response.data.m_data[i].m_director,
        });
      }
    });
    // 影人文章数据
    this.axios.get(this.path + "/essays/part").then((response) => {
      this.essay_count = response.data.e_count;
      for (var i = 0; i < this.essay_count; i++) {
        this.essays.push({
          value: response.data.e_data[i].title,
          id: response.data.e_data[i].wid,
          writer: response.data.e_data[i].writer,
        });
      }
    });

    // 相关文献数据
    this.axios.get(this.path + "/articles/part").then((response) => {
      this.literature_count = response.data.a_count;
      for (var i = 0; i < this.literature_count; i++) {
        this.articles.push({
          value: response.data.a_data[i].title,
          id: response.data.a_data[i].id,
          label: response.data.a_data[i].label,
        });
      }
    });
    //用户数据
    if (this.$store.state.login_state) {
      this.axios
        .post(this.path + "/showinfo", { name: this.$store.state.user_name })
        .then((response) => {
          // console.log(response.data);
          (this.user_id = response.data.id),
            (this.change_infor.email = response.data.email),
            (this.change_infor.true_name = response.data.true_name),
            (this.change_infor.sex = response.data.sex),
            (this.change_infor.phone = response.data.phone),
            (this.change_infor.idcard = response.data.idcard),
            (this.change_infor.idcertificates = response.data.idcertificates);
        });
    }
  },
};
</script>

<style>
.header_new.header_height_new {
  height: auto !important;
}
.menu {
  height: 12vh;
  box-shadow: 0px 1px 3px #aaaaaa;
}
.el-tooltip__popper {
  padding: 13px !important;
  font-size: 18px !important;
  box-shadow: 0 0 15px #aaaaaa;
}
.el-input-group__prepend {
  border-radius: 1vw 0 0 1vw !important;
}

.el-input-group__append {
  border-radius: 0 1vw 1vw 0 !important;
}
.el-select-dropdown__item.selected {
  color: #e2a253 !important;
}
.el-button--medium {
  font-size: 15px !important;
}
.el-divider--horizontal {
  margin: 3vw 0 2vw 0 !important;
}
.input_style_hidden {
  display: none;
}
.el-menu-item.noneclass_hidden {
  height: 10vh !important;
  margin-left: 0% !important;
  display: flex;
  align-items: center;
}
.noneclass {
  color: #e2a253;
  margin-top: 0.5vh;
  height: 13vh !important;
}
.el-submenu__title {
  height: 65px !important;
}
.img_phone {
  height: 7vh;
  margin-left: -20px;
}
.img_pc {
  margin-top: 0.4vh;
  height: 10vh;
}
</style>

<style scoped>
.el-menu--horizontal > .el-menu-item.is-active {
  font-size: 1.3vw;
  font-weight: bold;
  border-bottom: none !important;
  text-decoration: none !important;
}

.el-submenu .el-menu-item {
  height: 60px;
}
.el-menu--horizontal > .el-menu > .el-menu-item {
  height: 50px !important;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  text-decoration: none !important;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  font-size: 1.3vw;
  font-weight: bold;
  border-bottom: none !important;
  text-decoration: none !important;
}
.header_new > .header_height_new.submenu-title {
  margin-left: 20px !important;
}
.input_style {
  z-index: 2;
  position: relative;
  width: 20vw;
  left: 3.4vw;
  top: 3.3vh;
  border-radius: 1vw;
  box-shadow: 4px 4px 10px #aaaaaa;
}
.input_style_phone {
  margin: 1vh 0vw;
  width: 100%;
  box-shadow: 0;
  border-radius: 0 0 0 0;
}
.input_style_phone > .select_style {
  width: 100%;
  border-radius: 0 0 0 0;
}
.go_dir_icon {
  margin-left: 20px;
  margin-top: 18.5px;
  display: inline-block;
  font-size: 35px;
  color: #e2a253;
}
.select_style {
  width: 6vw;
  border-radius: 1vw 0 0 1vw;
}
.serach_button_style {
  width: 100%;
  color: #fff;
}
.login_out_fix {
  float: right;
  margin-top: 22px;
  margin-right: 1vw;
  white-space: pre;
}
.login_out_height {
  height: 40px;
  margin: 0 0 0 1vh;
}
.header_new {
  height: auto;
  padding-left: 8%;
  padding-right: 8%;
}
.header_image {
  height: 65px;
  margin-top: 10px;
  margin-left: 0.2vw;
}
.icon_style {
  font-size: 2.8vw !important;
  color: #e2a253;
  align-items: center !important;
}
.top_icon_style {
  margin: 3px 4px !important;
  font-size: 30px !important;
  color: #e2a253;
}
.data_font_size {
  font-size: 1vw;
  align-content: center;
}
.el-dropdown-link {
  cursor: pointer;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.icon_shadow {
  box-shadow: 5px 5px 5px #aaaaaa;
}
.el-col-15 {
  float: none !important;
}
.el-col-6 {
  float: right !important;
}
.flex-container {
  height: 15vh;
  height: fit-content;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: space-between; /* 在容器两端分布 */
}

.start-item {
  margin: 0 10px 0 0; /* 右边添加一些水平间距 */
}

.menu-icon {
  display: flex;
  justify-content: flex-end;
  margin-left: 40px;
  margin-top: 5px;
}
.message_count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.submenu-title {
  display: flex;
}
.small-text {
  line-height: 0;
  font-size: 1%;
}
.el-submenu__title {
  padding: 0 20px !important;
}

/*创建汉堡图标*/
.hamburger-icon::before {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  background-color: #aaaaaa;
  margin-bottom: 5px;
}
.hamburger-icon::after {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  background-color: #aaaaaa;
  margin-top: 5px;
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .submenu-title {
    padding-left: 20px;
  }
  .small-text {
    display: none; /* 在手机端隐藏元素 */
  }
}
</style>
