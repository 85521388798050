<template>
  <el-container v-if="moviedata">
    <el-header>
    <el-row>
      <el-col :xs="24" :sm="18" :md="17" :lg="17" :xl="18" style="padding-left:1%;padding-right:1%;padding-top:1%">
        <el-row>
          <video-player class="video-player vjs-custom-skin" ref="VideoPlayer" :playsinline="true"
            :options="playerOptions" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)"
            @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)" @playing="onPlayerPlaying($event)"
            @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
            @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)"
            @statechanged="playerStateChanged($event)" @ready="playerReadied"></video-player>
        </el-row>
        <el-row>
          <!-- <hr /> -->
          <el-container direction="vertical">
            <!-- <div>热门推荐</div> -->
            <el-row :gutter="20">
              <el-col :xs="6" :sm="4" :md="3" :lg="3" :xl="2" v-for="(data, i) in showData" :key="i" class="mouse_hover">
                <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }">
                  <!-- <router-link :to="'/video?id='+data.no"> -->
                  <img :src="data.pic" class="image" fit="cover" @click="convertmovie(data.no)" />
                  <!-- </router-link> -->
                  <div class="bottom clearfix">
                    <div class="time">{{ data.name }}</div>
                  </div>
                </el-card>
              </el-col>
              <el-col v-if="$store.state.login_state" :span="3" class="mouse_hover">
                <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }">
                  <!-- <router-link :to="'/video?id='+data.no"> -->
                  <el-image src="/static/add.jpg" @click="drawer_add = true" class="image" fit="cover" />
                  <!-- //class="image"  -->
                  <!-- </router-link> -->
                  <div class="bottom clearfix">
                    <div style="font-size: 15px;color: #999;text-align:center">添加影片</div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-container>
        </el-row>
      </el-col>
      <el-col :xs="0" :sm="6" :md="7" :lg="7" :xl="6" style="padding-left:20px;padding-right:20px;padding-top:20px">
        <!-- <div class="wrapper" ref="wrapper">
        <div class="content">-->
        <el-row style="height:80vh">
          <video-search style="width:100%" v-on:searchUrl="searchUrl"></video-search>
          <el-col :span="18"></el-col>
          <el-col :span="6">
            <router-link to="/video_index" style="float:right;color:#B8860B">分类查找</router-link>
          </el-col>

          <el-col height="160px" :span="15" style="padding-left:2px;margin-top:-20px">
            <span class="mouse_over"
              style="height: auto; font-size: 26px; color:#B8860B; line-height: 60px; text-align:center;font-weight:bold;">《{{
                replaceArr.m_name }}》</span>
            <span class="mouse_over" @click="routerTo"
              style="color: #b8860b; font-size: 15px;text-decoration: underlines;margin-left:-10px"> 拉片</span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">导演：</span>
            <span class="mouse_over"
              style="height: auto; font-size: 15px; color:gray; line-height: 30px; text-align:center;"
              v-html="replaceArr.m_director"></span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">首映时间：</span>
            <span style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px">{{ replaceArr.m_year
            }}年</span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">豆瓣评分：</span>
            <span style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px"
              v-html="replaceArr.douban_rank"></span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">主演：</span>
            <span style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px"
              v-html="replaceArr.m_actor"></span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">编剧：</span>
            <span style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px"
              v-html="replaceArr.m_script"></span>
            <br />
          </el-col>
          <el-col :span="9" style="padding-right:0%;padding-top:4%">
            <!-- <el-image
              style="height: 160px; width:120px; border-radius: 8px;"
              :src="replaceArr.m_pic"
              
            ></el-image>-->
            <div>
              <img style="height: 160px; width:120px; border-radius: 8px;" :src="replaceArr.m_pic" />
              <el-button v-if="$store.state.login_state" @click="drawer = true"
                style="color:#B8860B;width:120px;">更改影片信息</el-button>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="wrapper" ref="wrapper">
              <el-scrollbar style="overflow-y:hidden; height:100%">
                <div class="content">
                  <span
                    style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">本事/简介：</span>
                  <span class="introduction"
                    style="height:auto; font-size: 15px; color:gray;text-align: left;line-height:30px"
                    v-html="replaceArr.m_intro"></span>
                </div>
              </el-scrollbar>
            </div>
            <!--
            <chronicle-list
            :msg="keywordSearch"
            :director="director"
            ></chronicle-list>
            -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    </el-header>
    <el-main >
      <chronicle-list
            :msg="keywordSearch"
            :director="director"
            ></chronicle-list>
    </el-main>
    <el-drawer title="请输入需要更改的影片信息" size="100%" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <video-edit></video-edit>
    </el-drawer>
    <el-drawer title="请输入影片信息" size="100%" :visible.sync="drawer_add" :direction="direction" :before-close="handleClose">
      <video-add></video-add>
    </el-drawer>
  </el-container>
</template>

<script>
import BScroll from "better-scroll";
import VideoSearch from "../components/video/VideoSearch.vue";
import VideoEdit from "../components/video/VideoEdit.vue";
import VideoAdd from "../components/video/VideoAdd.vue"
import ChronicleList from "../components/chronicle/chronicle_list_video.vue";
export default {
  components: {
    VideoSearch,
    VideoEdit,
    VideoAdd,
    ChronicleList,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  // name: 'Home',
  // inject:['reload'],
  data() {
    return {
      drawer_add: false,
      drawer: false,
      direction: "btt",
      colors: ["#b8860b", "#778899"],
      currentDate: new Date(),
      directors: [],
      movie: [],
      moviedata: {},
      // videoid:1,
      movie_id: 12,
      tableData: [],
      showData: [],
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            // src:require( '../assets/XCZC.mp4' )// url地址
            src: "",
            // src: 'https://assets.yingshinet.com/chronicles/videos/SSH_FM.mp4'
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "暂时没有此视频资源，请观看《人生》、《联华交响曲》、《城市之夜》、《狼山喋血记》、《天伦》、《香雪海》、《生死恨》", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      director:'费穆',
      keywordSearch:'',
    };
  },
  watch: {
    moviedata(newdata,olddata) {
      this.keywordSearch = this.moviedata.m_name
      console.log(this.keywordSearch)
    },
    $route(to, from) {
      //监听路由是否变化

      if (to.query.id != from.query.id) {
        this.axios
          .post("https://api.movie.yingshinet.com/movies/select", {
            mid: to.query.id,
          })
          .then((response) => {
            this.moviedata = response.data.m_data[0];
            this.getInfo(this.moviedata.m_director);

            this.playerOptions.sources[0].src =
              this.moviedata.m_video ||
              "https://assets.yingshinet.com/chronicles/videos/TL_FM.mp4";
          });
      }
    },
  },
  methods: {
    // 播放回调
    onPlayerPlay(player) {
      // console.log("player play!", player);
    },
    // 暂停回调
    onPlayerPause(player) {
      // console.log("player pause!", player);
    },
    // 视频播完回调
    onPlayerEnded(player) {
      // console.log(player);
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      // console.log(player);
    },
    // 已开始播放回调
    onPlayerPlaying(player) {
      // console.log(player);
    },
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata(player) {
      // console.log(player);
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      // console.log(player);
    },
    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },
    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },
    //播放状态改变回调
    playerStateChanged(playerCurrentState) {
      // console.log("player current update state", playerCurrentState);
    },
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {
      // console.log("example player 1 readied", player);
    },
    //关闭按钮
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    routerTo() {
      this.$router.push({
        path: "/Filmanalysis",
        query: { moviename: this.moviedata.m_name },
      });

    },
    getInfo(director) {
      this.axios
        .post("https://api.movie.yingshinet.com/movies/select", {
          director: director,
        })
        .then((response) => {
          this.work = JSON.parse(JSON.stringify(response.data)).m_data;
          this.num = response.data.m_count;
          this.getData();
        });

      this.axios
        .get("https://api.movie.yingshinet.com/movies")
        .then((response) => {
          for (var i = 0; i < response.data.m_data.length; i++) {
            this.movie.push(response.data.m_data[i].m_name);
          }
        });
      //获取人物名
      this.axios
        .get("https://api.movie.yingshinet.com/figures")
        .then((response) => {
          for (var i = 0; i < response.data.f_data.length; i++) {
            this.directors.push(response.data.f_data[i].f_name);
          }
        });
    },
    getData() {
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.work[i].mid;
        this.tableData[i].name = this.work[i].m_name;
        this.tableData[i].pic =
          this.work[i].m_pic ||
          "https://assets.yingshinet.com/chronicles/pictures/MOVIE_27.jpg";
        this.tableData[i].director = this.work[i].m_director;
        this.tableData[i].actor = this.work[i].m_actor;
        this.tableData[i].year = this.work[i].m_year;
        this.tableData[i].script = this.work[i].m_script;
        this.tableData[i].douban_rank = this.work[i].douban_rank;
        this.tableData[i].intro = this.work[i].m_intro || "简介" + i + "待补充";
        this.tableData[i].video_path =
          this.work[i].m_video ||
          "https://assets.yingshinet.com/chronicles/videos/TL_FM.mp4";
      }

      this.showData = this.tableData.slice(0, 7);
    },
    convertmovie(id) {
      this.$router.push({
        path: "/video",
        query: {
          id: id,
        },
      });
      location.reload();
    },

    searchUrl: function (Value) {
      // childValue就是子组件传过来的值
      this.result = Value;
      if (this.result.length !== 0) {
        this.$router.push({
          path: "/video",
          query: {
            id: this.result[0].no,
          },
        });
        location.reload();
      } else {
        this.$alert("换个关键词试试", "找不到您想搜索的内容", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$message({
              type: "info",
              message: `搜索不到您想要的内容`,
            });
          },
        });
      }
    },
  },
  created() {
    this.axios
      .post("https://api.movie.yingshinet.com/movies/select", {
        mid: this.$route.query.id,
      })
      .then((response) => {
        this.moviedata = response.data.m_data[Math.floor(Math.random() * (response.data.m_data.length))];//随机选择一部数据库里有视频的电影
        this.$set(this.moviedata);
        this.getInfo(this.moviedata.m_director);
        this.playerOptions.sources[0].src = this.moviedata.m_video;
        console.log(this.playerOptions.sources[0].src)
        // this.moviedata.m_video ||
        // "https://assets.yingshinet.com/chronicles/videos/TL_FM.mp4";
        this.$nextTick(() => {
          this.scroll = new BScroll(this.$refs.wrapper, {});
        });
      });
  },

  computed: {
    replaceArr() {
      const arr = JSON.parse(JSON.stringify(this.moviedata));

      const colors = this.colors;
      const keywords = this.directors;
      const keywords2 = this.movie;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a style="text-decoration:none" href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        //替换
        arr.m_director = arr.m_director.replace(replaceReg, replaceString);

        if (arr.m_script) {
          arr.m_script = arr.m_script.replace(replaceReg, replaceString);
        }
        if (arr.douban_rank) {
          arr.douban_rank = arr.douban_rank;
        } else {
          arr.douban_rank = "暂无评分";
        }

        if (arr.m_actor) {
          arr.m_actor = arr.m_actor.replace(replaceReg, replaceString);
        }
        if (arr.m_intro) {
          arr.m_intro = arr.m_intro.replace(replaceReg, replaceString);
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp("《" + keywordItem + "》", "g");
        // 高亮替换v-html值

        const replaceString = `<a style="text-decoration:none" href="/#/video?id=${keywordIndex + 1
          }"><font color=${colors[0]}>《${keywordItem}》</font></a>`;
        // 替换
        arr.m_director = arr.m_director.replace(replaceReg, replaceString);

        if (arr.m_script) {
          arr.m_script = arr.m_script.replace(replaceReg, replaceString);
        }
        if (arr.douban_rank) {
          arr.douban_rank = arr.douban_rank;
        } else {
          arr.douban_rank = "暂无评分";
        }
        if (arr.m_actor) {
          arr.m_actor = arr.m_actor.replace(replaceReg, replaceString);
        }
        if (arr.m_intro) {
          arr.m_intro = arr.m_intro.replace(replaceReg, replaceString);
        }
      });

      return arr;
    },
  },
};
</script>
<style scoped>
.home {
  width: 80%;
  margin: 0 0;
  text-align: center;
}

.el-container {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
}

.el-aside {
  background-color: #f3f3f3;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: left;
  height: 100vh;
}

.wrapper {
  height: 50vh;
  overflow: hidden;
}

.el-footer {
  padding-top: 0px;
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  height: 0px;
}

.el-row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
  margin-bottom: 20px;
}

.card {
  width: 100%;
  height: 150px;
  margin-top: 5px;
}

.bg-purple {
  background: #e9eef3;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f4f7ff;
}

.time {
  font-size: 13px;
  color: #999;
}

.right {
  margin-right: 0px;
}

.bottom {
  margin-top: 0px;
  line-height: 12px;
  padding-top: 5px;
  /* padding: 14px; */
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 130px;
  display: block;
}

.input {
  margin-top: -10%;
  margin-bottom: 2%;
  padding-top: 0px;
  width: 100%;
  margin-left: 0%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.introduction::after {
  content: '凑字数凑字数凑字数凑字数凑字数凑字数凑字数凑字数凑字数';
  display: inline-block;
  opacity: 0;
}

.clearfix:after {
  clear: both;
}

.mouse_hover:hover {
  cursor: pointer;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>