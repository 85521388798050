<template>
  <div>
      <EssaysContent :essayurl="essayurl" :directorurl='directorurl'></EssaysContent>
  </div>
</template>
  
<script>
// 引入组件
import EssaysContent from '../components/essay/EssaysContent.vue'
export default {
  name: 'Essays',
  components: {
    EssaysContent
  },
  data() {
    return {
      essayurl: {},
      directorurl:{},
      nowid:1
    }
  },
  watch: {
    //监听路由是否变化
    '$route'(to, from) { 
    //如果路由发生变化，则按照变化后的路由查找显示当前作品 
      if (to.query.id != from.query.id) {
        this.axios
          .post("https://api.movie.yingshinet.com/essays/select", { id: to.query.id })
          .then((response) => {
            this.essayurl = response.data.e_data[0];
            this.axios
            .post("https://api.movie.yingshinet.com/figures/select", { f_name:this.essayurl.writer })
            .then((response) => {
            this.directorurl=response.data.f_data[0]
          });
          });

      }
    },

  },
  created() {
    // 进入页面，根据传来的影人名在影人库中查找该影人信息
    this.axios
      .post("https://api.movie.yingshinet.com/figures/select", { f_name:this.$store.state.current_figure })
      .then((response) => {
      this.directorurl=response.data.f_data[0]
    });
    // 根据影人名在作品库中查找其所有作品
    this.axios
      .post("https://api.movie.yingshinet.com/essays/searchwriter", { writer: this.$store.state.current_figure })
      .then((response) => {
        this.search_data = response.data.e_data;
        this.search_num = response.data.e_count;
        // 如果跳转到该页面时有id传来，则根据id显示页面内容
        if(this.$route.query.id){
          this.axios
          .post("https://api.movie.yingshinet.com/essays/select", { id:this.$route.query.id})
          .then((response) => {
          this.essayurl=response.data.e_data[0];
          });
        }
        else if(this.search_num==0){
          this.essayurl={
            content:'该影人还未录入作品数据，请登录后录入数据。',
            file_dir:[require("../../public/static/pdf_icon.png")]
          }

        }
        // 否则，默认显示该影人的作品库中第一个作品
        else{
          this.nowid=response.data.e_data[0].wid
          this.axios
          .post("https://api.movie.yingshinet.com/essays/select", { id:this.nowid})
          .then((response) => {
          this.essayurl=response.data.e_data[0];
          });
        }  
      });
  },

}
</script>
<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

</style>