<template>
  <div class="pic_border">
    <!-- 每月推荐 -->
    <span id="bigcenter">
      <el-tooltip class="item" effect="dark" :content="rec_figure.f_name + ': ' + rec_figure.f_brief" placement="right">
        <img :src="rec_figure.f_pic" :alt="rec_figure.f_name" @click="figure_click(rec_figure.f_name)"
          class="image_style_big" />
      </el-tooltip>
    </span>
    <!-- 照片墙 -->
    <span v-for="(figure, index) in figures_serach" :key="index" class="eliminate_margin">
      <span v-if="figure.f_pic != null">
          <el-image :src="figure.f_pic" :alt="figure.f_name" :title="figure.f_name" 
          @click="figure_click(figure.f_name)" class="image_style" lazy>
            <div slot="error" align="center" style="margin-top: 4vw; color: rgb(156, 156, 156); font-size: 1vw">
              <i style="font-style: normal" @click="figure_click(figure.f_name)">
                {{
                  figure.f_name
                }}
              </i>
            </div>
          </el-image>
      </span>
    </span>
  </div>
</template>
<script>
export default {
  name: "chronicle_list",
  props: {
    figures: Array,
    figure_names: Array,
    figures_serach: Array,
    rec_figure: Object,
  },
  data() {
    return {
    };
  },

  methods: {
    // 照片点击
    figure_click(figure_name) {
      this.$router.push("/directors?=" + figure_name);
      this.$store.commit("set_current_figure", figure_name);
    },

  },
  created() {

  }
};
</script>

<style scoped>
#bigcenter {
  float: left;
  z-index: 1;
}
.image_style_big {
  width: 15.15vw;
  height: 18.45vw;
  object-fit: fill;
}
.image_style_big:hover {
  z-index: 1;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}
.eliminate_margin {
  font-size: 0px;
}
.image_style {
  width: 5.05vw;
  height: 6.15vw;
  object-fit: fill;
}
.image_style:hover {
  z-index: 1;
  transform: scale(1.4, 1.4);
  transition: 0.5s transform;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}
.pic_border {
  font-size: 0;
  margin: 2vw 0vw 0 1.5vw;
}
</style>
