import { render, staticRenderFns } from "./postSearch.vue?vue&type=template&id=f57c3520&scoped=true"
import script from "./postSearch.vue?vue&type=script&lang=js"
export * from "./postSearch.vue?vue&type=script&lang=js"
import style0 from "./postSearch.vue?vue&type=style&index=0&id=f57c3520&prod&scoped=true&lang=css"
import style1 from "./postSearch.vue?vue&type=style&index=1&id=f57c3520&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f57c3520",
  null
  
)

export default component.exports