<template>
  <!-- 底部第一行容器 -->
  <el-card class="word-cloud" style="height: 74.5vh; width: 96vw; margin: 0 auto; margin-top: 2vh">
    <span class="elebutton">
      <el-button
        style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa"
        class="el-backtop"
        icon="el-icon-refresh"
        @click="toggleVisibility"
      ></el-button>
    </span>
    <Terms_cloud v-if="vis"></Terms_cloud>
    <template v-if="!vis">
      <div style="padding:5px">
        <el-row :gutter="8">
          <el-col>
            <!-- 标签列表子组件 -->
            <terms_list :all_terms="all_terms"></terms_list>
          </el-col>
        </el-row>
      </div>
    </template>
  </el-card>
</template>

<script>
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as $ from "jQuery";
import DirectorInfo from "../components/director/director_info.vue";
import Terms_list from "../components/director/terms_list.vue";
import Terms_cloud from "../components/director/term_cloud.vue";


export default {
  name: "PicCloud",
  components: {
    DirectorInfo,
    Terms_list,
    Terms_cloud,
  },
  data() {
    return {
      words: [],
      path: "https://api.movie.yingshinet.com",
      all_tags: [],
      currentPage: 1,
      pagesize: 20,
      default_name: "国际短片电影节",
      director_sorted: [],
      tableData_length: null,
      directors: [],
      all_terms: [],
      director_search: "",
      color: "#b8860b",
      vis: true,
    };
  },

  methods: {
    toggleVisibility() {
      this.vis = !this.vis;
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.isSearch) {
        // 如果是搜索功能的话，是一次性返回全部数据，需要每次换页的时候分页显示
        this.director_sorted = this.list.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize);
      } else {
        this.fetchDirectors(currentPage);
      }
    },
    fetchDirectors(page) {
      const path = `${this.path}/figures_world_Festival`;
      this.axios
        .post(path, { divide: 1, page, count: 50 })
        .then((res) => {
          this.tableData_length = res.data.all_figures_count;
          this.directors = res.data.figures.map((figure) => figure.f_name);
          this.director_sorted = [...this.directors];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file = [];
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    // 给每个词加搜索功能
    initWordCloud() {
      this.drawWordCloud();
      this.$nextTick(() => {
        document.querySelectorAll(".cloud-word").forEach((item) => {
          item.addEventListener("click", () => {
            // 词云图词汇内容
            const keyword = item.textContent;
            this.$router.push(`/post?name=${keyword}`);
            this.$store.commit("set_current_figure", keyword);
          });
        });
      });
    },
    // 获得初始数据
    getEvents() {
      this.axios.post(`${this.path}/terms`).then((response) => {
        this.all_tags = response.data.all_terms;
        this.words = this.all_tags
          .filter((tag) => tag.count > 15 && tag.count < 500)
          .map((tag) => ({
            text: tag.terms,
            size: Math.floor(tag.count / 12 + 6),
          }));
        this.initWordCloud();
      });
    }, 
    movie_click(movie_id) {
      this.$router.push(`/video?id=${movie_id}`);
    },
    fetchTerms() {
      this.axios.post(`${this.path}/terms`).then((response) => {
        this.all_terms = response.data.all_terms;
      });
    },
  },

  computed: {
    // 搜索
    search_data() {
      if (!this.director_search.trim()) {
        return this.director_sorted;
      }
      return this.director_sorted.filter((data) =>
        data.toLowerCase().includes(this.director_search.trim().toLowerCase())
      );
    },
    // 实体超链接
    entity_link() {
      let driection_link = this.direction;
      this.movie_names.forEach((movie_name) => {
        const replaceReg = new RegExp(`《${movie_name.mname}》`, "g");
        const replaceString = `<a href="/#/video?id=${movie_name.mid}" style="text-decoration:none"><font color=${this.color}>《${movie_name.mname}》</font></a>`;
        driection_link = driection_link.replace(replaceReg, replaceString);
      });
      return driection_link;
    },
  },
  mounted() {
    this.getEvents();
    this.fetchTerms();
    this.handleCurrentChange(1);
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>

<style>
.text_item {
  font-size: 15px;
  margin-bottom: 12px;
  margin-left: 15px;

}

.mouse_over:hover {
  color: #b8860b;
  cursor: pointer;
}

.el-card .el-card__header {
  padding: 8px 20px;
}

.e-input {
  text-align: left;
  border-radius: 5px;
  color: #606266;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  padding-left: 8px;
  outline: none;
}

.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}

.back_image {
  background-image: url("/static/back1.jpg");
  background-size: cover;
}


::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.el-carousel__indicators li {
  border: 0.5vw;
}
.el-card__body {
  padding: 0.5vw;
}
.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.cloud-word:hover {
  cursor: pointer;
}
.footerCard .el-card__body {
  padding: 0.5vw;
}
</style>