<template>
  <!-- <el-button @click="get_update_data()"></el-button> -->
  <!-- 修改顺序 -->
  <el-timeline class="timeline infinite-list" style="width: 31.5vw">
    <!-- 时间列表的每一项 -->
    <el-timeline-item
      v-for="(eventItem, index) in replaceArr"
      :key="eventItem.cid"
    >
      <el-card>
        <div>
          <!-- 内容 -->
          <span
            class="itemdata"
            v-html="allData_by_update[index].c_date + '，' + eventItem.content"
          ></span>

          <span class="itemfont"
            ><div style="font-size: 1.05vw">
              【创建人】:
              {{ allData_by_update[index].c_create_man }}&nbsp;
            </div>
            <div style="font-size: 1.05vw">
              【来源】: {{ allData_by_update[index].c_source }}
            </div></span
          >
          <span
            v-if="($store.state.auth == 1) ||
              ($store.state.auth == 2 &&
                allData_by_update[index].c_create_man == $store.state.user_name)"
            style="
              float: right;
              margin-right: 0vw;
              font-size: 1.2vw;
              margin-top: 0.2vw;
            "
            class="el-icon-delete mouse_over_head"
            @click="deleteEventItem(allData_by_update[index])"
          ></span>
          <span
            v-if="$store.state.login_state"
            style="
              float: right;
              margin-right: 0.5vw;
              font-size: 1vw;
              margin-top: 0.2vw;
            "
            class="el-icon-edit mouse_over_head"
            @click="editEventIem(allData_by_update[index])"
          ></span>
        </div>
      </el-card>
    </el-timeline-item>
    <!-- 删除确认框 -->
    <el-dialog title="确认删除" :visible.sync="delete_dialogEditVisible">
      <div>是否确认删除该条年谱数据？</div>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="
            transition: 0.1s;
            font-weight: 250;
            padding: 8px 12px;
            font-size: 7px;
            border-radius: 4p;
          "
          @click="delete_dialogEditVisible = false"
          >取 消</el-button
        >
        <el-button
          style="
            transition: 0.1s;
            font-weight: 250;
            padding: 8px 12px;
            font-size: 7px;
            border-radius: 4p;
          "
          type="warning"
          @click="confirm_delete()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑页面遮罩层 -->
    <el-dialog
      class="el-dia"
      title="年谱编辑：时间、地点、人物、事件，四个最重要的元数据。"
      :visible.sync="dialogEditVisible"
    >
      <!-- 影人信息表单 -->
      <el-form>
        <el-form-item label="日期" width="10vw">
          <el-input style="width: 20%" v-model="update_year"></el-input>年
          <el-input style="width: 20%" v-model="update_month"></el-input>月
          <el-input style="width: 20%" v-model="update_date"></el-input>日
          <span
            style="
              font-size: 10px;
              color: firebrick;
              margin-left: 20px;
              margin-bottom: 0px;
            "
            >提示：如果没有准确日期，请输入当年的0月或0日，如：1900年0月0日，1900年1月0日</span
          >
        </el-form-item>
        <!-- 原文不能修改做参考 -->
        <el-form-item label="原文" width="10vw">
          <el-popover
            placement="bottom-end"
            width="190"
            trigger="hover"
            content="请将修改结果填入下方输入框"
            style="font-size: 1vw"
          >
            <el-input
              type="textarea"
              :rows="6"
              v-model="eventItemContent"
              autocomplete="off"
              :readonly="true"
              :disabled="true"
              slot="reference"
            >
              ></el-input
            >
          </el-popover>
        </el-form-item>
        <el-form-item label="修改为" width="10vw">
          <el-input
            type="textarea"
            :rows="6"
            v-model="tmpEventItemContent"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="来源" width="10vw">
          <el-input
            type="textarea"
            :rows="6"
            v-model="new_source"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button style="
            transition: 0.1s;
            font-weight: 500; 
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4p;
          " type="info" @click="tmpEventItemContent = eventItemContent, tmpupdate_source = update_source">重置
        </el-button> -->
        <el-button
          style="
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4p;
          "
          @click="dialogEditVisible = false"
          >取 消</el-button
        >
        <el-button
          style="
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4p;
          "
          type="warning"
          @click="make_sure_click"
          >确 定</el-button
        >
      </div>

      <!-- 编辑页面确认弹窗 -->
      <el-dialog
        title="提示"
        :visible.sync="make_sure"
        width="30%"
        append-to-body
      >
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </el-timeline>
</template>

<script>
import service from "../../utils/request";
export default {
  props: ["director", "directors"],
  name: "ChronicleUpdate",
  inject: ["reload"],
  data() {
    return {
      colors: ["#b8860b", "#778899"],
      movie: [],
      keywordSearch: "",

      path: "https://api.movie.yingshinet.com/chronicles",
      allData_by_update: [], //用户修改数据
      updatedata_length: 0,

      chronicle_id: "",

      update_year: "",
      update_month: "",
      update_date: "",
      update_date_string: "",

      eventItemContent: "",
      tmpEventItemContent: "",
      str: "",
      new_source: "",

      delete_dialogEditVisible: false,
      dialogEditVisible: false,
      make_sure: false,
    };
  },
  watch: {
    director(curVal, oldVal) {
      // console.log("watch!!!!!!!!!!!!!!");
      this.director = curVal;
      this.get_update_data();
    },
  },
  methods: {
    // 获取更新记录
    get_update_data() {
      this.allData_by_update = [];
      const keyword = this.keywordSearch.trim();
      const keyJson = {
        // searchData: "山东",
        // month: 1,
        // figure: "费穆",
      };
      if (keyword) {
        keyJson.searchData = keyword;
      }
      keyJson.figure = this.director;
      // keyJson.c_create_man = this.$store.state.user_name;
      keyJson.type = "update_time";

      // this.$store.commit("setkeywordSearch", keyword);
      this.axios
        .post(this.path + "/keyword", keyJson)
        .then((response) => {
          // console.log("type = update_time:")
          console.log(response.data.searchShow);
          this.updatedata = response.data.searchShow;
          this.updatedata_length = this.updatedata.length;
          for (var i = 0; i < this.updatedata_length; i++) {
            this.allData_by_update[i] = {};
            this.allData_by_update[i].content = this.updatedata[i].c_content;
            this.allData_by_update[i].c_year = this.updatedata[i].c_year;
            this.allData_by_update[i].c_month = this.updatedata[i].c_month;
            this.allData_by_update[i].c_id = this.updatedata[i].cid;
            if (this.updatedata[i].c_month == 0) {
              this.allData_by_update[i].c_date = this.updatedata[
                i
              ].c_date.substring(0, this.updatedata[i].c_date.length - 4);
            } else if (
              (this.updatedata[i].c_date.substr(-2, 1) == 0) &
              isNaN(Number(this.updatedata[i].c_date.substr(-3, 1), 10))
            ) {
              this.allData_by_update[i].c_date = this.updatedata[
                i
              ].c_date.substring(0, this.updatedata[i].c_date.length - 2);
            } else {
              this.allData_by_update[i].c_date = this.updatedata[i].c_date;
            }
            this.allData_by_update[i].c_create_man =
              this.updatedata[i].c_create_man;
            this.allData_by_update[i].c_source = this.updatedata[i].c_source;
            this.allData_by_update[i].c_update_man =
              this.updatedata[i].c_update_man;
            this.allData_by_update[i].c_update_time =
              this.updatedata[i].c_update_time;
          }
          this.$set(this.allData_by_update);
          // console.log(this.allData_by_update,'kjhikh');
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // //搜索更新记录
    // search_update_Item() {
    //   const keyword = this.keywordSearch.trim();
    //   const month = this.MonthSearch.trim();
    //   const year = this.YearSearch.trim();

    //   const keyJson = {
    //     // searchData: "山东",
    //     // year: 1937,
    //     // figure: "费穆",
    //   };
    //   if (keyword) {
    //     keyJson.searchData = keyword;
    //   }
    //   if (month) {
    //     keyJson.month = month;
    //   }
    //   if (year) {
    //     keyJson.year = year;
    //   }
    //   keyJson.figure = this.director;
    //   keyJson.type = "update_time";
    //   // this.$store.commit("setkeywordSearch", keyword);
    //   // console.log(
    //   //   "this.$store.state.keywordSearch",
    //   //   this.$store.state.keywordSearch
    //   // );
    //   this.axios
    //     .post(this.path + "/keyword", keyJson)
    //     .then((response) => {
    //       // console.log("type = update_time:")
    //       // console.log(response.data.searchShow)
    //       this.updatedata = response.data.searchShow;
    //       this.updatedata_length = this.updatedata.length;
    //       for (var i = 0; i < this.updatedata_length; i++) {
    //         this.allData_by_update[i] = {};
    //         this.allData_by_update[i].content = this.updatedata[i].c_content;
    //         this.allData_by_update[i].c_year = this.updatedata[i].c_year;
    //         this.allData_by_update[i].c_month = this.updatedata[i].c_month;
    //         this.allData_by_update[i].c_id = this.updatedata[i].cid;
    //         if (this.updatedata[i].c_month == 0) {
    //           this.allData_by_update[i].c_date = this.updatedata[
    //             i
    //           ].c_date.substring(0, this.updatedata[i].c_date.length - 4);
    //         } else if (this.updatedata[i].c_date.substr(-2, 1) == 0 & isNaN(Number(this.updatedata[i].c_date.substr(-3, 1),10)) ) {
    //           this.allData_by_update[i].c_date = this.updatedata[
    //             i
    //           ].c_date.substring(0, this.updatedata[i].c_date.length - 2);
    //         } else {
    //           this.allData_by_update[i].c_date = this.updatedata[i].c_date;
    //         }
    //         this.allData_by_update[i].c_create_man =
    //           this.updatedata[i].c_create_man;
    //         this.allData_by_update[i].c_source = this.updatedata[i].c_source;
    //         this.allData_by_update[i].c_update_man =
    //           this.updatedata[i].c_update_man;
    //         this.allData_by_update[i].c_update_time =
    //           this.updatedata[i].c_update_time;
    //       }
    //       this.$set(this.allData_by_update);
    //       // console.log(this.allData_by_update,'kjhikh');
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    // 删除某条年谱
    confirm_delete() {
      this.axios.post(this.path + "/delete", {
        cid: this.delete_item.c_id,
        auth: this.$store.state.auth,
        name: this.$store.state.user_name,
      });
      this.delete_dialogEditVisible = false;
      this.reload();
      // this.$router.go(0)
    },
    deleteEventItem(eventItem) {
      this.delete_dialogEditVisible = true;
      this.delete_item = eventItem;
      console.log(this.$store.state.user_name);
      console.log(eventItem.c_id);
      console.log(this.$store.state.auth);
    },
    // 修改具体年谱的某一条
    editEventIem(eventItem) {
      this.chronicle_id = eventItem.c_id;
      this.eventItemContent = eventItem.content;
      this.tmpEventItemContent = eventItem.content;
      // this.update_date_string = eventItem.c_date;
      this.update_year = eventItem.c_year;
      this.update_month = eventItem.c_month;
      this.new_source = eventItem.c_source;
      this.str = eventItem.c_date.split("月")[1];
      this.str = this.str.split("日")[0];
      this.update_date = this.str;
      this.dialogEditVisible = true;
    },
    //影人编辑
    // makeSure() {
    //   this.make_sure = true;
    // },
    //编辑最终确认
    make_sure_click() {
      this.make_sure = false;
      this.dialogEditVisible = false;
      this.update_date_string =
        this.update_year +
        "年" +
        this.update_month +
        "月" +
        this.update_date +
        "日";
      //逻辑
      service({
        url: "/chronicles/update",
        method: "post",
        data: {
          cid: this.chronicle_id,
          c_content: this.tmpEventItemContent,
          c_date: this.update_date_string,
          c_year: this.update_year,
          c_month: this.update_month,
          c_figure: this.director,
          c_refer: "",
          c_source: this.new_source,
        },
      })
        .then((response) => {
          if (response.data.msg == "Success") {
            this.$message({
              message: "修改成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: "修改失败！",
              type: "warning",
            });
          }
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
  },
  computed: {
    // 替换内容中的关键词为高亮形式;影人\影片实体超链接计算
    replaceArr() {
      // console.log(this.allData);
      const arr = JSON.parse(JSON.stringify(this.allData_by_update));
      const colors = this.colors;
      const keywords = this.movie;
      const keywords2 = this.directors;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp("《" + keywordItem.m_name + "》", "g");
        // 高亮替换v-html值
        // index_movie = this.movie.index(keywordItem)

        const replaceString = `<a  href="/#/video?id=${keywordItem.mid}"><font color=${colors[0]}>《${keywordItem.m_name}》</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].content && arr[index].content.length) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a  href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (
            // arr[index].content.search('color=') === -1 &&
            arr[index].content &&
            arr[index].content.length
          ) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      // console.log(arr[1]);
      return arr;
    },
  },
  mounted() {
    this.get_update_data();
    // console.log(this.director)
  },
};
</script>

<style scoped>
.itemfont {
  color: #a1a3a8;
}
.el-timeline {
  width: 100%;
  padding-inline-start: 0vw;
}
.chronicleList .timeline {
  height: 77vh;
  overflow: scroll;
}

.el-timeline .el-timeline-item {
  padding-bottom: 0vw;
}

.infinite-list .yearformcard {
  height: 8vw;
  overflow: scroll;
}
</style>