<template>
  <div>
    <p class="intro">近年来，国家社科基金艺术学重大项目“中国特色电影知识体系研究”（22ZD10）及国家社科基金艺术学项目“影人年谱与中国电影史研究”（批准号：19BC033）主要阶段性成果（按发表时间倒序）：</p>
    <el-table :data="papers" :key="sort_button" style="width: 90%; margin: 1.5vw auto" :row-style="{ height: '6vw' }">
      <el-table-column align="center" label="编号" prop="id" min-width="8%"></el-table-column>
      <el-table-column align="center" label="类型" prop="type" min-width="8%"></el-table-column>
      <el-table-column align="center" label="题名" prop="value" min-width="30%">
        <!-- <div id="pdfContainer">
          <a @click="get_PDF(scope.row.value, scope.row.author)" style="text-decoration: none; color: #b8860b">
              {{ scope.row.value }}
          </a>
        </div> -->
        <!-- <template slot-scope="scope">
          <a v-if="scope.row.id != 1" :href="`/#/Content_references?id=${scope.row.id}`" target="_self"
             style="text-decoration: none; color: #b8860b">
            {{ scope.row.value }}
          </a>
          <a v-else style="text-decoration: none">{{ scope.row.value }}</a>
        </template> -->
        <template slot-scope="scope">
          <a v-if="scope.row.id != 1" 
            :href="'https://assets.yingshinet.com/chronicles/papers/' + scope.row.value + '_' 
            + scope.row.author + '.pdf'" style="text-decoration: none; color: #b8860b">{{ scope.row.value }}
          </a>
          <a v-else style="text-decoration: none">{{ scope.row.value }}</a>
        </template>
      </el-table-column>
      <el-table-column align="center" label="作者" prop="author" min-width="12%"></el-table-column>
      <el-table-column align="center" label="期刊/出版社" prop="source" min-width="15%"></el-table-column>
      <el-table-column align="center" label="年份" prop="year" min-width="5%"></el-table-column>
      <el-table-column align="center" label="卷（期）" prop="volume_period" min-width="8%"></el-table-column>
      <el-table-column align="center" label="页码" prop="pagecount" min-width="8%"></el-table-column>
    </el-table>
    <el-pagination style="text-align: center; margin: 5vh 0" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pagesize"
      layout="total, prev, pager, next" :total="tableData_length">
    </el-pagination>
  </div>
</template>

<script src="../utils/pdf.js"></script>
<script src="../utils/pdf.worker.js"></script>
<script>
import service from "../utils/request";
import PDFJS from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer';
export default {
  data() {
    return {
      path: "https://api.movie.yingshinet.com/papers",
      papers: [],
      papers_count: 0,
      list: [],
      scope: [],
      sort_button: false,
      // 翻页
      currentPage: 1, // 初始页
      pagesize: 10,   // 每页的数据
      tableData_length: null,  
    };
  },
  watch: {

  },
  methods: {
    searchPapers() {
      this.axios.get(this.path).then((response) => {
        this.papers_count = response.data.p_count;
        this.tableData_length = this.papers_count;
        for (var i = 0; i < this.papers_count; i++) {
          this.papers.push({
            value: response.data.p_data[i].p_title,
            id: response.data.p_data[i].p_id,
            type: response.data.p_data[i].p_type,
            author: response.data.p_data[i].p_author,
            source: response.data.p_data[i].p_source,
            year: response.data.p_data[i].p_year,
            volume_period: response.data.p_data[i].p_volume +
              response.data.p_data[i].p_period != "" && response.data.p_data[i].p_period != null ?
              "(" + response.data.p_data[i].p_period + ")" : "",
            pagecount: response.data.p_data[i].p_pagecount,
            pdf: response.data.p_data[i].p_pdf,
          });
        }
        this.list = this.papers;
        this.papers = this.list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        this.tableData_length = this.list.length;
        this.$set(this.papers);
      })
      .catch((error) => {
        print(error);
      });
    },
    // get_PDF(value, author) {
    //   PDFJS.getDocument({ url: "https://assets.yingshinet.com/chronicles/papers/" + value+"_"+author+".pdf"})
    //   .then((pdf) => {
    //     const container = document.getElementById('pdfContainer');
    //     const viewer = new PDFJS.PDFViewer({
    //       container: container
    //     });
    //     viewer.setDocument(pdf);
    //   });
    // },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.papers = this.list.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
      // 获取查询的结果，把数组长度赋值给分页组件中的total
      this.tableData_length = this.list.length;
      this.$set(this.papers);
    },
  },
  created() {
    this.searchPapers();
  },
};
</script>
<style scoped>
.intro {
   width: 85%;
   margin: 5vh auto;
   font-size: 2.5vh;
   color: gray;
   line-height: 1.8;
}
</style>