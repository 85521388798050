<template >
  <div>
    <div class="block">
      <el-slider v-model="xScaleChange" vertical height="200px" 
        style="margin: 0 auto; position: fixed; margin-top: 15vh;">
      </el-slider> 
    </div>
    <div class="link">
      <span style="margin-left: 7.8vw">数据来自：</span>
      <a style="text-decoration: none; color: inherit" target="_blank" href="https://cinemetrics.uchicago.edu/">Cinemetrics</a>
      <span> & </span>
      <a style="text-decoration: none; color: inherit" target="_blank" href="http://www.starword.com/">Starword</a>
    </div>
    <div class="line-graph">
      <svg id="lineGraph" height="100%"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";

export default {
  data() {
    return {
      path: "",
      work: [],
      tableData: [],
      scope: [],
      num: 0,
      book_search: "",
      dialog: false,
      dialogEditVisible: false,
      //新添加影片参数
      limit_num: 1,
      film_name: "",
      other_film: "",
      trans_name: "",
      film_year: "",
      film_director: "",
      film_country: "",
      film_countryCH: "",
      film_asl: "",
      film_scale: "",
      film_photographer: "",
      film_editor: "",
      film_studio: "",
      image_file: [],
      new_pic_id: "",
      make_sure_add: false,
      //修改影片参数
      pic_id: "",
      id: "",
      now_film_name: "",
      now_film_director: "",
      now_other_film: "",
      now_film_year: "",
      now_trans_name: "",
      now_film_country: "",
      now_film_countryCH: "",
      now_film_asl: "",
      now_film_scale: "",
      now_film_photographer: "",
      now_film_editor: "",
      now_film_studio: "",
      make_sure: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length: "",
      //first search
      first_search: false,
      sort_button: false,
      xScaleChange: 0,
      window_width: window.screen.width,
    };
  },
  watch: {
    xScaleChange(newval, old) {
      // console.log(this.xScaleChange);
      this.initFigureCircle();
    }
  },
  methods: {

    getInfo() {
      const path = "https://api.movie.yingshinet.com/cinemetrics_chi_group";
      this.axios
        .post(path, {divide: 1, page: 1, count: 20})
        .then((res) => {
          this.tableData_length = res.data.count;
          this.work = res.data.response_data;
          this.getData();
          this.initFigureCircle();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getData() {
      for (var i = 0; i < this.tableData_length; i++) {
        this.tableData[i] = {};
        this.tableData[i].name = this.work[i][0];
        this.tableData[i].asl = this.work[i][3];
        this.tableData[i].pic = "https://assets.yingshinet.com/chronicles/cinemetrics/" + this.work[i][2];
        this.$set(this.tableData);
      }
    },

    initFigureCircle() {
      this.drawLollipop()
      this.$nextTick(function() {
        document.querySelectorAll(".img").forEach((item) => {
          item.addEventListener("click", () => {
            let keyword = item.id;
            this.$router.push("/LengthTable?id=" + "name" + keyword);
          });
        });
      });
    },

    drawLollipop() {
      // 滑轮滑动改变xScale
      let bodywidth = document.body.clientWidth;
      let ww = this.window_width > 768 ? this.xScaleChange*2+100 : this.xScaleChange*4+100;
      let width = bodywidth / 100 * ww;
      let leftw = bodywidth / 100 * ww * 0.02;
      let rightw= bodywidth / 100 * ww * 0.92;

      let height = $(".line-graph").height();
      let svg = d3.select("#lineGraph")
                  .attr("width", width)
                  .attr("height", height);
      // 每次调用前删除之前部分
      svg.selectAll("*").remove();

      //提示框部分
		  let tooltip = d3.select("body")
                      .append("div")
                      .attr("class", "tips")
                      .style("opacity", 0.0)
                      .style("position", "absolute")
                      .style("width", "120px")
                      .style("height", "auto")
                      .style("border-style", "solid")
                      .style("border-width", "1px")
                      .style("background-color", "white")
                      .style("border-radius", "5px")
                      .style("font-family", "simsun")
                      .style("font-size", "14px")
                      .style("text-align", "center")
                      .style("line-height", "1.3em");

      let xScale = d3.scaleLinear().range([leftw, rightw]).domain([0, this.tableData_length-1]);                                
      let xAxis = svg.append('g')
                     .attr('class', 'xAxis')
                     .attr('transform', `translate(0, ${height-45})`)
                     .call(d3.axisTop(xScale).ticks(0).tickFormat(x => `${x.toFixed(0)}`));
      let yScale = d3.scaleLinear().range([30, height*0.85]).domain([0, 85]);
      let yAxis = svg.append('g')
                     .attr('class', 'yAxis')
                     .attr('transform', `translate(0, ${height-45})`);
                    //  .call(d3.axisBottom(yScale).ticks(20).tickFormat(x => `${x.toFixed(0)}`))

      let lines = svg.append('g')
                      .attr("class", "lines")
                      .selectAll(".lines")
                      .data(this.tableData)
                      .enter()
                      .append("line")
                      .attr("x1", (d, i) => {
                        return xScale(i);
                      })
                      .attr("y1", height-45)
                      .attr("x2", (d, i) => {
                        return xScale(i);
                      })
                      .attr("y2", (d, i) => {
                        return height-45 - yScale(parseFloat(d.asl));
                      })
                      .attr("stroke", "black")
                      .attr("stroke-width", 0.6)
                      .attr("opacity", 0.8);
                   
      let img_h = this.window_width > 768? 60 : 40;
      let img_w = this.window_width > 768? 60 : 40;
      let radius= this.window_width > 768? 23 : 15;
      let circles = svg.selectAll(".img")
                       .data(this.tableData)
                       .enter()
                       .append("circle")
                       .attr("class", "img")
                       .attr("id", (d) => {
                          // console.log(d.name);
                          return d.name;
                       })
                       .attr("r", radius)
                       .attr("cx", (d, i) => {
                          return xScale(i);
                       })
                       .attr("cy", (d) => {
                          return height-45 - yScale(parseFloat(d.asl));
                       })
                       .style("stroke", "DarkGray")
                       .style("stroke-width", "1.0px")
                       .attr("fill", (d, i) => {
                          //创建圆形图片
                          var defs = svg.append("defs").attr("id", "imgdefs");
                          var catpattern = defs.append("pattern")
                                          .attr("id", "catpattern" + i)
                                          .attr("height", 1)
                                          .attr("width", 1);
                          catpattern.append("image")
                                    .attr("x", - (img_w / 2 - radius+5.8))
                                    .attr("y", - (img_h / 2 - radius+3.5))
                                    .attr("width", img_w+11)
                                    .attr("height", img_h+6)
                                    .attr("xlink:href", d.pic);
                          // console.log(d.pic);
                          return "url(#catpattern" + i + ")";
                       })
                       .on("mouseover", (d, i) => {
                          tooltip.html(i.name + "-" + i.asl)
                                 .style("left", d.pageX + "px")
                                 .style("top", (d.pageY +20)+ "px")
                                 .style("opacity", 1.0);
                          d3.select(this)
                            .style("cursor", "pointer");
                       })
                       .on("mousemove", (d) => {
                          tooltip.style("left", d.pageX + "px")
                                 .style("top", (d.pageY +20) + "px");
                       })
                       .on("mouseout", (d) => {
                          tooltip.style("opacity", 0.0);
                       })
                       .on("click",function(d){
                          tooltip.style("opacity", 0.0);
                       });
    }        
  },
  mounted() {
    this.getInfo();
  },
}
</script>

<style>
.el-slider__bar {
  background-color: #e2a253 !important;
}
.el-slider__button {
  border: 2px solid #e2a253 !important;
}
</style>

<style scoped>
.link {
  margin-top: 2vw;
  font-size: 1.25vw;
}
.line-graph {
  height: 90vh; 
  width: 90vw;
  margin: 0 auto; 
  margin-top: 0vh; 
}

@media screen and (max-width: 768px) {
  .link {
    font-size: 4vw;
  }
}
</style>
