<template>
    <div style="margin-left:5%;margin-right:5%">
        <el-container>
            <el-header style="height:15%">
                <EssaySearch style="width:100%;margin-top:20px" v-on:searchResults="searchResults"></EssaySearch>
                <el-row :gutter="5" style="margin-top:1%;">
                    <!-- <el-row :gutter="5" style="margin-left:5%"> -->
                    <el-col
                        :span="1"
                        style="height:40px;width:80px;padding-left:10px;border-radius: 6px"
                        :style="{ backgroundColor: !year_isSelect ? '#FFC78E' : '' }"
                    >
                        <p class="selectfont" @click="year_fun(-1)">全部年代</p>
                    </el-col>
                    <el-col
                        :span="1"
                        style="height:40px;width:80px;padding-left:10px;border-radius: 6px"
                        :style="{ backgroundColor: year_arr[i] ? '#FFC78E' : '' }"
                        v-for="(data,i) in year"
                        :key="i"
                    >
                        <p class="selectfont" @click="year_fun(i)">{{ data }}</p>
                    </el-col>
                </el-row>
            </el-header>

             <el-pagination
                background
                layout="prev, pager, next"
                :total="article_count"
                :page-size="page_size"
                align="center"
                style="margin-top:20px"
                @current-change="page_change"
                :current-page.sync="currentPage"
            ></el-pagination>
            <el-main>
                <el-container direction="vertical">
                    <!-- <video-search v-on:searchResults="searchResults"></video-search> -->
                    <el-row :gutter="20">
                        <el-col
                            :span="3"
                            v-for="(data,i) in (isSearch ? result : tableData)"
                            :key="i"
                            class="mouse_hover"
                        >
                            <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }">
                                <el-image
                                    :src="data.thumbnail[0]"
                                    class="image"
                                    fit="cover"
                                    @click="convertessay(data)"
                                />
                                <div class="bottom clearfix">
                                    <div
                                        style="font-size: 15px;color: #999;text-align:center"
                                    >{{ data.title.slice(0, 7) }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col
                        v-if="$store.state.login_state"
                            :span="3"
                            class="mouse_hover"
                        >
                            <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }">
                                <el-image
                                    src="/static/pdf_add.png"
                                    class="image"
                                    fit="cover"
                                    @click="addFormVisible = true"
                                />
                                <div class="bottom clearfix">
                                    <div
                                        style="font-size: 15px;color: #999;text-align:center"
                                    >添加作品</div>
                                </div>
                            </el-card>
                            <el-dialog :visible.sync="addFormVisible" width="95%" height="95%" fullscreen>
                                <el-form :model="addform" :rules="addformrules" ref="addform">
                                    <el-col>
                                    <el-row>
                                        <el-col :span="11" style="margin-right:4%">
                                        <!-- 作品基本信息 -->
                                        <el-form-item label="作品名称" prop="title">
                                            <el-input v-model="addform.title" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-row>

                                            <el-col :span="8" style="margin-right: 3%">

                                            <el-form-item label="作品年份">
                                                <el-form-item prop="year">
                                                <el-date-picker
                                                    type="year"
                                                    placeholder="选择年份"
                                                    v-model="addform.year"
                                                    style="width: 100%"
                                                ></el-date-picker>
                                                </el-form-item>
                                            </el-form-item>
                                            </el-col>

                                            <el-col :span="8" style="margin-right: 3%">

                                            <el-form-item label="作品作者">
                                                <el-input v-model="addform.writer" autocomplete="off"></el-input>
                                            </el-form-item>
                                            </el-col>

                                            <el-col :span="4">
                                                <el-form-item label="是否推荐">
                                            <el-switch v-model="addform.label"></el-switch>
                                            </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                        <el-col :span="12" style="margin-right: 3%">
                                            <el-form-item label="作品类型">
                                                <el-input v-model="addform.source" autocomplete="off"></el-input>
                                            </el-form-item>
                                            </el-col>
                                            <el-col :span="11">
                                        <el-form-item label="作品来源">
                                            <el-input v-model="addform.intro" autocomplete="off"></el-input>
                                        </el-form-item>
                                        </el-col >
                                        </el-row>

                                        </el-col>
                                        <!-- 多张作品图片上传 -->
                                        <el-col :span="11" style="margin-right:4%">
                                        <el-upload
                                            class="avatar-uploader"
                                            action
                                            :auto-upload="false"
                                            :on-remove="handleRemove"
                                            :on-change="handleChange"
                                            :on-preview="handlePicPreview"
                                            :file-list="fileList"
                                            list-type="picture-card"
                                            multiple
                                            accept=".jpg, .jpeg, .png"
                                        >
                                            <i class="el-icon-plus avatar-uploader-icon"></i>
                                            <div class="el-upload__tip" slot="tip">只能上传jpg/jpeg/png文件,上传图片大小不能超过3MB!</div>
                                        </el-upload>
                                        <!-- <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                                            <img width="100%" :src="dialogImageUrl" />
                                        </el-dialog> -->
                                        </el-col>
                                    </el-row>
                                    <el-row :span="20">
                                        <el-form-item label="作品内容">
                                        <el-input v-model="addform.content" autocomplete="off" type="textarea" :rows="16"></el-input>
                                        </el-form-item>
                                    </el-row>
                                    </el-col>
                                </el-form>
                                <div slot="footer">
                                    <el-button @click="addFormVisible = false">取 消</el-button>
                                    <el-button type="warning" @click="submitaddForm('addform')">提 交</el-button>
                                </div>
                            </el-dialog>
                        </el-col>
                    </el-row>
                </el-container>
            </el-main>
            
        </el-container>
    </div>
</template>
<script>
import service from "../utils/request";
import EssaySearch from "../components/essay/EssaySearch.vue"
export default {
    components: {
        EssaySearch,
    },
    data() {
        return {
            drawer: false,
            direction: 'btt',
            showData: [],
            year_select: -1,
            year_isSelect: false,
            year: ['2020年代', "2010年代", "2000年代", "90年代", "80年代", "70年代", "60年代", "50年代", "40年代", "30年代"],
            year_arr: [],
            result: [],
            isSearch: false,
            directorurl: {},
            tableData: [],
            article_count: null,
            page_size: 20,
            currentPage: 1,
            select_state: "0",
            year_num: null,
            addFormVisible: false,
            dialogVisible: false,
            fileList: [],
            dialogImageUrl: "",
            addform: {
                title: "",
                year: "",
                content: "",
                intro: "",
                writer: "",
                label: false,
                source: "",
                type:"",
            },
            addformrules: {
                title: [{ required: true, message: "请输入作品名", trigger: "blur" }],
                year: [
                {
                    type: "date",
                    required: true,
                    message: "请选择年份",
                    trigger: "change",
                },
                ],
            },    
        }
    },
    computed:{

    dataShow: function(){
                let start = this.currentPage*this.pageSize;
                let end = Math.min((this.currentPage+1)*this.pageSize, this.tableData.length)
                return this.tableData.slice(start, end)
            },
            pageNum: function(){
                return Math.ceil(this.tableData.length / this.pageSize) || 1 ;
            }
    },
    methods: {
        handlePicPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;        
            },
                handleChange(file) {
      this.fileList.push(file);
      console.log(this.fileList)   
    },
    handleRemove(file) {
      this.fileList.map((item, index) => {
        if (item.uid == file.uid) {
          this.fileList.splice(index, 1);
        }
      });
    },
        label_Is(label){
        if (label){
           return '推荐'
       }
       else{
            return ''
       }
      },
        submitaddForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
            if (this.fileList.length == 0) {
                this.$message.error("需要上传至少一张图片！");
            } else {
                this.addform.year = this.addform.year.getFullYear();

                this.addform.label = this.label_Is(this.addform.label);

                let _this = this;
                service({
                url: "essays/insert",
                method: "post",
                data: _this.addform,
                }).then((response) => {
                if (response.data.msg == "insert success") {
                    this.new_file_id = response.data.id;
                    this.$message({
                    message: "添加成功",
                    type: "success",
                    });
                    if (this.fileList.length) {
                    let pic_param = new FormData(); //form对象
                    for (var i = 0; i < this.fileList.length; i++) {
                        pic_param.append(
                        "file" + i.toString(),
                        this.fileList[i].raw
                        );
                    }
                    let config = {
                        headers: { "Content-Type": "multipart/form-data" },
                    };
                    // this.axios
                    //     .post('https://api.movie.yingshinet.com/essays/file/' + this.new_file_id, pic_param, config)
                    service({
                        url: "essays/file/" + this.new_file_id,
                        method: "post",
                        data: pic_param,
                        config: config,
                    }).then((response) => {
                        if (response.status == "200") {
                        this.$message({
                            message: "添加图片成功！",
                            type: "success",
                        });
                        this.$router.push({
                            path: "/essays",
                            query: {
                            id: this.new_file_id,
                            },
                        });
                        } else {
                        this.$message({
                            message: "添加图片失败",
                            type: "warning",
                        });
                        }
                    });
                    }
                }
                }),
                (this.addFormVisible = false);
            }
            } else {
            this.$message({
                message: "添加失败",
                type: "warning",
            });
            }
        });
        },
        page_change(page) {
            this.currentPage = page
            if (this.select_state == "0") {
                this.axios.post("https://api.movie.yingshinet.com/essays/select?page=" + page + "&offset=" + this.page_size + "&divide_page=1", { writer: this.$store.state.current_figure })
                    .then((res) => {
                        this.work = res.data.e_data;
                        console.log(res.data)
                        this.article_count = res.data.all_item_count
                        this.num = res.data.e_count;
                        this.tableData = []
                        for (var i = 0; i < this.num; i++) {
                            this.tableData[i] = {};
                            this.tableData[i].id = this.work[i].wid;
                            this.tableData[i].title = this.work[i].title;
                            this.tableData[i].year = this.work[i].year;
                            this.tableData[i].thumbnail = this.work[i].thumbnail?this.work[i].thumbnail:[]
                        }
                        if (this.num == 0) {
                            this.tableData[0] = {}
                            this.tableData[i].title = "尚无数据";
                            this.tableData[i].thumbnail = [require("../../public/static/pdf_icon.png")]
                        }
                        this.$set(this.tableData);
                    });
            } else {
                this.axios.post("https://api.movie.yingshinet.com/essays/select?page=" + page + "&offset=" + this.page_size + "&divide_page=1", { writer: this.$store.state.current_figure, year: this.year_num })
                    .then((res) => {
                        console.log(res.data)
                        this.work = res.data.e_data;
                        this.article_count = res.data.all_item_count
                        this.num = res.data.e_count;
                        this.tableData = []
                        for (var i = 0; i < this.num; i++) {
                            this.tableData[i] = {};
                            this.tableData[i].id = this.work[i].wid;
                            this.tableData[i].title = this.work[i].title;
                            this.tableData[i].year = this.work[i].year;
                            this.tableData[i].thumbnail =this.work[i].thumbnail?this.work[i].thumbnail:[]
                        }
                        if (this.num == 0) {
                            this.tableData[0] = {}
                            this.tableData[i].title = "尚无数据";
                            this.tableData[i].thumbnail = [require("../../public/static/pdf_icon.png")]
                        }
                        this.$set(this.tableData);
                    });
            }
        },
        year_fun(num) {
            if (num !== -1) {
                this.year_num = 202 - num
                this.select_state = "1"
                // var arr_index = new Array();

                for (var i = 0; i < this.year_arr.length; i++) {
                    // arr_index.push(i)

                    this.year_arr[i] = false
                }
                this.year_arr[num] = true
                // let status = !this.year_arr[num]
                // this.year_arr.splice(num, 1, status)
                // arr_index.splice(num, 1)
                // for (var i = 0; i < arr_index.length; i++) {
                //     let s = false
                //     this.year_arr.splice(arr_index[i], 1, s)
                // }
                // let index = this.year_arr.indexOf(true);
                // this.year_select = index
                // if (index != -1) {
                //     this.year_isSelect = true;
                //     this.isSearch = true;
                // }
                // else {
                this.year_isSelect = true;
                this.isSearch = false;
                // }

                // var data = []
                // for (var i = 0; i < this.tableData.length; i++) {
                //     if (this.tableData[i].year >= 2020 - (10 * num) && this.tableData[i].year < 2030 - (10 * num)) {
                //         if (!this.type_isSelect) {
                //             data.push(this.tableData[i])
                //         }
                //         else {
                //             if (this.tableData[i].movie_type.indexOf(this.type[this.type_select]) != -1) {
                //                 data.push(this.tableData[i])
                //             }
                //         }
                //         // console.log(this.tableData[i].year)
                //     }
                //     this.isSearch = true;
                //     this.result = data
                // }
                this.page_change(1)
            }
            else {
                for (var i = 0; i < this.year_arr.length; i++) {
                    // arr_index.push(i)

                    this.year_arr[i] = false
                }
                this.select_state = "0"
                this.isSearch = false;
                this.year_isSelect = !this.year_isSelect;
                this.page_change(1)
            }
        },
        convertessay(data) {
            console.log(data)

            this.$router.push({
                path: '/essays',
                query: {
                    id: data.id,
                },
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        searchResults: function (Value) {
            // childValue就是子组件传过来的值
            this.result = Value
            if (this.result.length !== 0) {
                this.isSearch = true;
            }
            else {
                this.$alert('换个关键词试试', '找不到您想搜索的内容', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$message({
                            type: 'info',
                            message: `action: ${action}`
                        });
                    }
                });
            }

        }

    },
    created() {
        for (var i = 0; i < this.year.length; i++) {
            this.year_arr.push(false)
        }
        this.page_change(1) 
        this.axios
            .post("https://api.movie.yingshinet.com/figures/select", { f_name: this.$store.state.current_figure })
            .then((response) => {
                this.directorurl = response.data.f_data[0]
            });
    },
}
</script>
<style scoped>
.selectfont {
    color: gray;
    font-size: 15px;
    margin-top: 10px;
}
.mouse_hover:hover {
    cursor: pointer;
}
.image {
    width: 120px;
    height: 140px;
    display: block;
}
.card {
    width: 120px;
    height: 160px;
    /* margin-top:10px; */
    margin-bottom: 20px;
}
.bottom {
    margin-top: 0px;
    line-height: 12px;
    padding-top: 5px;
    /* padding: 14px; */
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}
.mouse_hover:hover {
    cursor: pointer;
}
</style>