<template>
    <div
      class="homepage"
      style="background-color: aliceblue"
    >
      <el-row style="margin-top: 20px">
        <el-col :span="16" style="display: inline-block">
          <force
            style="display: inline-block; height: 54vh; width: 99.3%"
          ></force>
        </el-col>
        <el-col :span="8" style="display: inline-block">
          <el-row
            ><chronicleRecent
              style="height: 54vh; display: inline-block; width: 100%"
            ></chronicleRecent
          ></el-row>
          <!-- <el-row
            ><tagshow
              style="
                height: calc(22vh - 6px);
                display: inline-block;
                width: 100%;
              "
            ></tagshow
          ></el-row> -->
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" style="display: inline-block">
          <cloud
            style="height: 31vh; display: inline-block; width: 99.3%"
          ></cloud>
        </el-col>
        <el-col :span="8" style="display: inline-block">
          <movieRecent
            style="height: 31vh; display: inline-block; width: 100%"
          ></movieRecent>
        </el-col>
      </el-row>
    </div>

</template>
<script>
import cloud from "../homepage/cloud.vue";
import force from "../homepage/force.vue";
import movieRecent from "../homepage/movieRecent.vue";
import chronicleRecent from "../homepage/chronicleRecent.vue";
import tagshow from "../homepage/tagshow.vue";


export default {
  components: {
    cloud,
    force,
    tagshow,
    chronicleRecent,
    movieRecent,
  },
  data() {
    return {
      work: [],
      tableData: [],
      tableData_length: "",
    };
  },

  methods: {
    getInfo() {
      const path = "https://api.movie.yingshinet.com/homefilmers";
      this.axios
        .post(path, { divide: 1, page: 1, count: 20 })
        .then((res) => {
          this.tableData_length = res.data.count;
          this.work = res.data.response_data;
          this.getData();
          this.initHist();
        })
        .catch((error) => {
          //console.error(error);
        });
    },

    getData() {
      for (var i = 0; i < this.tableData_length; i++) {
        this.tableData[i] = {};
        this.tableData[i].id = i;
        this.tableData[i].country_name = this.work[i][1];
        this.tableData[i].trans_name = this.work[i][0];
        this.tableData[i].asl = this.work[i][2];
        this.tableData[i].film_count = this.work[i][3];
        console.log(this.tableData[i]);
        this.$set(this.tableData);
      }
    },
  },
  mounted() {
    //this.getInfo();
  },
};
</script>

<style scoped>
.body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 86vh;
  margin-top: 0;
}

.container_collapse {
  padding-left: 8%;
  padding-right: 8%;
}
.container_collapse > .bottom-section {
  display: block;
}
.left-section {
  flex: 2;
  /* 设置左侧图片占据更大空间 */
  display: flex;
  flex-direction: row;
  /* 将图片横向排列 */
  justify-content: flex-start;
  /* 保持图片靠左对齐 */
  align-items: center;
  /* 垂直居中图片 */
}
.container_collapse > .bottom-section > .left-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* 横向分布 */
  align-items: center; /* 垂直居中 */
}

.container_collapse > .bottom-section > .left-section .left-section-image {
  width: 100%;
  height: 140px;
  margin-bottom: 2px; /* 图片之间的垂直间距 */
  object-fit: cover;
  filter: grayscale(80%);
}

/* ... 其他样式 ... */
</style>
