<template >
  <div>
    <div class="block">
      <el-slider v-model="xScaleChange" vertical height="200px" 
        style="margin: 0 auto; position: fixed; margin-top: 20vh;">
      </el-slider>
    </div>
    <div class="line-graph">
      <svg id="lineGraph" height="100%"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";

export default {
  name: "PicLine",
  data () {
    return {
      figuredata:[],
      dataset:[],
      num:0,
      xScaleChange:0,
      window_width: window.screen.width,
    };
  },
  watch: {
    xScaleChange(newval, old) {
      this.drawLineGraph();
    }
  },
  methods: {

    getfigure() {
      this.axios
          .get("https://api.movie.yingshinet.com/figures")
          .then((res) => {
            this.figuredata = res.data.f_data;
            for(var i = 0; i < res.data.f_count; i++) {
              if(this.figuredata[i].birth_time && this.figuredata[i].death_time) {
                if(this.figuredata[i].birth_time.length > 4) {
                  this.figuredata[i].birth_time = parseInt(this.figuredata[i].birth_time.slice(0, 4)) + parseFloat(this.figuredata[i].birth_time.slice(5, 7))/12;
                }
                else {
                  this.figuredata[i].birth_time = parseInt(this.figuredata[i].birth_time)
                }
                var data = {
                  name: this.figuredata[i].f_name,
                  birth: this.figuredata[i].birth_time,
                  death: parseInt(this.figuredata[i].death_time),
                  pic: this.figuredata[i].f_pic
                } 
                this.dataset.push(data)
              }
            }
            this.$set(this.dataset);
            this.num = this.dataset.length;
            this.initFigureCircle();
          })
          .catch((error) => {
            console.error(error);
          });
    },

    initFigureCircle() {
      this.drawLineGraph();
      this.$nextTick(() => {
        document.querySelectorAll(".img").forEach((item) => {
          item.addEventListener("click", () => {
            console.log(item);
            let keyword = item.id;
            this.$router.push("/directors?id=" + keyword);
          });
        });
      });
    },

    drawLineGraph() {
      // 滑轮滑动改变xScale
      let bodywidth = document.body.clientWidth;
      let ww = this.window_width > 768 ? this.xScaleChange*2+100 : this.xScaleChange*4+100;
      let width = bodywidth / 100 * ww;
      let leftw = bodywidth / 100 * ww * 0.02;
      let rightw= bodywidth / 100 * ww * 0.92;

      let height = $(".line-graph").height();
      let svg = d3.select("#lineGraph")
                  .attr("width", width)
                  .attr("height", height);
      // 每次调用前删除之前部分
      svg.selectAll("*").remove();

      //提示框部分
		  let tooltip = d3.select("body")
                      .append("div")
                      .attr("class", "tips")
                      .style("opacity", 0.0)
                      .style("position", "absolute")
                      .style("width", "120px")
                      .style("height", "auto")
                      .style("border-style", "solid")
                      .style("border-width", "1px")
                      .style("background-color", "white")
                      .style("border-radius", "5px")
                      .style("font-family", "simsun")
                      .style("font-size", "14px")
                      .style("text-align", "center")
                      .style("line-height", "1.3em");

      let xScale = d3.scaleLinear().range([leftw, rightw]).domain([1850, 1950]);
      let xAxis = svg.append('g')
                     .attr('class', 'xAxis')
                     .attr('transform', `translate(0, ${height-30})`)
                     .call(d3.axisBottom(xScale).ticks(20).tickFormat(x => `${x.toFixed(0)}`));
      if(this.window_width <= 768) {
        xAxis.call(g => {
                g.selectAll("text")
                 .attr("transform", "rotate(-45)")
                 .style("text-anchor", "end");
             });
      }

      let lines = svg.append('g')
                      .attr("class", "lines")
                      .selectAll(".lines")
                      .data(this.dataset)
                      .enter()
                      .append("line")
                      .attr("x1", (d) => {
                        return xScale(d.birth);
                      })
                      .attr("y1", height-30)
                      .attr("x2", (d) => {
                        return xScale(d.birth);
                      })
                      .attr("y2", (d) => {
                        return height-30 - (d.death-d.birth)*4.2;
                      })
                      .attr("stroke", "black")
                      .attr("stroke-width", 0.6)
                      .attr("opacity", 0.8);

      let img_h = this.window_width > 768? 60 : 40;
      let img_w = this.window_width > 768? 60 : 40;
      let radius= this.window_width > 768? 23 : 15;
      let circles = svg.selectAll(".img")
                       .data(this.dataset)
                       .enter()
                       .append("circle")
                       .attr("class","img")
                       .attr("id", (d) => {
                          return d.name;
                       })
                       .attr("r",radius)
                       .attr("cx", (d, i) => {
                          return xScale(d.birth);
                       })
                       .attr("cy", (d) => {
                          return height-30 - (d.death-d.birth)*4.2;
                       })
                       .style("stroke", "DarkGray")
                       .style("stroke-width", "1.0px")
                       .attr("fill", (d, i) => {
                          //创建圆形图片
                          var defs = svg.append("defs").attr("id", "imgdefs");
                          var catpattern = defs.append("pattern")
                                          .attr("id", "catpattern" + i)
                                          .attr("height", 1)
                                          .attr("width", 1);
                          catpattern.append("image")
                                    .attr("x", - (img_w / 2 - radius + 5.8))
                                    .attr("y", - (img_h / 2 - radius + 3.5))
                                    .attr("width", img_w + 11)
                                    .attr("height", img_h + 6)
                                    .attr("xlink:href", d.pic);
                          return "url(#catpattern" + i + ")";
                       })
                       .on("mouseover", (d,i) => {
                          tooltip.html(i.name + "-" + Math.ceil(i.death-i.birth+1) + "岁" + "</br>" + "生于：" + (Math.floor(i.birth)))
                                 .style("left", d.pageX + "px")
                                 .style("top", (d.pageY + 20)+ "px")
                                 .style("opacity", 1.0);
                          d3.select(this)
                            .style("cursor", "pointer");
                       })
                       .on("mousemove", (d) => {
                          tooltip.style("left", d.pageX + "px")
                                 .style("top", (d.pageY + 20) + "px");
                       })
                       .on("mouseout", (d) => {
                          tooltip.style("opacity", 0.0);
                       })
                       .on("click", (d) => {
                          tooltip.style("opacity", 0.0);
                       });
    } 
  },
  mounted() {
    this.getfigure();
  },
};
</script>

<style>
.el-slider__bar {
  background-color: #e2a253 !important;
}
.el-slider__button {
  border: 2px solid #e2a253 !important;
}
</style>

<style scoped>
.line-graph {
  height: 90vh; 
  width: 90vw;
  margin: 0 auto; 
  margin-top: 0vh; 
}
.el-row {
    margin-bottom: -50px;
    &:last-child {
      margin-bottom: 0;
    }
}
</style>
