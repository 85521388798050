<template>
  <div>
  </div>
</template>

<script>
export default {
  created() {
    const pdfURL = 'https://assets.yingshinet.com/chronicles/GUIDE.pdf';

    // 创建一个链接并自动触发点击事件，以在当前页面中打开PDF文件
    const link = document.createElement('a');
    link.href = pdfURL;
    link.target = '_self'; // 在当前页面中打开链接
    link.download = 'GUIDE.pdf'; // 设置要下载的文件名
    link.click();
  }
}
</script>

