<template>
  <div>
    <span v-if="replaceArr.length != 0" style="margin-top: -2% ;margin-left: 2% ;position:absolute;font-size: 20px; color:gray;text-align: left;line-height:30px;font-weight:bold;">电影年谱：</span>
    <el-scrollbar style="margin-top: 50%;">
        <div class="box">
          <!-- 时间轴列表 -->
          <el-timeline style="width: 97.5vw">
            <!-- 时间列表的每一项 -->
            <el-timeline-item v-for="(eventItem, index) in replaceArr" :key="eventItem.cid">
              <el-card width="90vw !important">
                <div>
                  <span class="itemdata" v-html="allData[index].c_date + '，' + eventItem.content"></span>
                  <span class="itemfont">
                    --创建人:{{ allData[index].c_create_man }}&nbsp;--来源:{{ allData[index].c_source }}
                  </span>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-scrollbar>
  </div>
</template>

<script>
import service from "../../utils/request";
import Chronicle from "../../views/Chronicle.vue";
import Chronicle_barchart from "./chronicle_barchart.vue";
import ChronicleUpdate from "./chronicle_update.vue";
import ChronicleUser from "./chronicle_user.vue";
export default {
  components: {
    Chronicle,
    Chronicle_barchart,
    ChronicleUpdate,
    ChronicleUser,
  },
  name: "chronicle_list",
  inject: ["reload"],
  props:['msg'],
  data() {
    return {
      // 编辑模版
      //遮罩层是否显示
      delete_dialogEditVisible: false,
      dialogEditVisible: false,
      make_sure: false,
      colors: ["#b8860b", "#778899"],
      directors: [
        //40位影人名单
      ],
      dirctors_pic: [
        //40位影人照片
      ],
      allData: [], //所有年谱数据，数组['','','',...]
      allData_by_update: [], //用户修改数据
      userData: [],
      keywordSearch: this.msg,
      MonthSearch: "",
      YearSearch: "",
      path: "https://api.movie.yingshinet.com/chronicles",
      currentFigureImage: "",
      path_currency: "https://api.movie.yingshinet.com",
      dialog: false,
      loading: false,
      edit_event_item: "",
      eventItemContent: "",
      tmpEventItemContent: "",
      movie: [],
      image_file: [],
      othermovie: [],
      otheritemLength: 0,
      itemLength: 0,
      updatedata_length: 0,
      imgsrc: "",
      userdata_length: 0,
      formLabelWidth: "50px",
      date_value: new Date(1937, 0, 1),
      date_value_two: "",
      event_content: "",
      event_source: "",
      updata_date_value: "",
      isshowimage: false,
      chronicle_id: "",
      update_date_string: "",
      date_value_three: "",
      update_year: "",
      update_month: "",
      update_date: "",
      update_source: "",
      tmpupdate_source: "",
      director_select: "",
      director: "",
      tmpdirector: "", //添加事件时产生bug,矫正vuex中的director
      c_year: "",
      c_month: "",
      c_date: "",
      str: "",
      limit_num: 1,
      new_source: "",
      delete_item: "",
      //timeline order
      activeOrder: "timeorder",
      tab_pane_order: {
        timeorder: true,
        updateorder: false,
        userupdateorder: false,
      },
    };
  },
  watch: {
    msg() {
      this.keywordSearch = this.msg;
      console.log(this.keywordSearch)
    },
    "$store.state.keywordSearch": {
      handler(val, oldVal) {
        this.keywordSearch = val;
      },
    },
    keywordSearch: {
      handler(val, oldVal) {
        this.searchEvents();
      },
    },
  },
  methods: {
    showImage(pic) {
      this.isshowimage = true;
      this.imgsrc = pic;
    },
    //按年份和月份时间顺序排序
    sortYear(obj) {
      return obj.sort(function (a, b) {
        let x = a["c_year"];
        let y = b["c_year"];
        if (x < y) return -1;
        else if (x > y) return 1;
        else {
          let c = a["c_month"];
          let d = b["c_month"];
          return c < d ? -1 : c > d ? 1 : 0;
        }
      });
    },
    getEvents() {
      console.log("aaaaa"+this.keywordSearch);
      const keyword = this.keywordSearch;
      const keyJson = {};
      if (keyword) {
        keyJson.searchData = keyword;
      }
      keyJson.figure = null;
      this.axios.post(this.path + "/keyword", keyJson).then((response) => {
        this.movies = response.data.searchShow;
        //年谱数据movies.c_content
        this.itemLength = this.movies.length;
        this.allData = [];
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[i].c_content;
          this.allData[i].c_year = this.movies[i].c_year;
          this.allData[i].c_month = this.movies[i].c_month;
          //this.allData[i].c_figure_origin = this.movies[i].c_figure;
          this.allData[i].c_id = this.movies[i].cid;
          if (this.movies[i].c_pic != null) {
            this.allData[i].c_pic =
              "https://assets.yingshinet.com/chronicles/pictures/" +
              this.movies[i].c_pic;
          }
          if (this.movies[i].c_month == 0) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 4
            );
          } else if (
            (this.movies[i].c_date.substr(-2, 1) == 0) &
            isNaN(Number(this.movies[i].c_date.substr(-3, 1), 10))
          ) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 2
            );
          } else {
            this.allData[i].c_date = this.movies[i].c_date;
          }
          this.allData[i].c_create_man = this.movies[i].c_create_man;
          this.allData[i].c_source = this.movies[i].c_source;
          this.allData[i].c_update_man = this.movies[i].c_update_man;
          this.allData[i].c_update_time = this.movies[i].c_update_time;
        }
        this.allData = this.sortYear(this.allData);
      });
      this.$set(this.allData);
    },
    searchItem() {
      // console.log("searchItem");
      console.log(this.keywordSearch)
      // const keyword = this.director_search.trim();
      const keyword = "《"+this.keywordSearch+"》";
      const month = this.MonthSearch.trim();
      const year = this.YearSearch.trim();

      const keyJson = {
        // searchData: "山东",
        // year: 1937,
        // figure: "费穆",
      };
      if (keyword) {
        keyJson.searchData = keyword;
      }
      if (month) {
        keyJson.month = month;
      }
      if (year) {
        keyJson.year = year;
      }
      keyJson.figure = null;
      this.axios;
      this.axios.post(this.path + "/keyword", keyJson).then((response) => {
        this.movies = response.data.searchShow;
        //年谱数据movies.c_content
        this.itemLength = this.movies.length;
        this.allData = [];
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[i].c_content;
          //this.allData[i].c_figure_origin = this.movies[i].c_figure;
          this.allData[i].c_year = this.movies[i].c_year;
          this.allData[i].c_month = this.movies[i].c_month;
          this.allData[i].c_id = this.movies[i].cid;
          if (this.movies[i].c_pic != null) {
            this.allData[i].c_pic =
              "https://assets.yingshinet.com/chronicles/pictures/" +
              this.movies[i].c_pic;
          }
          // 去除0月/0日
          if (this.movies[i].c_month == 0) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 4
            );
          } else if (
            (this.movies[i].c_date.substr(-2, 1) == 0) &
            isNaN(Number(this.movies[i].c_date.substr(-3, 1), 10))
          ) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 2
            );
          } else {
            this.allData[i].c_date = this.movies[i].c_date;
          }
          this.allData[i].c_create_man = this.movies[i].c_create_man;
          this.allData[i].c_source = this.movies[i].c_source;
          this.allData[i].c_update_man = this.movies[i].c_update_man;
          this.allData[i].c_update_time = this.movies[i].c_update_time;
        }
        this.allData = this.sortYear(this.allData);
      });
      this.$set(this.allData);
    },
    debounce(fn, wait = 1000) {
      let timer;
      return function () {
        let context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context);
        }, wait);
      };
    },

    searchEvents() {
      let keyword = this.keywordSearch;
      this.$store.commit("setkeywordSearch", keyword);
      this.debounce(this.searchItem, 300)();
    },
  },
  computed: {
    // 替换内容中的关键词为高亮形式;影人\影片实体超链接计算
    replaceArr() {
      // console.log(this.allData);
      const arr = JSON.parse(JSON.stringify(this.allData));
      const colors = this.colors;
      const keywords = this.movie;
      const keywords2 = this.directors;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp("《" + keywordItem.m_name + "》", "g");
        // 高亮替换v-html值
        // index_movie = this.movie.index(keywordItem)

        const replaceString = `<a  href="/#/video?id=${keywordItem.mid}"><font color=${colors[0]}>《${keywordItem.m_name}》</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].content && arr[index].content.length) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a  href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (
            // arr[index].content.search('color=') === -1 &&
            arr[index].content &&
            arr[index].content.length
          ) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      return arr;
    },
  },
  created() {
    this.director = this.$store.state.current_figure;

    // 获取影片名
    this.axios.get(this.path_currency + "/movies").then((response) => {
      this.movie = [];
      for (var i = 0; i < response.data.m_data.length; i++) {
        const movie = {};
        movie.m_name = response.data.m_data[i].m_name;
        movie.mid = response.data.m_data[i].mid;
        this.movie.push(movie);
      }
    });
    //获取人物名
    this.axios.get(this.path_currency + "/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i].f_name);
        this.dirctors_pic.push(response.data.f_data[i].f_pic);
      }
    });
    this.axios
      .post(this.path_currency + "/figures/select", { f_name: this.director })
      .then((response) => {
        this.currentFigureImage = response.data.f_data[0].f_pic;
      });
  },
  mounted() {
    this.currentFigureImage = this.$store.state.current_figure_pic;
    this.director_select = this.director;
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 1.1vw;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.chronicle_e_input {
  border-radius: 0.3vw;
  border: 0.07vw solid #b8860b;
  color: #606266;
  height: 5vw;
  line-height: 5vw;
  padding-left: 0.3vw;
  outline: none;
}

.el-col .chronicleList > .el-card {
  margin: 0.5vw;
}

.chronicleList .listSearch {
  width: 31vw;
  position: static;
  margin: 1vw 0 0.8vw 0;
  text-align: center;
  line-height: 1vw;
}

.listSearch .el-card__body {
  padding: 0.2vw;
}

.chronicleList-mobile .listSearch-mobile{
  position: static;
  width: 100%;
  margin: 1vw 0 0.8vw 0;
  text-align: center;
  line-height: 1vw;
}

.listSearch-mobile .el-card__body {
  padding: 0.2vw;
}

.el-timeline {
  width: 100%;
  padding-inline-start: 0vw;
}

.chronicleList .timeline {
  width: auto;
  height: 77vh;
  overflow: scroll;
}

.el-timeline .el-timeline-item {
  padding-bottom: 0vw;
}

.infinite-list .yearformcard {
  height: 8vw;
  overflow: scroll;
}

.yearformcard .el-card__body {
  padding: 0.8vw;
}

.yearformcard p {
  margin: 0vw;
}

.mouse_over_head:hover {
  color: #b8860b;
  cursor: pointer;
}

.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}

/* 创建人颜色 */
.itemfont {
  color: #a1a3a8;
}

/* 搜索框 */
.keywordSearchInput {
  width: 60%;
  margin-left: 1vw;
}

.keywordSearchInput #search {
  padding: 12px 20px;
  background-color: #ffc78e;
  border-radius: 0%;
}

.keywordSearchInput #search-box {
  width: 13.9%;
  /* height: 10%; */
  margin-top: 0px;
  margin-left: 65.4%;
  padding-bottom: 20px;
}

.director_select {
  display: inline-block;
  width: 6vw;
  height: 6vh;
  margin-left: 2vw;
}

.director_select-mobile {
  display: inline-block;
  width: 20vw;
  height: 6vh;
  margin-left: 10vw;
  margin-top: 3vw;
}
</style>
<style>
.chronicleList {
  height: 83vh; 
  width: 28vw;
  position: relative;
  margin-left: 0vw;
}

.chronicleList .el-card__body {
  padding: 0.5vw;
}

.chronicleList .el-timeline-item__tail,
.el-timeline-item__node {
  margin-left: 0.5vw;
}

.chronicleList .el-timeline-item__wrapper {
  padding-left: 1.3vw;
}

.chronicleList a {
  text-decoration: none;
}

.chronicleList-mobile {
  height: auto; 
  width: 99vw;
  position: relative;
  margin-left: 0vw;
}

.chronicleList-mobile .el-card__body {
  padding: 0.5vw;
}

.chronicleList-mobile .el-timeline-item__tail,
.el-timeline-item__node {
  margin-left: 0.5vw;
}

.chronicleList-mobile .el-timeline-item__wrapper {
  padding-left: 1.3vw;
}

.chronicleList-mobile a {
  text-decoration: none;
}

.director_img {
  width: 3vw !important;
  height: 6vh !important;
  left: 1vw !important;
  top: 0.8vh;
  position: absolute;
  border-radius: 50%;
}

.director_img-mobile {
  width: 12vw !important;
  height: 6vh !important;
  left: 1vw !important;
  top: 0.8vh;
  position: absolute;
  border-radius: 50%;
}
.box {
  height: 60vh;
}

.itemdata {
  font-size: 1vw;
  line-height: 1.5vw;
}
.itemfont {
  font-size: 1vw;
  line-height: 1.5vw;
}
</style>