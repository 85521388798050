<template>
  <div v-if="essayurl" style="margin-left:8%;margin-right:8%">
      <div>
        <el-row :gutter="7">
          <el-card style="height:32vh;background-color: #b8870b10" >
          <el-col :span="6">
            <div v-if="this.essayurl.file_dir">
            <div>

              <el-image
                style="width: 200px; height: 230px; margin-top: 0%;margin-bottom: 1%"
                :src="this.essayurl.file_dir[nowpage - 1]"
                :preview-src-list="this.essayurl.file_dir"
              >
              </el-image>
            </div>
          </div>
          </el-col>
          <el-col :span="14">
            <span style="height: auto; font-size: 25px; color:gray;text-align: left;line-height:30px;font-weight:bold;" v-html="replaceArr.title"></span><br/>
            <span class="content_key">年份：</span>
            <span class="content_value">{{ replaceArr.year }}</span><br/>
            <span class="content_key">来源：</span>
            <span class="content_value">{{ replaceArr.intro }}</span><br/>
            <span class="content_key">作者：</span>
            <span class="content_value" v-html="replaceArr.writer"></span><br/>
            <span class="content_key">类型：</span>
            <span class="content_value">{{ replaceArr.source }}</span><br/>
            <span class="content_key">页数：</span>
            <span class="content_value" v-if="replaceArr.file_dir">{{ replaceArr.file_dir.length }}页</span><br/>
            <span v-if="$store.state.login_state" style="margin-left: 10px;margin-left: 3px" >
                <!-- 登陆之后显示增删查改以及下载按钮 -->
                <el-button size="mini" type="warning" @click="jumptonewtab()">下载图片</el-button>
                <!-- 增加作品功能 -->
                <el-button size="mini" type="warning" plain @click="addFormVisible = true">增加</el-button>
                <el-dialog :visible.sync="addFormVisible" width="95%" height="95%" fullscreen>
                  <el-form :model="addform" :rules="addformrules" ref="addform">
                    <el-col>
                      <el-row>
                        <el-col :span="11" style="margin-right:4%">
                          <!-- 作品基本信息 -->
                          <el-form-item label="作品名称" prop="title">
                            <el-input v-model="addform.title" autocomplete="off"></el-input>
                          </el-form-item>
                          <el-row>

                            <el-col :span="8" style="margin-right: 3%">

                              <el-form-item label="作品年份">
                                <el-form-item prop="year">
                                  <el-date-picker
                                    type="year"
                                    placeholder="选择年份"
                                    v-model="addform.year"
                                    style="width: 100%"
                                  ></el-date-picker>
                                </el-form-item>
                              </el-form-item>
                            </el-col>

                            <el-col :span="8" style="margin-right: 3%">

                              <el-form-item label="作品作者">
                                <el-input v-model="addform.writer" autocomplete="off"></el-input>
                              </el-form-item>
                            </el-col>

                            <el-col :span="4">
                                <el-form-item label="是否推荐">
                              <el-switch v-model="addform.label"></el-switch>
                            </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                          <el-col :span="12" style="margin-right: 3%">
                              <el-form-item label="作品类型">
                                <el-input v-model="addform.source" autocomplete="off"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="11">
                          <el-form-item label="作品来源">
                            <el-input v-model="addform.intro" autocomplete="off"></el-input>
                          </el-form-item>
                          </el-col >
                          </el-row>

                        </el-col>
                        <!-- 多张作品图片上传 -->
                        <el-col :span="11" style="margin-right:4%">
                          <el-upload
                            class="avatar-uploader"
                            action
                            :auto-upload="false"
                            :on-preview="handlePicPreview"
                            :on-remove="handleRemove"
                            :on-change="handleChange"
                            :file-list="fileList"
                            list-type="picture-card"
                            multiple
                            accept=".jpg, .jpeg, .png"
                          >
                            <i class="el-icon-plus avatar-uploader-icon"></i>
                            <div class="el-upload__tip" slot="tip">只能上传jpg/jpeg/png文件,上传图片大小不能超过3MB!</div>
                          </el-upload>
                          <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                            <img width="100%" :src="dialogImageUrl" />
                          </el-dialog>
                        </el-col>
                      </el-row>
                      <el-row :span="20">
                        <el-form-item label="作品内容">
                          <el-input v-model="addform.content" autocomplete="off" type="textarea" :rows="16"></el-input>
                        </el-form-item>
                      </el-row>
                    </el-col>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="addFormVisible = false">取 消</el-button>
                    <el-button type="warning" @click="submitaddForm('addform')">提 交</el-button>
                  </div>
                </el-dialog>
                <!-- 删除功能，逻辑删 -->
                <el-button size="mini" type="warning" plain @click="deleteVisible = true">删 除</el-button>
                <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
                  <span>该条记录确定删除吗?</span>
                  <span slot="footer">
                    <el-button @click="deleteVisible = false">取 消</el-button>
                    <el-button type="warning" @click="submitdelete()">确 定</el-button>
                  </span>
                </el-dialog>
                <!-- 修改功能 -->
                <el-button size="mini" type="warning" plain @click="showUpdateForm()">修改</el-button>
                <br />
                <el-dialog :visible.sync="updateFormVisible" width="95%" height="95%" fullscreen>
                  <el-form :model="updateform" :rules="updateformrules" ref="updateform">
                    <el-row>
                      <el-col :span="15">
                        <div v-if="this.essayurl.file_dir">
                        <span style="color: #886600">页数：{{ nowpage }}/{{ this.essayurl.file_dir.length }}</span>
                        <el-button size="mini" circle icon="el-icon-arrow-left" @click="pagedown()"></el-button>
                        <el-button size="mini" circle icon="el-icon-arrow-right" @click="pageup()"></el-button>
                        <el-image
                          style="
                            width: 90%;
                            height: 100vh;
                            margin-top: 10px;
                            margin-left: 10px;
                          "
                          :src="this.essayurl.file_dir[nowpage - 1]"
                          :preview-src-list="this.essayurl.file_dir"
                        >
                        </el-image>
                        </div>
                      </el-col>
                      <el-col :span="9">
                        <el-form-item label="作品名称" prop="title">
                          <el-input v-model="updateform.title" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="作品年份">
                          <el-form-item prop="year">
                            <el-date-picker
                              type="year"
                              placeholder="选择年份"
                              v-model="updateform.year"
                              style="width: 100%"
                            ></el-date-picker>
                          </el-form-item>
                        </el-form-item>
                        <!-- <el-form-item label="作品标签">
                        <el-input v-model="updateform.label" autocomplete="off"></el-input>
                        </el-form-item>-->
                        <el-form-item label="作品来源">
                          <el-input v-model="updateform.intro" autocomplete="off"></el-input>
                        </el-form-item>

                        <el-form-item label="作品类型">
                          <el-input v-model="updateform.source" autocomplete="off"></el-input>
                        </el-form-item>

                        <el-form-item label="作品作者">
                          <el-input v-model="updateform.writer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="作品内容">
                          <el-input
                            type="textarea"
                            :rows="16"
                            v-model="updateform.content"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="updateFormVisible = false">取 消</el-button>
                    <el-button type="warning" @click="submitupdateForm('updateform')">修 改</el-button>
                  </div>
                </el-dialog>
              </span>
          </el-col>
          <el-col :span="4">
                <el-row :gutter="2">
                  <img style="height:80px; width:60px;border-radius:60%;" :src="directorurl.f_pic"/>
                </el-row>
                <el-row :gutter="5">
                  <span style="height: auto; font-size: 8px; color:gray;text-align: left;line-height:10px;">{{directorurl.f_name}}-{{directorurl.f_brief}}</span>
                </el-row>
                <el-row :gutter="2">
                <span style="height: auto; font-size: 6px; color:gray;text-align: left;line-height:10px;font-weight:bold;">创建人：</span>
                <span style="height: auto; font-size: 6px; color:gray;text-align: left;line-height:10px;">{{ replaceArr.create_man }}</span><br/>
                </el-row> 
            </el-col>
          </el-card>
        </el-row>
        <el-row :gutter="6">
          <el-card style="height:68vh ;overflow-y:scroll;background-color: #b8870b10;margin-top:15px">
            <span class="content_key">内容：</span>
            <span class="content_value" v-html="replaceArr.content"></span><br/>
          </el-card>

        </el-row>
      </div>
    <br />
    <br />
  </div>
</template>

<script>
import service from "../../utils/request";
export default {
  name: 'EssaysContent',
  data() {
    return {
      colors: ["#b8860b", "#778899"],
      directors: [
        //40位影人名单
      ],
      movie: [],
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "",
      nowpage: 1,
      addFormVisible: false,
      addform: {
        title: "",
        year: "",
        content: "",
        intro: "",
        writer: "",

        label: false,

        source: "",
        type:"",
      },
      addformrules: {
        title: [{ required: true, message: "请输入作品名", trigger: "blur" }],
        year: [
          {
            type: "date",
            required: true,
            message: "请选择年份",
            trigger: "change",
          },
        ],
      },
      updateFormVisible: false,
      updateform: {},
      updateformrules: {
        title: [{ required: true, message: "请输入作品名", trigger: "blur" }],
        year: [
          {
            type: "date",
            required: true,
            message: "请选择年份",
            trigger: "change",
          },
        ],
      },
      deleteVisible: false,
      limit_num: 20,
    }
  },
  props: {
    essayurl: Object,
    directorurl: Object

  },
  computed: {
    // 替换内容中的关键词为高亮形式;影人\影片实体超链接计算
    replaceArr() {
      const arr = JSON.parse(JSON.stringify(this.essayurl));

      const colors = this.colors;
      const keywords = this.directors;
      const keywords2 = this.movie; 
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a style="text-decoration:none" href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        //替换
        if (arr.title) {
          arr.title = arr.title.replace(replaceReg, replaceString)
        }
        if (arr.writer){
          arr.writer = arr.writer.replace(replaceReg, replaceString)
        }
        if (arr.content) {
          arr.content = arr.content.replace(replaceReg, replaceString)
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp('“'+keywordItem+'”', "g");
        // 高亮替换v-html值
        const replaceString = `<a style="text-decoration:none" href="/#/video?id=${keywordIndex + 1
          }"><font color=${colors[0]}>“${keywordItem}”</font></a>`;
        // 替换
        if (arr.title) {
          arr.title = arr.title.replace(replaceReg, replaceString)
        }
        if (arr.writer){
          arr.writer = arr.writer.replace(replaceReg, replaceString)
        }
        if (arr.content) {
          arr.content = arr.content.replace(replaceReg, replaceString)
        }
      });

      return arr;
    },

  },
  methods: {
    submitaddForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.fileList.length == 0) {
            this.$message.error("需要上传至少一张图片！");
          } else {
            this.addform.year = this.addform.year.getFullYear();

            this.addform.label = this.label_Is(this.addform.label);

            let _this = this;
            service({
              url: "essays/insert",
              method: "post",
              data: _this.addform,
            }).then((response) => {
              if (response.data.msg == "insert success") {
                this.new_file_id = response.data.id;
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                if (this.fileList.length) {
                  let pic_param = new FormData(); //form对象
                  for (var i = 0; i < this.fileList.length; i++) {
                    pic_param.append(
                      "file" + i.toString(),
                      this.fileList[i].raw
                    );
                  }
                  let config = {
                    headers: { "Content-Type": "multipart/form-data" },
                  };
                  // this.axios
                  //     .post('https://api.movie.yingshinet.com/essays/file/' + this.new_file_id, pic_param, config)
                  service({
                    url: "essays/file/" + this.new_file_id,
                    method: "post",
                    data: pic_param,
                    config: config,
                  }).then((response) => {
                    if (response.status == "200") {
                      this.$message({
                        message: "添加图片成功！",
                        type: "success",
                      });
                      this.$router.push({
                        path: "/essays",
                        query: {
                          id: this.new_file_id,
                        },
                      });
                    } else {
                      this.$message({
                        message: "添加图片失败",
                        type: "warning",
                      });
                    }
                  });
                }
              }
            }),
              (this.addFormVisible = false);
          }
        } else {
          this.$message({
            message: "添加失败",
            type: "warning",
          });
        }
      });
    },
    showUpdateForm() {
      this.updateform = {
        content: this.essayurl.content,
        title: this.essayurl.title,
        year: new Date(this.essayurl.year, 1, 1),
        writer: this.essayurl.writer,
        intro: this.essayurl.intro,
        label: this.essayurl.label,
        source: this.essayurl.source,
        thumbnail: this.essayurl.thumbnail,
        file_dir: this.essayurl.file_dir,
      };
      this.updateFormVisible = true;
    },
    submitupdateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateform.year = this.updateform.year.getFullYear();
          var updatedic = {
            id: this.essayurl.wid,
            now_content: this.updateform.content,
            now_title: this.updateform.title,
            now_year: this.updateform.year,
            now_writer: this.updateform.writer,
            now_intro: this.updateform.intro,
            now_label: this.updateform.label,
            now_source: this.updateform.source,
          };
          service({
            url: "essays/update",
            method: "post",
            data: updatedic,
          }).then((response) => {
            this.$message({
              message: "修改成功！",
              type: "success",
            });
            this.$router.push({
              path: "/essays",
              query: {
                id: updatedic.id,
              },
            });
            location.reload();
          });
          this.updateFormVisible = false;
        } else {
          this.$message({
            message: "修改失败",
            type: "warning",
          });
        }
      });
    },

      label_Is(label){
        if (label){
           return '推荐'
       }
       else{
            return ''
       }
      },

    handleChange(file) {
      this.fileList.push(file);
      console.log(this.fileList)   
    },
    handlePicPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;        
    },
    handleRemove(file) {
      this.fileList.map((item, index) => {
        if (item.uid == file.uid) {
          this.fileList.splice(index, 1);
        }
      });
    },
    submitdelete() {
      this.deleteVisible = false;
      let _this = this;
      const content = {
        id: _this.essayurl.wid,
      };
      this.axios
        .post("https://api.movie.yingshinet.com/essays/delete", content)
        .then((response) => {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.$router.push({
            path: "/essays",
            query: {
              id: 1,
            },
          });
        });
    },
    // 翻页函数
    pageup() {
      if (this.nowpage < this.essayurl.file_dir.length) {
        this.nowpage++;
      }
    },
    pagedown() {
      if (this.nowpage > 1) {
        this.nowpage--;
      }
    },
    jumptonewtab() {
      window.location.href = this.essayurl.file_dir;
    },
  },
  created() {
    // 获取影片名
    this.axios.get("https://api.movie.yingshinet.com/movies").then((response) => {
      for (var i = 0; i < response.data.m_data.length; i++) {
        this.movie.push(response.data.m_data[i].m_name);
      }
    });
    //获取人物名
    this.axios.get("https://api.movie.yingshinet.com/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i].f_name);
      }
    });
  },

}
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.content_key{
  height: auto;
  font-size: 15px; 
  color:gray;
  text-align: left;
  line-height:30px;
  font-weight:bold;
}
.content_value{
  height: auto; 
  font-size: 15px; 
  color:gray;
  text-align: left;
  line-height:30px
}
</style>