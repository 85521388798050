<template>
  <div>
    <el-table :data="tableData" style="width: 90%; margin: 20px auto" :row-style="{ height: '80px' }">
      <el-table-column align="center" label="编号" prop="no" min-width="6%"></el-table-column>
      <el-table-column align="center" label="标题" prop="title" min-width="15%"></el-table-column>
      <el-table-column align="center" label="内容" prop="content" min-width="40%"></el-table-column>
      <el-table-column align="center" label="添加人" prop="create_man" min-width="7%"></el-table-column>
      <el-table-column align="center" label="添加时间" prop="create_time" min-width="7%"></el-table-column>
      <el-table-column align="center" label="备注" prop="comments" min-width="7%"></el-table-column>
      <el-table-column align="center" label="图片" min-width="10%">
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <el-image v-if="scope.row.log_pic != null" style="height: 75px; border-radius: 8px; width: 40px"
            :src="`${scope.row.log_pic}?${scope.row.update_time}`"></el-image>
          <div v-if="scope.row.log_pic == null">暂无图片</div>
        </template>
      </el-table-column>

      <el-table-column align="center" min-width="10%">
        <template slot-scope="scope">
          <el-button size="mini" v-if="$store.state.login_state" type="warning"
            @click="handleEdit(scope.$index, scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="text-align: center; margin: 5vh 0" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pagesize"
      layout="total, prev, pager, next" :total="tableData_length"></el-pagination>

    <!-- 添加按钮 -->
    <span class="elebutton">
      <el-button style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa" class="el-backtop" icon="el-icon-plus"
        @click="dialog = true" circle v-if="this.$store.state.login_state"></el-button>
    </span>
    <!-- 新添加日志页面 -->
    <el-dialog class="el-dia" title="请添加日志信息：" :visible.sync="dialog" width="100%" height="100%" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure_add" width="100%" append-to-body>
        <span>确定要添加日志？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick">确 定</el-button>
        </span>
      </el-dialog>
      <el-row>
        <el-col width="100px" style="margin-top: 30px">
          <el-upload class="upload-demo" ref="upload" list-type="picture" action :limit="limit_num"
            :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
            style="margin-left: 100px">
            <el-button slot="trigger" size="medium" type="warning">点击上传日志图片</el-button>
            <div slot="tip" class="el-upload__tip">
              支持上传jpg/jpeg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-col>
        <el-col width="100px" style="margin-top: 30px">
          <div>
            <el-button type="warning" @click="add_logs()" style="margin-left: 100px">确 定</el-button>
            <el-button @click="dialog = false">取 消</el-button>
          </div>
        </el-col>
      </el-row>
      <el-form label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="标题" width="100px" style="margin-top: 30px">
              <el-input v-model="title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="内容" width="100px" style="margin-top: 30px">
              <el-input type="textarea" :rows='8' v-model="content" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="备注" width="100px" style="margin-top: 30px">
              <el-input v-model="comments" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 修改日志页面 -->
    <el-dialog class="el-dia" title="日志编辑" :visible.sync="dialogEditVisible" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
        <span>确定要修改日志？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
      <el-row>
        <el-col width="100px" style="margin-top: 30px">
          <el-upload class="upload-demo" ref="upload" list-type="picture" action :limit="limit_num"
            :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
            style="margin-left: 100px">
            <el-button slot="trigger" size="medium" type="warning">点击上传日志图片</el-button>
            <div slot="tip" class="el-upload__tip">
              支持上传jpg/jpeg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-col>
        <el-col width="100px" style="margin-top: 30px">
          <div>
            <el-button type="warning" @click="edit_logs()" style="margin-left: 100px">确 定</el-button>
            <el-button @click="dialogEditVisible = false">取 消</el-button>
          </div>
        </el-col>
      </el-row>

      <el-form label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="标题" width="100px" style="margin-top: 30px">
              <el-input v-model="new_title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="内容" width="100px" style="margin-top: 30px">
              <el-input type="textarea" :rows='8' v-model="new_content" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="备注" width="100px" style="margin-top: 30px">
              <el-input v-model="new_comments" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import service from "../utils/request";
export default {
  data() {
    return {
      work: [],
      tableData: [],
      scope: [],
      num: 0,
      dialog: false,
      dialogEditVisible: false,
      //新添加影片参数
      limit_num: 1,
      title: "",
      content: "",
      comments: "",
      image_file: [],
      new_pic_id: "",
      make_sure_add: false,
      //修改影片参数
      pic_id: "",
      loc_pic: "",
      lid: "",
      new_title: "",
      new_comments: "",
      new_content: "",
      make_sure: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length: null,
      isSearch: false,
      all_count: 0,
      list: []
    };
  },
  methods: {

    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
      //console.log(this.pagesize)
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      const path = "https://api.movie.yingshinet.com/logs";
      this.axios
        .post(path, { divide: 1, page: currentPage, count: 10 })
        .then((res) => {
          //console.log(res)
          this.work = res.data.all_logs;
          this.num = res.data.cur_subtitle_count;
          this.tableData_length = res.data.all_subtitle_count;
          this.all_count = res.data.all_subtitle_count;
          this.tableData.length = this.tableData_length;
          this.tableData = []
          this.getData();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 显示确认添加弹窗
    add_logs() {
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      //信息上传
      service({
        url: "https://api.movie.yingshinet.com/logs/insert",
        method: "post",
        data: {
          title: this.title,
          content: this.content,
          comments: this.comments,
        },
      }).then((response) => {
        this.make_sure_add = false
        this.dialog = false
        if (response.status == "200") {
          this.new_pic_id = response.data.id;
          // 图片上传
          if (this.image_file.length == 1) {
            this.$message({
              message: "基本信息添加成功！请等待日志图片上传！",
              type: "warning",
            });
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/logs/pic/" + this.new_pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.status == "200") {
                  this.$message({
                    message: "日志图片上传成功！",
                    type: "success",
                  });
                  this.handleCurrentChange(this.currentPage);
                } else {
                  this.$message({
                    message: "日志图片好像出问题了！",
                    type: "warning",
                  });
                }
              });
          }
          else {
            this.$message({
              message: "基本信息添加成功！",
              type: "success",
            });
            this.handleCurrentChange(this.currentPage);
          }
        } else {
          console.log(response)
          this.$message({
            message: "基本信息添加好像出问题了！",
            type: "warning",
          });
        }
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    getData() {
      // console.log(this.work)
      for (let i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.all_count - this.work[i].lid + 1;
        this.tableData[i].title = this.work[i].title;
        this.tableData[i].comments = this.work[i].comments;
        this.tableData[i].content = this.work[i].content;
        this.tableData[i].log_pic = this.work[i].log_pic;
        this.tableData[i].create_man = this.work[i].create_man;
        this.tableData[i].create_time = this.work[i].create_time;
        this.tableData[i].update_time = this.work[i].update_time;
      }
      this.$set(this.tableData);
    },
    handleEdit(index, row) {
      this.pic_id = this.all_count - row.no + 1;
      this.loc_pic = this.work[row.no - 1].log_pic ? this.work[row.no - 1].log_pic.split('/').pop() : null;
      this.lid = this.all_count - row.no + 1;
      this.new_title = row.title;
      this.new_comments = row.comments
      this.new_content = row.content
      this.dialogEditVisible = true;
    },
    //影片编辑
    edit_logs() {
      this.make_sure = true;
    },
    make_sure_click() {
      this.make_sure = false;
      this.dialogEditVisible = false;
      //信息上传
      service({
        url: "https://api.movie.yingshinet.com/logs/update",
        method: "post",
        data: {
          id: this.lid,
          now_log_title: this.new_title,
          now_log_content: this.new_content,
          now_log_comment: this.new_comments,
          log_pic: this.loc_pic
        },
      }).then((response) => {
        if (response.status == "200") {
          this.title = this.now_log_title;
          this.content = this.now_log_content;
          // 图片上传
          if (this.image_file.length == 1) {
            this.$message({
              message: "基本信息修改成功！请等待日志图片上传！",
              type: "warning",
            });
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/logs/pic/" + this.pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.status == "200") {
                  this.$message({
                    message: "日志图片修改成功！",
                    type: "success",
                  });
                  this.handleCurrentChange(this.currentPage);
                } else {
                  this.$message({
                    message: "日志图片好像出了点问题",
                    type: "warning",
                  });
                }
              });
          }
          else {
            this.$message({
              message: "基本信息修改成功！",
              type: "success",
            });
            this.handleCurrentChange(this.currentPage);
          }
        } else {
          this.$message({
            message: "基本信息修改好像出问题了！",
            type: "warning",
          });
        }
      });
    },
  },
  created() {
    this.handleCurrentChange(1);
  }
}

</script>
<style>
</style>