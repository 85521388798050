<template>
  <div>
    <div>
          <LiteratureContent 
            :clickedLiteratureurl="clickedLiteratureurl" 
            :directorurl='directorurl'                   
            v-on:updatedData="updatedData"
            v-on:deleted="deleted"
            v-on:added="added"></LiteratureContent>
    </div>
  </div>
</template>
  
<script>
import LiteratureContent from '../components/literature/LiteratureContent.vue'


export default {
  name: 'Literature',
  components: {
    LiteratureContent
  },
  data() {
    return {
      // 文献总数
      num: 0,
      // 鼠标悬停显示数据
      popoverContent: '',
      open_list: ["1"],
      // 搜索框搜索数据
      //literature_search: '',
      // 搜索结果及数量
      search_data: [],
      search_num: 0,
      // 点击item的信息
      clickedLiteratureurl: {},
      directorurl:{}
    }
  },
  watch: {
    '$route'(to, from) { //监听路由是否变化，根据路由id展示右侧图片和相应内容
      if (to.query.id != from.query.id) {
        this.axios
          .post("https://api.movie.yingshinet.com/articles/select", { id: to.query.id })
          .then((response) => {
            this.clickedLiteratureurl = response.data.m_data[0];
            this.axios
            .post("https://api.movie.yingshinet.com/figures/select", { f_name:this.clickedLiteratureurl.label})
            .then((response) => {
            this.directorurl=response.data.f_data[0]
          });
          });
      }
    },
  },
  computed: {
  },
  methods: {
    //鼠标悬停事件  
    setMouseOver: function (item, $event) {
      this.popoverContent = item.title
    },
    setMouseOut() {
      // console.log(this.popoverContent)
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },

    //修改内容后
    // 已修改内容重新从后端读取数据显示
    updatedData(val) {
      this.axios
        .post("https://api.movie.yingshinet.com/articles/select", { title: val.title })
        .then((response) => {
          this.clickedLiteratureurl = response.data.m_data[0];
        });
    },
    // 删除一条数据后执行操作
    deleted(val) {
      this.literature_search = '';
      this.axios
        .get("https://api.movie.yingshinet.com/articles/part")
        .then((res) => {
          this.num = res.data.a_count;
          this.search_num = res.data.a_count;
          this.search_data = res.data.a_data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 增加一条数据后执行操作
    added(val) {
      this.axios
        .get("https://api.movie.yingshinet.com/articles/part")
        .then((res) => {
          this.num = res.data.a_count;
          this.search_num = res.data.a_count;
          this.search_data = res.data.a_data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
  created() {
    // 读取人物数据
    this.axios
            .post("https://api.movie.yingshinet.com/figures/select", { f_name:this.$store.state.current_figure})
            .then((response) => {
            this.directorurl=response.data.f_data[0]
          });
    // 读取文献数据
    this.axios
      .post("https://api.movie.yingshinet.com/articles/searchlabel", { label: this.$store.state.current_figure })
      .then((response) => {
        this.search_data = response.data.a_data;
        this.search_num = response.data.a_count;
        // 如果跳转到该页面时有id传来，则根据id显示页面内容
        if(this.$route.query.id){
          this.axios
          .post("https://api.movie.yingshinet.com/articles/select", { id:this.$route.query.id})
          .then((response) => {
          this.clickedLiteratureurl=response.data.m_data[0];
          });
        }
        else if(this.search_num==0){
          this.clickedLiteratureurl={
            content:'该影人还未录入作品数据，请登录后录入数据。',
            file_dir:[require("../../public/static/pdf_icon.png")]
          }
        }
        else{
          this.axios
          .post("https://api.movie.yingshinet.com/articles/select", { id:response.data.a_data[0].id})
          .then((response) => {
            this.clickedLiteratureurl=response.data.m_data[0];
          });
        }
      });
  }

}
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>
