<template>
  <div style="padding:5px">
    <div class="el_card" slot="header" style="height: 60px">
              <el-input class="e-input" placeholder="搜索标签" v-model="post_search"
                style="width: 60%; margin: 10px 200px;"></el-input>
            </div>
     <div style="overflow-y: auto;">
              <el-row>
                <el-col :span="4" v-for="(title, index) in search_data" :key="index" class="text_item">
                  <span class="mouse_over" @click="titleClick(title)">{{
                      title
                  }}</span>
                </el-col>
              </el-row>
            </div>
   

    <el-row :gutter="8">
      <el-col>
        <!-- 标签列表子组件 -->
        <terms_list v-bind:all_terms="all_terms"></terms_list>
      </el-col>
     
    </el-row>
   
    <!-- 编辑页面 -->
    <el-dialog class="el-dia" title="请添加影人信息：" :visible.sync="dialog" width="100%" height="100%" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure_add" width="50%" append-to-body>
        <span>确定要添加信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload class="upload-demo" ref="upload" list-type="picture" action="alert" :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
        <el-button slot="trigger" size="medium" type="warning">点击上传影人图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left:3px">
          支持上传jpg/jpeg/png文件，且不超过500kb
        </div>
      </el-upload>
      <el-form>
        <el-form-item label="影人" width="100px" style="margin-top:5px">
          <el-input v-model="director_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="诞辰" width="100px" style="margin-top:5px; width:30%; display:inline-block">
          <el-date-picker v-model="director_brith_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy年MM月dd日" style="width:100%"></el-date-picker>
        </el-form-item>

        <el-form-item label="配偶" width="100px" style="margin:5px 0 0 30px; width:30%; display:inline-block">
          <el-input v-model="director_spouse" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" width="100px" style="margin:5px 0 0 30px; width:20%; display:inline-block">
          <el-radio-group v-model="director_sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="简介" width="100px" style="margin-top:5px">
          <el-input v-model="director_brief" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详述" width="100px" style="margin-top:5px">
          <el-input type="textarea" :rows="8" v-model="dir_direction" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="warning" @click="add_director()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DirectorInfo from "../components/director/director_info.vue";
import service from "../utils/request";
import Terms_list from "../components/director/terms_list.vue";
export default {
  components: {
    DirectorInfo,
    Terms_list
},
  data() {
    return {
      //翻页
      currentPage: 1, //初始页
      pagesize: 20, //    每页的数据
      alltags:[],
      alltagname:[],
      post_search:"",
      director_sorted: [],
      tableData_length:null,
      directors: [],
      all_terms:[],
      terms:[],
      dir_name: "",
      birth_time: "",
      director_sex: "",
      brief: "",
      spouse: "",
      director_search: "",
      direction: "",
      limit_num: 1,
      url: "",
      essays: [],
      dir_id: 0,
      srcList: [],
      path: "https://api.movie.yingshinet.com",
      image_file: [],
      dialog: false,
      director_name: "",
      director_brith_time: "",
      director_brief: "",
      director_spouse: "",
      dir_direction: "",
      dir_sex: "",
      make_sure_add: false,
      new_pic_id: "",
      isSearch: false,
      color: "#b8860b",
    };
  },
  computed: {
    // 搜索
     search_data: {
      get() {
        if (!this.post_search.trim()) {
          return this.alltagname;
        }
        return this.alltagname.filter(
          (data) =>
            data &&
            data
              .toLowerCase()
              .includes(this.post_search.trim().toLowerCase())
        );
      },
    },
    // 实体超链接
   
  },
  methods: {
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.isSearch) {
        //如果是搜索功能的话，是一次性返回全部数据，需要每次换页的时候分页显示
        this.director_sorted = this.list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        //this.tableData_length = list.length;
        this.$set(this.director_sorted);
      }
      else {
        const path = "https://api.movie.yingshinet.com/figures_world_Festival";
        this.axios
          .post(path, { divide: 1, page: currentPage, count: 50 })
          .then((res) => {
            this.tableData_length = res.data.all_figures_count;
            this.directors=[]
            for (var i = 0; i < res.data.cur_figures_count; i++) {
              this.directors.push(res.data.figures[i].f_name);
            }
            this.director_sorted = []
            this.director_sorted = this.directors
            this.$set(this.director_sorted);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    //按字母顺序排序
    sortChinese(obj) {
      obj.sort(function (item1, item2) {
        return item1.localeCompare(item2, 'zh-CN');// localeCompare为string内置函数
      })
    },
    // 显示确认添加弹窗
    add_director() {
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      this.make_sure_add = false;
      this.dialog = false;

      //信息上传
      service({
        url: "/figures/insert",
        method: "post",
        data: {
          f_name: this.director_name,
          birth_time: this.director_brith_time,
          f_work: this.dir_direction,
          f_brief: this.director_brief,
          sex: this.director_sex,
          spouse: this.director_spouse,
        },
      }).then((response) => {
        if (response.data.msg == "insert success") {
          this.new_pic_id = response.data.id;
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          // 图片上传
          if (this.image_file.length == 1) {
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/figures/pic/" +
                this.new_pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.data.msg == "success") {
                  this.$message({
                    message: "影人照片上传成功！",
                    type: "success",
                  });
                  location.reload();
                } else {
                  this.$message({
                    message: "影人照片好像出了点问题",
                    type: "warning",
                  });
                }
              });
          }
        } else {
          this.$message({
            message: "添加有点问题！",
            type: "warning",
          });
        }
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file)
    },

    titleClick(title) {
      const index = this.alltagname.indexOf(title)
      // console.log(index)
      this.$router.push('/post?name=' + title)
    },
  },

  mounted() {
    this.axios
      .post(this.path + "/terms")
      .then((response) => {
        this.all_terms = response.data.all_terms;
      });
  },
  created(){
    // this.handleCurrentChange(1);
    this.axios
      .post("https://api.movie.yingshinet.com/terms", {
      })
      .then((response) => {
        this.alltags=response.data.all_terms; 
        for(let i=0;i<this.alltags.length;i++)
        {
          this.alltagname[i]=this.alltags[i].terms;
        }    
      });
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>

<style>
.text_item {
  font-size: 15px;
  margin-bottom: 12px;
  margin-left: 15px;

}

.mouse_over:hover {
  color: #b8860b;
  cursor: pointer;
}

.el-card .el-card__header {
  padding: 8px 20px;
}

.e-input {
  text-align: left;
  border-radius: 5px;
  color: #606266;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  padding-left: 8px;
  outline: none;
}

.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}

.back_image {
  background-image: url("/static/back1.jpg");
  background-size: cover;
}
</style>
