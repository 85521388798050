 <template>
<div>
<center><h3>电影著作作者词云图</h3></center>
<el-scrollbar>
    <svg id="wordcloud" height="50vh" width="100vw"></svg>
</el-scrollbar>
</div>
</template>

 <script>
 import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as $ from "jQuery";
import { loadHistory } from "../../../utils/store";
export default {
  data() {
    return {
      work: [],
      tableData: [],
      tableData_length: "",
      path1:"https://api.movie.yingshinet.com/books/author",
    };
  },
  methods:{

    drawcloud(){
        let width=(window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth);
		let height=(window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight)*0.45;
		let sizeScale=d3.scaleLinear()
                   .domain([0,100])
                   .range([0,20]);
		// var words=[{text:"战狼2",size:57},{text:"速度与激情8",size:27},{text:"羞羞的铁拳",size:22},{text:"功夫瑜伽",size:18},
		// {text:"西游伏妖篇",size:17},{text:"变形金刚5",size:16},{text:"摔跤吧！爸爸",size:13},{text:"芳华",size:13},
		// {text:"寻梦环游记",size:12},{text:"加勒比海盗5",size:12}];
		let wc=cloud()
				  .size([width, height])
				  .words(this.tableData)
				  .padding(1)
				  .rotate(0)
				  .font("Impact")
				  .fontSize(function(d) { return sizeScale(d.size)+6; })
				  .on("end", draw)
				  .start();
   
	function draw(words) {
        const fill = d3.scaleOrdinal(d3.schemeCategory10);

			let svg=d3.select("#wordcloud")
				.attr("width", width)
				.attr("height", height)
			  .append("g")
				.attr("transform", "translate("+width/2+","+height/2+")")
			  .selectAll("text")
				.data(words)
			  .enter()
			  .append("a")
                 .attr("xlint:href",function(d){
                            return "/#/Content_book?wanted=author"+d.text;
                 })
			  .append("text")
				.style("font-size", function(d) { return d.size+2 + "px"; })
				.style("font-family", "Impact")
				.style("fill", function(d, i) { return fill(i); })
				.attr("text-anchor", "middle")
				.attr("transform", function(d) {
				  return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
				})
				.text(function(d) { return d.text; });
		  }
    },
  initdata(){
     this.axios
        .post(this.path1)
        .then((res) => {
          for (var i = 0; i < 1000; i++) {
            this.tableData[i] = {};
            this.tableData[i].text = res.data.data[i].author;
            this.tableData[i].size = res.data.data[i].count;
          }
          this.tableData_length=res.data.count;
          this.drawcloud();
          })
},
  },

    created(){

        this.initdata();
    }
  }
        </script>