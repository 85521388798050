<template>
  <div style="margin-top: 80px">
    <!-- 照片墙 -->
    <div
      align="center"
      style="
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 20;
        background-color: white;
      "
    >
      <img src="/static/movie_logo.svg" style="height: 80px" />
    </div>
    <!-- 上布局 -->
    <div v-if="this.$store.state.isvis == 'homepage'" class="homepage">
      <div>
        <cloud style="width: 100%; height: 31vh"></cloud>
      </div>
      <div>
        <force style="height: 45%; width: 100%; margin: 0vh 0 0 0"></force>
      </div>
      <div>
        <chronicleRecent style="height: 50%; width: 100%"></chronicleRecent>
      </div>
      <div>
        <div
          v-show="window_width < 768"
          align="left"
          style="
            font-weight: bold;
            color: #e2a253;
            font-size: 15px;
            margin: 1vw 0 0vw 1vw;
          "
        >
          电影类型
        </div>
        <el-scrollbar style="height: 15vh">
          <tagshow style="height: 100%; width: 100%"></tagshow>
        </el-scrollbar>
      </div>
      <div>
        <div
          v-show="window_width < 768"
          align="left"
          style="
            font-weight: bold;
            color: #e2a253;
            font-size: 15px;
            margin: 1vw 0 1vw 1vw;
          "
        >
          近代电影
        </div>
        <el-scrollbar style="height: 15vh">
          <movieRecent
            style="height: 35vh; width: 100%; margin-top: -4vh"
          ></movieRecent>
        </el-scrollbar>
      </div>
    </div>
    <div style="margin: 0vh 10vw 0 10vw; width: 80vw; position: relative">
      <div class="title" align="center">
        <h2></h2>
      </div>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="4" style="align: center; width: 20% !important">
          <div class="icon_style">
            <i class="el-icon-user"></i>
          </div>
          <span class="data_font_size">影人 {{ figure_count }} 位</span>
        </el-col>
        <el-col :span="4" style="width: 16% !important">
          <div class="icon_style">
            <i class="el-icon-pie-chart"></i>
          </div>
          <span class="data_font_size">年谱 {{ chronicle_count }} 条</span>
        </el-col>

        <el-col :span="4" style="width: 16% !important">
          <div class="icon_style">
            <i class="el-icon-film"></i>
          </div>
          <span class="data_font_size">影片 {{ movie_count }} 条</span>
        </el-col>

        <el-col :span="4" style="width: 16% !important">
          <div class="icon_style">
            <i class="el-icon-notebook-1"></i>
          </div>
          <span class="data_font_size">文章 {{ essay_count }} 条</span>
        </el-col>

        <el-col :span="4" style="width: 16% !important">
          <div class="icon_style">
            <i class="el-icon-notebook-2"></i>
          </div>
          <span class="data_font_size">著作 {{ article_count }} 条</span>
        </el-col>
        <el-col :span="4" style="width: 16% !important">
          <div class="icon_style">
            <i class="el-icon-chat-dot-square"></i>
          </div>
          <span class="data_font_size">百科 {{ encyclopedia_count }} 条</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import service from "../utils/request";
import PicBorder from "../components/home/pic_border.vue";
import PicCloud from "../components/home/pic_cloud.vue";
import PicForce from "../components/home/pic_force.vue";
import PicLine from "../components/home/pic_line.vue";
import PicHist from "../components/home/pic_hist.vue";
import PicLollipop from "../components/home/pic_lollipop.vue";
import VideoGraph from "./Video_index_graph.vue";

import cloud from "../components/homepage/cloud.vue";
import force from "../components/homepage/force.vue";
import movieRecent from "../components/homepage/movieRecent.vue";
import chronicleRecent from "../components/homepage/chronicleRecent.vue";
import tagshow from "../components/homepage/tagshow.vue";
import chronicle_list from "../components/chronicle/chronicle_list.vue";

export default {
  components: {
    PicBorder,
    PicCloud,
    PicForce,
    PicLine,
    VideoGraph,
    PicLollipop,
    PicHist,
    cloud,
    force,
    tagshow,
    chronicleRecent,
    movieRecent,
    chronicle_list,
  },
  data() {
    // 登出表单验证
    var validate_oldpass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入旧密码"));
      } else {
        callback();
      }
    };
    var validate_newpass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    };
    var validate_newpass_again = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.change_passwrd.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      window_width: window.screen.width,
      WorldASLdata: "世界电影ASL",
      filmanalysis: "电影拉片",
      company: "制片公司",
      essay_title: "文献索引",
      essay_peo: "影人文论",
      conments: "评论",
      introduction: "项目简介",
      knowledgeGene: "知识生成",
      editGuidance: "编纂指南",
      about: "加入我们",
      programDialog: "项目日志",
      force: "社交网络",
      ASLdata: "华语ASL",
      picFigure: "影人星丛",
      picFilm: "影人作品",
      figurearticles: "论  文",
      literature: "早期报刊",
      essay_website: "相关平台",
      essay_chi: "著  作",
      periodical: "期  刊",
      chronicle: "影人年谱",
      ChronicleAnalysis: "可视分析",
      encyclopedia: "电影百科",
      chronicleList: "年谱列表",
      registerAuthorize: "注册验证",
      people: "影人年谱",
      people_china: "中国影人",
      people_world: "世界影人",
      movie_classic: "经典电影",
      dialog_content: "项目日志",
      video_title: "电影计量",
      video_brief: "影片题录",
      video: "影像视频",
      filmanalysis: "电影拉片",
      company: "制片公司",
      essay: "影人文论",
      color_write: "#ffffff",
      conments: "评论",
      connectus: "数据",
      content: "研究专著",
      occupation: "职业导航",
      Montage: "标签词云",
      HKmovie: "香港电影",
      Festival: "标签搜索",
      Francedirector: "法国电影",
      AnnualEvent: "电影年表",
      // 路径
      path: "https://api.movie.yingshinet.com",
      // 数据与统计
      fig_response: "",
      figures: [],
      figure_names: [],
      figures_serach: [],
      figure_count: 0,
      chronicles: [],
      chronicle_count: 0,
      movies: [],
      movie_count: 0,
      essays: [],
      essay_count: 0,
      articles: [],
      article_count: 0,
      encyclopedia_count: 0,
      // 搜索框
      select: "1",
      serach_option: [],
      search_content: "",
      // 登出与修改密码
      logout_visible: false,
      change_password_visible: false,
      change_password_makesure: false,
      change_passwrd: {
        old_password: "",
        new_password: "",
        new_password_again: "",
      },
      // 修改密码表单规则
      rules: {
        old_password: [
          { required: true, validator: validate_oldpass, trigger: "change" },
        ],
        new_password: [
          { required: true, validator: validate_newpass, trigger: "change" },
        ],
        new_password_again: [
          {
            required: true,
            validator: validate_newpass_again,
            trigger: "change",
          },
        ],
      },
      //修改个人信息
      change_information_visible: false,
      change_information_makesure: false,
      change_infor: {
        true_name: "",
        email: "",
        sex: "",
        phone: "",
      },
      user_id: "",
      viswhat: "",
      rec_figure: {},
    };
  },
  methods: {
    // 词云图切换
    handlecloud(isvis, name) {
      this.viswhat = name;
      this.$store.commit("handlevis", isvis);
    },
    go_director() {
      this.$router.push("/directors?=" + this.rec_figure.f_name);
      this.$store.commit("set_current_figure", this.rec_figure.f_name);
    },
    // 照片点击
    figure_click(figure_name) {
      if (this.window_width > 768) {
        this.$router.push("/directors?=" + figure_name);
        this.$store.commit("set_current_figure", figure_name);
      } else {
        this.$message({
          showClose: true,
          message: "更多信息请见电脑端页面",
        });
      }
    },
    // select option回调方法
    serach_item_change(value) {
      this.search_content = "";
      switch (value) {
        // 影人搜索
        case "1":
          this.serach_option = this.figure_names;
          break;
        case "2":
          // 影片搜索
          this.serach_option = this.movies;
          break;
        case "3":
          // 文章搜索
          this.serach_option = this.essays;
          break;
        case "4":
          // 文章搜索
          this.serach_option = this.articles;
          break;
        default:
      }
    },
    // 搜索点击
    search_click() {
      switch (this.select) {
        case "1":
          // 影人搜索
          if (this.search_content.trim()) {
            this.figures_serach = [];
            for (var i = 0; i < this.figures.length; i++) {
              if (
                this.figures[i].f_name.includes(
                  this.search_content.trim().toLowerCase()
                )
              ) {
                this.figures_serach.push(this.figures[i]);
              }
            }
          } else {
            this.figures_serach = this.figures;
          }
          break;
        case "2":
          // 影片搜索
          break;
        case "3":
          // 文章搜索
          break;
        case "4":
          // 文献搜索
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });
      }
    },
    // 匹配搜索建议
    query_search(query_string, callback) {
      var restaurants = this.serach_option;
      var results = [];
      // 影人匹配
      for (var i = 0; i < restaurants.length; i++) {
        if (restaurants[i].value.includes(query_string.trim().toLowerCase())) {
          results.push(restaurants[i]);
        }
      }
      callback(results);
    },
    // 匹配项点击
    handle_select(item) {
      switch (this.select) {
        case "1":
          // 影人搜索
          this.$router.push("/directors?=" + item.value);
          this.$store.commit("set_current_figure", item.value);
          break;
        case "2":
          // 影片搜索
          this.$router.push("/video?id=" + item.id);
          this.$store.commit("set_current_figure", item.director);
          break;
        case "3":
          // 文章搜索
          this.$router.push("/essays?id=" + item.id);
          this.$store.commit("set_current_figure", item.writer);
          break;
        case "4":
          // 文献搜索
          this.$router.push("/literature?id=" + item.id);
          this.$store.commit("set_current_figure", item.label);
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });
      }
    },
    // 确定登出
    logout_click() {
      this.logout_visible = false;
      service({ url: "/logout", method: "get" })
        .then((response) => {
          this.$store.commit("change_state_out");
          this.$message({
            message: "登出成功！",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 提交修改密码表单
    onSubmit(change_password_form) {
      this.$refs[change_password_form].validate((valid) => {
        if (valid) {
          this.change_password_makesure = true;
        }
      });
    },
    // 修改密码
    change_password_click() {
      service({
        url: "/changepsw",
        method: "post",
        data: {
          old_password: this.change_passwrd.old_password,
          new_password: this.change_passwrd.new_password,
        },
      })
        .then((response) => {
          this.change_password_visible = false;
          this.change_password_makesure = false;
          if (response.data.msg == "change password success") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 提交修改个人信息表单
    onSubmitInfor(change_information_form) {
      this.$refs[change_information_form].validate((valid) => {
        if (valid) {
          this.change_information_makesure = true;
        }
      });
    },
    // 修改个人信息
    change_information_click() {
      console.log("id: " + this.user_id);
      service({
        url: "/changeinfo",
        method: "post",
        data: {
          id: this.user_id,
          true_name: this.change_infor.true_name,
          email: this.change_infor.email,
          sex: this.change_infor.sex,
          phone: this.change_infor.phone,
        },
      })
        .then((response) => {
          this.change_information_visible = false;
          this.change_information_makesure = false;
          if (response.data.msg == "change error") {
            this.$message({
              message: "修改失败",
              type: "warning",
            });
          } else {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.$store.commit("toggleCollapse");
    console.log(
      "this.$store.state.isCollapse: " + this.$store.state.isCollapse
    );
    //获得推荐人物
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    //获取各类数据个数
    this.axios.get(this.path + "/statistics").then((response) => {
      this.chronicle_count = response.data.statistics[0].chronicle_count;
      this.article_count = response.data.statistics[0].article_count;
      this.essay_count = response.data.statistics[0].essay_count;
      this.figure_count = response.data.statistics[0].figure_count;
      this.movie_count = response.data.statistics[0].movie_count;
      this.encyclopedia_count = response.data.statistics[0].wp_count;
    });
    this.axios
      .post("https://api.movie.yingshinet.com/figures/select", {
        birth_time: month + "月" + day + "日",
      })
      .then((response) => {
        if (response.data.f_count != 0) {
          this.rec_figure = response.data.f_data[0];
        } else {
          this.axios
            .post("https://api.movie.yingshinet.com/figures/select", {
              birth_time: month + "月",
            })
            .then((res) => {
              this.rec_figure = res.data.f_data[0];
            });
        }
      });
    // 影人数据
    this.axios.get(this.path + "/figures").then((response) => {
      const fig_count = response.data.f_count;
      for (var i = 0; i < fig_count; i++) {
        this.figures.push(response.data.f_data[i]);
        this.figure_names.push({ value: response.data.f_data[i].f_name });
      }
      this.figures_serach = this.figures;
      this.fig_response = response.data;
      this.figure_count = response.data.f_count;
      this.serach_option = this.figure_names;
    });
    // 年谱数据
    this.axios.get(this.path + "/chronicles").then((response) => {
      this.chronicle_count = response.data.c_count;
    });
    // 影片数据
    this.axios.get(this.path + "/movies").then((response) => {
      this.movie_count = response.data.m_count;
      for (var i = 0; i < this.movie_count; i++) {
        // value必须，不然搜索框不显示
        this.movies.push({
          value: response.data.m_data[i].m_name,
          id: response.data.m_data[i].mid,
          director: response.data.m_data[i].m_director,
        });
      }
    });
    // 影人文章数据
    this.axios.get(this.path + "/essays/part").then((response) => {
      this.essay_count = response.data.e_count;
      for (var i = 0; i < this.essay_count; i++) {
        this.essays.push({
          value: response.data.e_data[i].title,
          id: response.data.e_data[i].wid,
          writer: response.data.e_data[i].writer,
        });
      }
    });
    // 相关文献数据
    this.axios.get(this.path + "/articles/part").then((response) => {
      this.article_count = response.data.a_count;
      for (var i = 0; i < this.article_count; i++) {
        this.articles.push({
          value: response.data.a_data[i].title,
          id: response.data.a_data[i].id,
          label: response.data.a_data[i].label,
        });
      }
    });
    //用户数据
    if (this.$store.state.login_state) {
      this.axios
        .post(this.path + "/showinfo", { name: this.$store.state.user_name })
        .then((response) => {
          // console.log(response.data);
          (this.user_id = response.data.id),
            (this.change_infor.email = response.data.email),
            (this.change_infor.true_name = response.data.true_name),
            (this.change_infor.sex = response.data.sex),
            (this.change_infor.phone = response.data.phone);
        });
    }
  },
};
</script>

<style>
.el-tooltip__popper {
  padding: 13px !important;
  font-size: 18px !important;
  box-shadow: 0 0 15px #aaaaaa;
}
.el-input-group__prepend {
  border-radius: 1vw 0 0 1vw !important;
}
.el-input-group__append {
  border-radius: 0 1vw 1vw 0 !important;
}
.el-select-dropdown__item.selected {
  color: #e2a253 !important;
}
.el-button--medium {
  font-size: 15px !important;
}
.el-divider--horizontal {
  margin: 3vw 0 2vw 0 !important;
}

.reandlog_icons {
  display: inline-block;
  padding: 0 !important;
}
.reandlog_hidden {
  display: none !important;
}
</style>

<style scoped>
.el-menu--horizontal > .el-menu-item.is-active {
  font-size: 1.3vw;
  font-weight: bold;
  border-bottom: none !important;
  text-decoration: none !important;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none !important;
  text-decoration: none !important;
  height: 80px !important;
  margin-top: 0 !important;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  text-decoration: none !important;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  font-size: 1.3vw;
  font-weight: bold;
  border-bottom: none !important;
  text-decoration: none !important;
}
.input_style {
  z-index: 2;
  position: fixed;
  width: 22vw;
  left: 4vw;
  top: 110px;
  border-radius: 1vw;
  box-shadow: 4px 4px 10px #aaaaaa;
}
.go_dir_icon {
  margin-left: 20px;
  margin-top: 18.5px;
  display: inline-block;
  font-size: 35px;
  color: #e2a253;
}
.menu .reandlog {
  float: right;
}
.select_style {
  width: 5vw;
  border-radius: 1vw 0 0 1vw;
}
.serach_button_style {
  width: 4vw;
  font-size: 1vw;
  color: #fff;
}
.login_out_fix {
  float: right;
  margin-top: 22px;
  margin-right: 1vw;
  white-space: pre;
}
.login_out_height {
  height: 40px;
  margin: 0 0 0 1vh;
}
/* .user_hello {
  margin-right: 1vw;
  font-size: 17px;
  color: #e2a253;
}
.user_hello:hover {
  cursor: pointer;
  color: #e2a253;
} */
.header_height {
  height: 80px;
  border-bottom: 0.2vw solid #e2a253;
  box-shadow: 2px 2px 10px #aaaaaa;
}
.header_image {
  height: 65px;
  margin-top: 10px;
  margin-left: 0.2vw;
}
.icon_style {
  font-size: 2.8vw !important;
  color: #e2a253;
}
.top_icon_style {
  margin: 3px 4px !important;
  font-size: 30px !important;
  color: #e2a253;
}
.data_font_size {
  font-size: 1vw;
}
.el-dropdown-link {
  cursor: pointer;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.icon_shadow {
  box-shadow: 5px 5px 5px #aaaaaa;
}
.el-col-15 {
  float: none !important;
}
.el-col-6 {
  float: right !important;
}
</style>
