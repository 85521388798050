@@ -1,274 +0,0 @@
<template class="PersonalPage">
  <div>
    <div style="display: flex">
      <el-select class="select_type" v-model="select_type" filterable placeholder="选择改动类型" 
      @change="change_type(select_type)">
      <el-option label="创建年谱" value="创建"></el-option>
      <el-option label="修改年谱" value="修改"></el-option>
    </el-select>
    <el-row>
      <el-button v-if="this.$store.state.is_summerschoolstudent" style="display: inline-block; margin-left: 2vw; margin-top: 10px; text-align: center;" icon="el-icon-reading"  @click="certificatePreview()">证书预览</el-button>
    </el-row>
    </div>

    <el-table :data="searchData" :key="sort_button" style="width: 90%; margin: 20px auto"
      :row-style="{ height: '80px' }">
      <el-table-column align="center" label="编号" prop="no" min-width="5%"  ></el-table-column>
      <el-table-column align="center" label="日期" prop="c_date" min-width="15%"></el-table-column>
      <el-table-column align="center" label="内容" prop="c_content" min-width="35%"></el-table-column>
      <el-table-column align="center" label="影人" prop="c_figure" min-width="15%">
        <!-- 影人超链接 -->
        <template slot-scope="scope">
          <span v-if="scope.row.c_figure!='费穆'" >
            <a :href="'/#/directors?name='+scope.row.c_figure" style="text-decoration: none; color: #b8860b">{{scope.row.c_figure}}</a>
            </span>
          <span v-else>
            <a href="/#/directors" style="text-decoration: none; color: #b8860b">{{ scope.row.c_figure }}</a>
            </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="图片" prop="c_pic" min-width="10%">
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <el-image class="image_style" v-if="scope.row.c_pic != null" :src="scope.row.c_pic"></el-image>
          <div v-if="scope.row.c_pic == null">暂无图片</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="改动类型" prop="c_type" min-width="10%"></el-table-column>
      <el-table-column align="center" label="更新时间" prop="c_change_time" min-width="20%"></el-table-column>

    </el-table>

    <el-pagination style="text-align: center; margin: 5vh 0" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pagesize"
      layout="total, prev, pager, next" :total="tableData_length"></el-pagination>

  </div>

</template>
    
<script>
import service from "../utils/request";
export default {
  data() {
    return {
      path: "https://api.movie.yingshinet.com/chronicles/select",
      work: [],
      tableData: [],
      //随机数的数组
      randomNum: [],
      scope: [],
      num: 0,
      search_figure: "",
      dialog: false,
      dialogEditVisible: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length: null,

      first_search: false,
      sort_button: false,
      isSearch: false,
      all_count: 0,
      list: [],

      searchData: [],
      select_type: "修改",
      tableData1: [], // 创建年谱数据
      tableData2: [], // 修改年谱数据
      tableData_length1: null, // 创建年谱的长度
      tableData_length2: null, // 修改年谱的长度
    };
  },
  watch: {
    search_content: {
      handler: function () {
        if (this.search_content == "") {
          this.isSearch = false;
          this.tableData_length = this.all_count;
        }
      },
    },
  },

  methods: {
    director_link() {
      console.log("dianji");
    },

    change_type(select_type) {
      switch (select_type) {
        case "创建":
          {
            // this.searchFigure();
            this.tableData_length = this.tableData_length1;
            this.searchData = this.tableData1;
          }
          break;
        case "修改":
          {
            // this.searchFigure();
            this.tableData_length = this.tableData_length2;
            this.searchData = this.tableData2;
          }
          break;
        default:
      }
    },

    // 搜索功能
    searchFigure() {
      this.isSearch = true;
      const path = "https://api.movie.yingshinet.com/chronicles/select";
      var config1 = {
        c_create_man: this.$store.state.user_name,
      };
      this.axios
        .post(path, config1)
        .then((res) => {
          this.tableData1 = [];
          for (var i = 0; i < res.data.c_count; i++) {
            console.log(res.data.c_data[i].c_figure);
            this.tableData1[i] = {};
            this.tableData1[i].no = i + 1;
            this.tableData1[i].c_content = res.data.c_data[i].c_content;
            this.tableData1[i].c_date = res.data.c_data[i].c_date;
            this.tableData1[i].c_type = "创建";
            this.tableData1[i].c_figure = res.data.c_data[i].c_figure;
            this.tableData1[i].c_change_time = res.data.c_data[i].c_create_time;
            if (res.data.c_data[i].c_pic != null) {
              this.tableData1[i].c_pic =
                "https://assets.yingshinet.com/chronicles/pictures/" +
                res.data.c_data[i].c_pic;
            } else {
              this.tableData1[i].c_pic = null;
            }
          }
          this.list = this.tableData1;
          this.tableData1 = this.list.slice(
            (this.currentPage - 1) * this.pagesize,
            this.currentPage * this.pagesize
          );
          // 获取查询的结果，把数组长度赋值给分页组件中的total
          this.tableData_length1 = this.list.length;
          this.$set(this.tableData1);
        })
        .catch((error) => {
          print(error);
        });

      var config2 = {
        c_update_man: this.$store.state.user_name,
      };
      this.axios
        .post(path, config2)
        .then((res) => {
          this.searchData = [];
          for (var i = 0; i < res.data.c_count; i++) {
            console.log(res.data.c_data[i].c_figure);
            this.searchData[i] = {};
            this.searchData[i].no = i + 1;
            this.searchData[i].c_content = res.data.c_data[i].c_content;
            this.searchData[i].c_date = res.data.c_data[i].c_date;
            this.searchData[i].c_type = "修改";
            this.searchData[i].c_figure = res.data.c_data[i].c_figure;
            this.searchData[i].c_change_time = res.data.c_data[i].c_update_time;
            if (res.data.c_data[i].c_pic != null) {
              this.searchData[i].c_pic =
                "https://assets.yingshinet.com/chronicles/pictures/" +
                res.data.c_data[i].c_pic;
            } else {
              this.searchData[i].c_pic = null;
            }
          }
          this.list = this.searchData;
          this.searchData = this.list.slice(
            (this.currentPage - 1) * this.pagesize,
            this.currentPage * this.pagesize
          );
          this.tableData2 = this.searchData;

          // 获取查询的结果，把数组长度赋值给分页组件中的total
          this.tableData_length = this.list.length;
          this.tableData_length2 = this.tableData_length;
          this.$set(this.searchData);
        })
        .catch((error) => {
          print(error);
        });
    },

    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },

    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.isSearch) {
        // && this.tableData.length!=0
        //如果是搜索功能的话，是一次性返回全部数据，需要每次换页的时候分页显示
        this.searchData = this.list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        //this.tableData_length = list.length;
        this.$set(this.searchData);
      } else {
        const path = "https://api.movie.yingshinet.com/paper";
        this.axios
          .post(path, { divide: 1, page: currentPage, count: 10 })
          .then((res) => {
            this.work = res.data.all_paper;
            this.num = res.data.cur_subtitle_count;
            this.tableData_length = res.data.all_subtitle_count;
            this.all_count = res.data.all_subtitle_count;
            this.tableData.length = this.tableData_length;
            this.tableData = [];
            this.getData();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    //跳转到证书页面
    certificatePreview() {
      this.$router.push("/CertificatePage")
    }
  },

  created() {
    this.searchFigure();
  },
};
</script>
<style scoped>
::v-deep .el-button:focus,
.el-button:hover {
  color: #e2a253 !important;
  border-color: #e2a253 !important;
  background-color: #fff6ec !important;
}

.image_style {
  width: 40px;
  height: 55px;
  border-radius: 3px;
  object-fit: fill;
  vertical-align: middle;
}

.image_style:hover {
  z-index: 10;
  transform: scale(3, 3);
  transition: 0.5s transform;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}

.el-select {
  left: 20px !important;
  top: 10px;
}
</style>
