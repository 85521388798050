<template 
      >
  <div style="
      text-align: center;">
    <div class="container-border" style="height:100vh; width: 80vw; margin: 0 auto;text-align:center ">
      <h3>
        数据贡献榜单
      </h3>
      <svg id="barchart" class="barchart-border"></svg>
    </div>
    <el-button size="medium" id="ShowButton" @click="changeButton" type="warning" round plain>
      <i :class="showAll ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>{{ showAll ? "展示部分" : "展示全部" }}
    </el-button>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";



export default {
  name: "statistics_barchart",
  data () {
    return {
      send_books: "",
      //barchart数据
      chartdata: {},
      showAll: false,
    };
  },
  methods: {
    //  绘制直方图
    preDrawBarChart (chartdata, flag) {
      let individual = []
      let original_count = [];
      let individual_filter = []
      let original_count_filter = [];
      let othernumber = 0
      //加载并筛选电影数据,individual为电影产地列表，original_count为数量列表
      if (flag == undefined) {
        for (let i = 0, len = chartdata.length; i < len; i++) {
          individual_filter.push(chartdata.data[i].name)
          original_count_filter.push(chartdata.data[i].value)
        }
        this.DrawBarChart(individual_filter, original_count_filter)
        return
      }
      for (let i = 0, len = chartdata.length; i < len; i++) {
        if (chartdata.data[i].value > flag) {
          individual_filter.push(chartdata.data[i].name)
          original_count_filter.push(chartdata.data[i].value)
        }
        this.DrawBarChart(individual_filter, original_count_filter)
      }
      // console.log(individual_top10, original_count_top10)
      // this.DrawBarChart(individual, original_count)
    },
    DrawBarChart (individual, original_count) {
      // if (chartdata.data[i].value > 0) {
      //   individual_top10.push(chartdata.data[i].name)
      //   original_count_top10.push(chartdata.data[i].value)
      // }
      // 每次绘图时清空svg
      let svg = d3.select("#barchart").selectAll("*").remove();
      //创建序数比例尺和包括10中颜色的输出范围
      var color = d3.scaleOrdinal(d3.schemeCategory10);
      //设定svg及其父元素尺寸大小并且为响应式
      let w = $(".container-border").width();
      let h = original_count.length * 25;
      $(".container-border").height(h + 36);
      // w = $("body").width() * 0.65;
      // 设置svg中图像的偏移位置
      const margin = { top: 20, right: 60, bottom: 20, left: 150 };
      w = w - margin.left - margin.right;
      h = h - margin.top - margin.bottom;
      //创建svg设定尺寸与偏移
      svg = d3
        .select("#barchart")
        .attr("width", w + margin.left + margin.right)
        .attr("height", h + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      //创建直方图
      var xAxisWidth = w;
      var yAxisWidth = h;
      var yScale = d3.scaleBand().domain(individual).range([0, yAxisWidth]);
      var xScale = d3
        .scalePow()
        .exponent(0.4)
        .domain([0, d3.max(original_count)])
        .range([0, xAxisWidth]).nice();
      var xAxis = d3.axisTop(xScale);
      var yAxis = d3.axisLeft().scale(yScale)
      svg
        .append("g")
        .attr(
          "transform",
          "translate(" + 0 + "," + 0 + ")"
        )
        .call(xAxis);

      svg
        .append("g")
        .call(yAxis)
        .selectAll("text")
        .style("font-size", "16px")
        .attr("fill", function (d, i) {
          return color(i);
        })
        .style("opacity", 0.9)


      // 添加透明填充
      const maxCount = d3.max(original_count)
      let tmp_original_count = []
      for (let i in original_count) {
        // console.log(i);
        tmp_original_count[i] = maxCount
      }
      let rect1Width = xScale(maxCount);
      svg
        .selectAll("rect1")
        .data(tmp_original_count)
        .enter()
        .append("rect")
        .attr("fill", function (d, i) {
          // console.log(0, color(0))
          // console.log(color(i), i)
          return color(i);
        })
        .attr("x", 0)
        .attr("y", function (d, i) {
          return 0 + yScale(individual[i]) + yScale.bandwidth() * 0.1;
        })
        .attr("height", yScale.bandwidth() * 0.85)
        .attr("width", function (d, i) {
          return rect1Width
        })
        .style("opacity", 0.1)



      svg
        .selectAll("rect2")
        .data(original_count)
        .enter()
        .append("rect")
        .attr("id", function (d, i) {
          return i;
        })
        .attr("fill", function (d, i) {
          // console.log(0, color(0))
          // console.log(color(i), i)
          return color(i);
        })
        .attr("x", 0)
        .attr("y", function (d, i) {
          return 0 + yScale(individual[i]) + yScale.bandwidth() * 0.1;
        })
        .attr("height", yScale.bandwidth() * 0.8)
        .attr("width", function (d, i) {
          return xScale(original_count[i]);
        })
        .style("opacity", 0.65)
        .on("mouseover", function (d, i) {
          d3.select(this).attr("fill", d3.rgb(color(parseInt(d.target.id))).brighter());
        })
        .on("mouseout", function (d, i) {
          d3.select(this).attr("fill", color(parseInt(d.target.id)));
        })


    },
    formatbarchartData (data) {//格式化直方图数据
      return data //排序
        .map(({ country, count }) => {
          return { name: country, value: count };
        })
        .sort((a, b) => d3.descending(a.value, b.value));
    },
    changeButton (e) {
      this.showAll = !this.showAll
      this.showAll ? this.preDrawBarChart(this.chartdata) : this.preDrawBarChart(this.chartdata, 0)
    }
  },
  created () {
    // 获取直方图和饼图的数据
    this.axios.get("https://api.movie.yingshinet.com/countactions").then((res) => {
      const apidata = res.data.data
      // console.log("res", res.data.data);
      const akeys = []
      const bvalues = []
      const countryCount = []

      for (const key in apidata) {
        // console.log(apidata[key]);
        // console.log(key);
        // if (key != "空" && key != "？") {
        //去掉空的影响
        akeys.push(apidata[key].name);
        bvalues.push(apidata[key].create_num);
        // tmp = tmp - this.apidata[key];
        // }
      }
      // that.akeys.push("其他");
      // that.bvalues.push(tmp);

      for (let i = 0; i < akeys.length; i++) {
        countryCount.push({});
        countryCount[i].country = akeys[i];
        countryCount[i].count = bvalues[i];
      }

      const barChartData = Object.assign(this.formatbarchartData(countryCount), {
        format: "%",
        y: "↑ count",
      });
      const chartdata = {}
      chartdata.data = barChartData
      chartdata.length = akeys.length
      this.chartdata = chartdata
      this.preDrawBarChart(this.chartdata, 0)
    });
  }
};
</script> 
<style coped>
.container-border .el-card__body {
  padding: 0;
}

.container-border h3 {
  padding: 0px;
  font-size: 1.2vw;
}

#ShowButton {
  margin-bottom: 15px
}
</style>