<template >
  <!-- 底部第一行容器 -->
  <el-card
    class="word-cloud"
    style="height: 74.5vh; width: 96vw; margin: 0 auto; margin-top: 2vh"
  >
    <svg
      id="wordcloud"
      height="100%"
    />
  </el-card>
</template>

<script>
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as $ from "jQuery";
import { loadHistory } from "../../../utils/store";

export default {
  components: {},
  name: "PicCloud",
  props: {
    figures: Array,
    figure_names: Array,
    figures_serach: Array,
  },
  data () {
    return {
      words: [],
      //   fontSizeMapper: (word) => Math.log2(word.value) * 5,
      path: "https://api.movie.yingshinet.com/chronicles",
      wordcloud: null,
      wordcount: 0,
      wordsdata: [],
      director: "",
    };
  },

  methods: {
    // 给每个词加搜索功能
    initWordCloud () {
      // console.log("this.words", this.words);
      // 先根据this.words绘图
      this.drawWordCloud();
      // 绘图后根据添加的类名，给每个词添加搜索功能
      this.$nextTick(function () {
        document.querySelectorAll(".cloud-word").forEach((item) => {
          item.addEventListener("click", () => {
            // 词云图词汇内容
            let keyword = item.textContent;
            this.$router.push("/directors?=" + keyword);
            this.$store.commit("set_current_figure", keyword);
          });
        });
      });
    },
    //获得初始数据
    getEvents () {
      const figures = this.figures;
      // console.log("figures", figures);
      this.axios.post(this.path + "/keyword", {}).then((response) => {
        this.words = [];
        for (let i in figures) {
          //   console.log(figures[i]); //深泽
          this.words[i] = {
            text: `${figures[i].f_name}`,
            size: `${Math.floor(Math.random() * 30 + 5)}`,
          };
        }
        // console.log("this.words", this.words);
        this.initWordCloud();
        // console.log(this.wordcloud);
      });
    },

    drawWordCloud () {
      // console.log("词云图开始", this.words);
      let w = $(".word-cloud").width();
      let h = $(".word-cloud").height();
      // console.log(w, h);
      let layout = cloud()
        .size([w, h])
        .words(this.words)
        // 
        .padding(10)
        .rotate(0)
        .font("Impact")
        .fontSize(function (d) {
          return d.size;
        })
        .on("end", draw);

      layout.start();

      function draw (words) {
        const color = d3.scaleOrdinal(d3.schemeCategory10);
        let svg = d3.select("#wordcloud");
        svg.selectAll("*").remove();
        svg.attr("width", layout.size()[0]).attr("height", layout.size()[1]);
        let g = svg
          .append("g")
          .attr(
            "transform",
            "translate(" +
            layout.size()[0] / 2 +
            "," +
            layout.size()[1] / 2 +
            ")"
          );
        let text = g
          .selectAll("text")
          .data(words)
          .enter()
          .append("a")
          .attr("class", "cloud-word")
          // .attr("xlint:href", function (d) {
          //   // return "/network?wanted={{jfile}}";
          //   return null;
          // })
          .append("text")
          .attr("fill", (_, i) => color(i))
          .attr("transform", function (d) {
            return (
              "translate(" +
              [Math.random() * 1000 - 500, Math.random() * 1000 - 500] +
              ")"
            );
          })
          .transition()

          .style("font-size", function (d) {
            return d.size + "px";
          })
          .style("font-family", "Impact")
          .attr("text-anchor", "middle")
          .text(function (d) {
            return d.text;
          })
          .attr("transform", function (d) {
            return (
              "translate(" +
              [d.x, d.y] +
              ")rotate(" +
              d.rotate +
              ")" +
              "scale(1.2)"
            );
          });
      }
    },
    movie_click (movie_id) {
      this.$router.push("/video?id=" + movie_id);
    },
  },
  mounted () {
    // 词云图起步获得初始数据
    this.getEvents();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.el-carousel__indicators li {
  border: 0.5vw;
}
.el-card__body {
  padding: 0.5vw;
}
.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.cloud-word:hover {
  cursor: pointer;
}
.footerCard .el-card__body {
  padding: 0.5vw;
}
</style>